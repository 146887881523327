@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.BoonFormGroup {
  @include above(md) {
    display: flex;

    .boon-form-group-context {
      flex: 0 0 160px;
    }

    .boon-form-group-content {
      flex: 1;
      overflow: hidden;
    }
  }

  @include below(lg) {
    .boon-form-group-context {
      margin-bottom: 15px;
    }
  }

  @include above(xl) {
    .boon-form-group-context {
      flex-basis: 200px;
    }
  }

  .boon-form-group-label {
    font-weight: 800;
  }

  .boon-form-group-required {
    @include typo-small;
    color: $colorNine;
  }

  .boon-form-group-sub-label {
    @include typo-small;
    color: $colorNine;
  }

  .boon-form-group-helper-text {
    @include typo-small;
    color: $colorNine;
    margin-top: 10px;

    a {
      text-decoration: underline;

      &:hover {
        color: $colorWhite;
      }
    }
  }

  // HACKS
  .boon-text-field-label {
    display: none;
  }

  .boon-text-field-wrapper {
    margin-top: 0;
  }
}
