@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/mixins/shadows';

.UserDashboardEvents {
  .user-dashboard-events-title {
    @include typo-list-header;
    margin-bottom: 20px;
    font-family: $fontFamily;
    text-transform: none;
    color: $colorTextSecondary;
  }

  .user-dashboard-events-box {
    @include box-shadow-far;
    position: relative;
    background-color: $colorSurface;
    border-radius: 16px;
    min-height: 200px;
    padding: 30px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .user-dashboard-events-empty-text {
    color: $colorTextSecondary;
    max-width: 260px;
    text-align: center;
  }

  .button {
    margin-top: 20px;
  }

  .user-dashboard-events-error {
    color: $colorWarning;
    text-align: center;
  }

  .user-dashboard-events-content {
    .EventCard {
      margin-bottom: 20px;
    }
  }
}
