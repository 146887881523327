@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/helpers/cards';

.RecentNotifications {
  min-height: 300px;

  @include above(md) {
    @include card-near;
  }

  @include below(md) {
    overflow-y: auto;
  }

  .recent-notifications-header {
    display: flex;
    padding: 16px 24px 8px 24px;

    .recent-notifications-header-title {
      @include typo-headline5;
      color: $colorTextPrimary;
    }

    .recent-notifications-header-mark-read {
      @include typo-small;
      color: $colorTextSecondary;
      margin-left: auto;
      cursor: pointer;

      &:hover {
        color: $colorTextPrimary;
      }
    }
  }

  .recent-notifications-empty {
    @include position(absolute, 50% 0 null 0);
    transform: translateY(-50%);
    color: $colorTextSecondary;
    padding: 0 24px;
    text-align: center;
  }

  .recent-notifications-error {
    @include position(absolute, 50% 0 null 0);
    transform: translateY(-50%);
    padding: 0 24px;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .recent-notifications-error-message {
      color: $colorError;
      margin-bottom: 16px;
    }
  }

  .recent-notifications-content {
    @include above(md) {
      max-height: 50vh;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }

  .recent-notifications-spinner {
    width: 100%;
    height: 64px;
    position: relative;
    margin-bottom: 15px;
  }
}
