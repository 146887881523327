@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/mixins/spacing';

.CreatorSidebar {
  display: flex;

  .creator-sidebar-wrapper {
    flex: 0 0 210px;
    background-color: $colorPanel;
    color: $colorWhite;
    min-height: 100vh;
    box-shadow: 0 60px 80px 0 rgba(0, 0, 0, 0.02),
      0 18px 24px 0 rgba(0, 0, 0, 0.01), 0 8px 10px 0 rgba(0, 0, 0, 0.01),
      0 3px 4px 0 rgba(0, 0, 0, 0.01);
    @include page-no-header-spacing;
    position: relative;

    @include below(md) {
      display: none;
    }
  }

  .creator-sidebar-inner {
    @include position(sticky, $headerHeight null null null);
    padding-top: 30px;
  }

  .creator-sidebar-page {
    flex: 1;
    width: 100%;
  }

  .creator-sidebar-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 30px;
  }

  .creator-sidebar-menu {
    margin-top: 30px;
  }
}
