@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.InsightsOverviewPage {
  .insights-footer {
    margin-top: 160px;

    .InsightsGenericWidget {
      display: flex;
      flex-direction: column;
      background-color: $colorPrimary;
      min-height: 300px;
      color: $colorWhite;
    }

    .insights-generic-widget-header {
      margin-bottom: 16px;
    }

    .insights-footer-links {
      color: $colorWhite;
      margin-top: auto;
      font-size: 1.2em;

      a {
        margin-bottom: 5px;
        display: inline-block;
        text-decoration: none;
        &:hover {
          color: $colorTextMuted;
        }
      }
    }

    .insights-footer-icons {
      fill: $colorWhite;
      margin: 0 5px 3px 0;
    }
  }
}
