@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.CreatorColorPicker {
  display: flex;

  .creator-color-picker-default {
    border-right: 1px solid $colorBorder;
    padding-right: 8px;
    margin-right: 8px;
  }

  .creator-color-picker-colors {
    flex: 1 1 auto;
    display: grid;
    grid-template-columns: repeat(8, 24px);
    grid-gap: 8px;
  }
}

.CreatorColorPickerItem {
  @include size(24px);
  border-radius: $borderRadiusMedium;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    @include size(18px);
  }

  // Default
  background-color: $colorPrimary;
  svg {
    fill: $colorWhite;
  }
}
