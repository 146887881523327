@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.BoonInputAddonEmoji {
  position: relative;
  height: 100%;

  .boon-input-addon-emoji-loader {
    @include position(absolute, 0 0 0 0);
    background-color: rgba(0, 0, 0, 0.3);
  }

  .boon-input-addon-emoji-value {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 46px;
    cursor: pointer;

    font-size: 18px;
    font-family: emoji;
  }
}

.BoonInputAddonEmojiModal {
  z-index: $zOverlay;

  .emoji-mart {
    button {
      &:focus {
        outline: none;
      }
    }

    .emoji-mart-emoji {
      > span {
        cursor: pointer;
      }
    }
  }
}
