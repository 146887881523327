@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/global/variables';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.ChannelCarouselControls {
  display: flex;
  justify-content: center;

  .channel-carousel-controls-pages {
    position: relative;
    display: inline-flex;
    margin: 0 -4px;
  }

  .channel-carousel-controls-page {
    margin: 0 4px;
    background-color: $colorBorder;
    width: 12px;
    cursor: pointer;
  }

  .channel-carousel-controls-position {
    @include position(absolute, 0 0 null null);

    background-color: $colorTextPrimary;
    width: 12px;

    transition: left 250ms ease-out;
  }

  .channel-carousel-controls-page,
  .channel-carousel-controls-position {
    height: 4px;
    border-radius: $borderRadiusSmall;
  }
}
