@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.HeaderProduct {
  display: inline-flex;

  @include below(sm) {
    display: none;
  }

  .header-product-item {
    display: flex;
    align-items: center;
    position: relative;

    margin-left: 32px;

    .header-product-item-link {
      cursor: pointer;
      transition: opacity 250ms ease-out;
      font-size: 14px;
      font-weight: 700;

      display: flex;
      align-items: center;

      &:hover {
        opacity: 0.6;
      }
    }

    svg {
      @include size(24px);
      margin-top: 4px;
    }

    .OptionsMenuBox {
      .options-menu-box-content {
        top: 24px;
        right: 0;
        transform: translateX(100%);

        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}
