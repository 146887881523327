@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.EventHostPanel {
  padding: 15px 15px 15px 15px;
  width: 100%;
  background-color: $colorHostPanelBackground;
  display: flex;
  align-items: center;

  .event-host-panel-title {
    @include typo-headline6;
    margin-right: auto;
  }
}
