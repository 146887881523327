@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/helpers/cards';

.OptionsMenu {
  .options-menu-button {
    fill: $colorTextPrimary;
    @include size(24px);
    cursor: pointer;
  }

  &.dark {
    .options-menu-button {
      fill: $colorDarkTextPrimary;
    }
  }
}

.OptionsMenuBox {
  position: absolute;
  user-select: none;
  color: $colorTextPrimary;

  .options-menu-box-content {
    @include position(absolute, null -24px null null);
    @include card-far();
    padding: 8px;
    border-radius: $borderRadiusLarge;
    overflow: hidden;
    background-color: $colorSurface;
    transition: opacity 0.2s ease-in-out;
    z-index: 9999;
    white-space: nowrap;

    &:not(.open) {
      display: none;
    }

    > a,
    .link {
      display: block;
      cursor: pointer;
      padding: 8px 16px;

      &:not(:last-child) {
        margin-bottom: 8px;
      }

      &:hover {
        color: inherit;
        text-decoration: none;
        background-color: $colorBorderLight;
        border-radius: 8px;
      }
    }

    .product-card-menu-move-menu {
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      padding-top: 5px;
    }
  }
}
