@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/helpers/cards';

.UserDashboardFollowing {
  .UserCard {
    margin-bottom: 20px;
  }

  .user-dashboard-following-empty {
    @include card-near;
    background-color: $colorSurface;
    border-radius: 4px;
    padding: 30px;
  }

  margin-bottom: -20px;
}
