@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/helpers/cards';

.LeadQualificationPage {
  color: $colorWhite;
}

.LeadQualificationStep {
  max-width: 630px;
  margin: 0 auto;
  padding: 0 $containerSpacing 30px $containerSpacing;
  min-height: 100vh;

  &:not(.active) {
    display: none;
  }

  @include above(lg) {
    padding: 50px 24px 24px 24px;
  }

  .lead-qualification-step-progress {
    text-transform: uppercase;
    font-family: $fontFamily;
    font-size: 13px;
    color: $colorTextSecondary;
    margin-bottom: 20px;
  }

  .lead-qualification-step-title {
    @include typo-headline2;
    color: $colorTextPrimary;
    margin-bottom: 30px;
  }

  .lead-qualification-step-subtitle {
    font-weight: 700;
  }

  .lead-qualification-step-box {
    @include card-near;
    color: $colorTextPrimary;
    border-radius: $borderRadiusLarge;
    padding: 30px 30px;
    @include card-mobile-stretch;

    @include below(sm) {
      border-left: none;
      border-right: none;
    }

    .input-error {
      margin-top: 10px;
      margin-bottom: -5px;
    }
  }

  .lead-qualification-step-below {
    display: flex;
    margin-top: 30px;
    align-items: center;
  }

  .lead-qualification-step-back {
    color: $colorNine;

    &:hover {
      color: $colorPrimary;
    }
  }

  .lead-qualification-step-submit {
    margin-left: auto;
  }

  .BoonFormGroup {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  // Steps
  .lead-qualification-step-categories {
    .lead-qualification-step-categories-list {
      margin-top: 20px;
      column-count: 2;
      column-gap: 20px;

      @include above(lg) {
        column-count: 3;
      }
    }
  }

  .lead-qualification-step-social {
    .lead-qualification-step-social-list {
      display: flex;
      flex-wrap: wrap;
    }

    .lead-qualification-step-social-description {
      margin-bottom: 10px;
    }

    .lead-qualification-step-social-item {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      position: relative;
      flex: 1 1 0px;

      padding: 15px 10px;

      &:hover {
        .lead-qualification-step-social-item-icon {
          svg {
            fill: $colorPrimary;
          }
        }
      }

      .lead-qualification-step-social-item-icon {
        flex-shrink: 0;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        @include size(60px);
        border: 1px solid $colorBorderLight;
        border-radius: 50%;
        margin-bottom: 5px;

        svg {
          fill: $colorBlack;
          transition: fill 250ms;
          @include size(50%);
        }
      }

      .lead-qualification-step-social-item-remove {
        @include position(absolute, 10px 10px null null);
        background-color: $colorSurface;
        @include box-shadow-extra-far;
        border-radius: 999px;
        border: 1px solid $colorBorderLight;
        @include size(24px);

        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        svg {
          @include size(16px);
        }

        &:hover {
          background-color: $colorAlternateBackground;
        }
      }

      &.disabled {
        pointer-events: none;
        opacity: 0.6;
      }

      &.success {
        .lead-qualification-step-social-item-icon {
          background-color: $colorPrimary;
          svg {
            fill: $colorWhite;
          }
        }
      }
    }
  }
}
