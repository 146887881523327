@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/mixins/spinner';

.PostComments {
  .post-comments-border {
    border-top: 1px solid $colorBorderLight;
    margin: 0 24px;
  }

  .post-comments-more {
    width: auto;
    color: var(--color-creator-primary);
    margin: 16px 0;
    padding-left: 20px;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;

    &:hover {
      color: $colorTextPrimary;
    }

    &.loading {
      &:after {
        @include spinner($size: 16px, $width: 2px, $color: $colorTextPrimary);
        margin-top: 4px;
        margin-left: 10px;
      }
    }

    &.error {
      color: $colorError;
    }
  }

  .post-comments-compose {
    padding: 0 24px 0 24px;
    margin: 16px 0;
  }

  &:not(:empty) {
    padding-bottom: 1px;
  }
}
