@import 'styles/global/colors';

.CreatorCoupons {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(226, 228, 233);
  box-shadow: 0 0 2px 0 rgba(60, 64, 67, 0.02),
    0 2px 2px 0 rgba(60, 64, 67, 0.03);
  border-radius: 16px;
  padding: 24px;
  height: max-content;
  position: relative;

  .creator-coupon-error {
    color: $colorWarning;

    span {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .creator-coupons-title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 44px;

    p {
      margin: 0;
    }

    .creator-coupons-title-text {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
    }

    .creator-coupons-add-button {
      display: flex;
      background: $colorInputFields;
      border-radius: 20px;
      width: 106px;
      height: 40px;
      align-items: center;
      justify-content: space-between;
      padding: 0 23px;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 24px;
      cursor: pointer;
      user-select: none;

      &:hover {
        background: darken($colorBorderLight, 3%);
      }
    }
  }

  .creator-coupons-wrapper {
    display: flex;
    flex-direction: column;
    overflow-x: auto;

    .creator-coupons-table-wrapper {
      display: flex;

      &.stretch {
        justify-content: stretch;
      }

      &.space {
        justify-content: space-between;
      }

      p {
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
      }

      .creator-coupons-coupon-title {
        width: 25%;
      }

      .creator-coupons-code-title {
        width: 25%;
      }

      .creator-coupons-term-title {
        width: 20%;
      }

      .creator-coupons-redemption-title {
        text-align: right;
        width: 10%;
      }

      .creator-coupons-status-title {
        text-align: center;
        width: 13%;
      }
    }

    .creator-coupons-border-line {
      border-bottom: 1px solid rgb(240, 241, 244);
      margin-bottom: 14px;
    }

    .creator-coupons-content {
      overflow-y: auto;
      max-height: 100%;

      .creator-coupons-coupon-breaker {
        width: 100%;
        height: 1px;
        background-color: $colorBorderLight;
        margin-top: -5px;
        margin-bottom: 10px;
      }

      .creator-coupons-coupon-content {
        display: flex;
        flex-direction: row;

        &.stretch {
          justify-content: stretch;
        }

        &.space {
          justify-content: space-between;
        }

        p {
          color: $colorTextPrimary;
          font-size: 14px;
          font-weight: normal;
          letter-spacing: 0;
          line-height: 24px;
        }

        .content-overflow {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .creator-coupons-coupon-name {
          width: 25%;
        }

        .creator-coupons-coupon-code {
          width: 25%;
        }

        .creator-coupons-coupon-terms {
          width: 20%;
        }

        .creator-coupons-coupon-redemptions {
          width: 10%;
          text-align: right;
        }

        .creator-coupons-coupon-status {
          width: 13%;
          text-align: left;
          padding-left: 45px;
        }
      }
    }
  }
}
