@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.PostCardAttachmentHeader {
  display: flex;
  align-items: center;
  padding: 16px;
  pointer-events: none;

  .post-card-attachment-header-type {
    display: flex;
    align-items: center;

    .post-card-attachment-header-type-icon {
      @include size(24px);
      fill: $colorTextPrimary;
      margin-right: 8px;
    }
  }

  .post-card-attachment-header-right {
    margin-left: auto;
    .PostCardLock {
      pointer-events: all;
    }
  }

  &.dark {
    color: $colorDarkTextPrimary;

    .post-card-attachment-header-type-icon {
      fill: $colorDarkTextPrimary;
    }
  }
}
