@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.CreatorSetupMembership {
  .CreatorSettingsPaymentOptionsPanel {
    margin-bottom: 20px;
  }
}
