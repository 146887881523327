@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.PostCardAttachmentPhoto {
  position: relative;
  cursor: pointer;
  margin: 0 24px;
  border-radius: 16px;

  .post-card-attachment-photo-image {
    width: 100%;
    border-radius: 16px;
    max-height: 550px;
    object-fit: contain;
    background-color: black;
    filter: brightness(90%);
    transition: filter 250ms ease-in-out;
  }

  &:hover {
    .post-card-attachment-photo-image {
      filter: none;
    }
  }

  .post-card-attachment-photo-locked {
    @include position(absolute, 0 0 0 0);
    background-color: rgba(black, 0.2);
    border-radius: 16px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .PostCardAttachmentHeader {
    @include position(absolute, 0 0 null 0);
  }
}
