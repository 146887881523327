@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.PageModal {
  background-color: $colorBase;
  z-index: $zOverlay;
  overflow: auto;

  .page-modal-close {
    @include position(fixed, 20px null null 20px);
    @include size(46px);

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 50%;
    cursor: pointer;
    z-index: $zOverlay;

    background-color: $colorDark8;
    box-shadow: 0 60px 80px 0 rgba(0, 0, 0, 0.02),
      0 18px 24px 0 rgba(0, 0, 0, 0.01), 0 8px 10px 0 rgba(0, 0, 0, 0.01),
      0 3px 4px 0 rgba(0, 0, 0, 0.01);

    .page-modal-close-icon {
      @include size(20px);
      fill: $colorLightGrey;
    }

    &:hover {
      .page-modal-close-icon {
        fill: $colorWhite;
      }
    }
  }

  .modal-content {
    width: 100%;
  }
}
