@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/helpers/cards';

.DashboardCreateButton {
  position: relative;
  svg {
    @include size(24px);
    margin-right: 5px;
    margin-left: -5px;
  }

  .dashboard-create-button-menu {
    @include position(absolute, calc(100% + 15px) 0 null null);
    @include card-near;
    padding: 15px 20px;
    background-color: $colorSurface;
    border-radius: 16px;
    z-index: 9999;
    color: $colorTextPrimary;

    &:not(.open) {
      display: none;
    }

    .dashboard-create-button-menu-item {
      display: flex;
      align-items: center;
      white-space: nowrap;

      &:hover {
        color: inherit;
      }

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      svg {
        @include size(18px);
        fill: $colorTextPrimary;
        margin-right: 10px;
        margin-top: 1px;
      }
    }
  }
}
