@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/shadows';
@import 'styles/modules/bourbon';

.PinnedModal {
  .modal-content {
    display: flex;
  }

  // TODO: HACK FOR OVERLAY ON BURGER MENU
  z-index: 2020;

  @include media-breakpoint-down(sm) {
    display: flex;
    justify-content: unset;
    align-items: unset;

    .modal-content {
      @include position(fixed, null null 0 null);
      flex-direction: column;
      margin: 0;
      width: 100%;
    }

    &#{&}-enter,
    &#{&}-appear {
      .modal-overlay {
        opacity: 0;
      }

      .modal-content {
        bottom: -100%;
      }
    }

    &#{&}-enter-active,
    &#{&}-appear-active {
      .modal-overlay {
        opacity: 1;
        transition: all 250ms ease-out;
      }

      .modal-content {
        bottom: 0;
        transition: all 250ms ease-out;
      }
    }

    &#{&}-exit {
      .modal-overlay {
        opacity: 1;
      }

      .modal-content {
        bottom: 0;
      }
    }

    &#{&}-exit-active {
      .modal-overlay {
        opacity: 0;
        transition: all 250ms ease-in;
      }

      .modal-content {
        bottom: -100%;
        transition: all 250ms ease-in;
      }
    }
  }

  @include media-breakpoint-up(sm) {
    .modal-content {
      flex-direction: row;
      @include box-shadow-far;
    }

    &#{&}-enter,
    &#{&}-appear {
      .modal-overlay {
        opacity: 0;
      }

      .modal-content {
        opacity: 0;
        transform: scale(0.9);
      }
    }

    &#{&}-enter-active,
    &#{&}-appear-active {
      .modal-overlay {
        opacity: 1;
        transition: all 250ms ease-in-out;
      }

      .modal-content {
        opacity: 1;
        transform: scale(1);
        transition: all 250ms ease-in-out;
      }
    }

    &#{&}-exit {
      .modal-overlay {
        opacity: 1;
      }

      .modal-content {
        opacity: 1;
        transform: scale(1);
      }
    }

    &#{&}-exit-active {
      .modal-overlay {
        opacity: 0;
        transition: all 250ms ease-in-out;
      }

      .modal-content {
        opacity: 0;
        transform: scale(0.9);
        transition: all 250ms ease-in-out;
      }
    }
  }

  .pinned-modal-content {
    flex: 1 1 auto;
    background-color: white;
    padding: 24px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  @include media-breakpoint-up(sm) {
    .pinned-modal-content {
      width: 375px;
    }
  }

  @include above(md) {
    .pinned-modal-content {
      border-radius: 16px;
    }
  }
}
