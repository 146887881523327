@import 'styles/modules/bourbon';

.BoonInputAddonSelect {
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;

  > select {
    display: flex;
    height: 100%;
    border: none;
    outline: none;
    appearance: none;
    margin-bottom: 3px; // Font hack
    padding: 0 30px 0 15px;
    background-color: transparent;
    color: inherit;
    cursor: pointer;

    &:disabled {
      cursor: initial;
    }
  }

  > svg {
    @include position(absolute, 50% 10px null null);
    @include size(18px);
    transform: translateY(-50%);
    pointer-events: none;
  }
}
