@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.PostComposeAttachmentButton {
  border-radius: 900px;
  cursor: pointer;
  margin-bottom: 0;
  display: flex;

  .post-compose-attachment-button-icon {
    @include size(24px);
    fill: $colorDark;
  }

  .post-compose-attachment-button-title {
    margin-left: 10px;
    margin-right: 6px;

    @include below(sm) {
      display: none;
    }
  }

  &:hover {
    opacity: 0.8;
  }
}
