@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/global/variables';
@import 'styles/mixins/responsiveness';
@import 'styles/helpers/cards';

.HeaderMenu {
  display: flex;
  position: relative;
  user-select: none;

  .header-menu-content {
    @include position(absolute, 40px 0 null null);
    @include card-near;
    color: $colorTextPrimary;
    display: none;
    opacity: 0;
    z-index: 10;
    width: 350px;
    background-color: $colorSurface;
    transition: opacity 0.2s ease-in-out;
    overflow: auto;
    max-height: calc(100vh - #{$headerHeight});
  }

  &.open {
    .header-menu-content {
      display: block;
      opacity: 1;
    }

    .header-menu-button-icon {
      transform: rotate(180deg);
    }
  }
}
