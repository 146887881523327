@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.PostCommentReply {
  margin: 12px 0 20px 0;

  &[highlight='true'] {
    position: relative;
    &:after {
      content: '';
      @include position(absolute, -16px -16px -4px -16px);
      animation: reply-highlight 1000ms ease-out;
      animation-delay: 500ms;
      pointer-events: none;
    }
  }
}

@keyframes reply-highlight {
  0%,
  50%,
  100% {
    background-color: transparent;
  }
  25%,
  75% {
    background-color: rgba(var(--color-creator-primary-rgb), 0.2);
  }
}
