@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.UnlockButton {
  &.filter {
    color: $colorPrimary;
    color: var(--color-creator-primary);
    font-weight: 600;
  }

  .unlock-button-icon {
    @include size(18px);
    fill: $colorPrimary;
    fill: var(--color-creator-primary);
    margin-right: 5px;
    margin-left: -5px;
  }

  .attend-button-icon {
    @include size(18px);
    margin-right: 5px;
    margin-left: -5px;
  }
}
