@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.InsightsNumberWidget {
  position: relative;
  // title + spacing + change
  min-height: 48px + 16px + 24px;

  .insights-number-widget-value {
    font-family: $fontFamilyBoon;
    font-size: 48px;
    font-weight: 800;
    line-height: 1;
    padding: 8px 0;
  }

  .insights-number-widget-change {
    @include typo-small;
    font-weight: 800;

    &.insights-number-widget-change-up {
      svg {
        transform: rotate(180deg);
      }
    }

    &.insights-number-widget-change-negative {
      color: $colorAlarm;
      svg {
        fill: $colorAlarm;
      }
    }

    &:not(.insights-number-widget-change-negative) {
      color: $colorSuccess;
      svg {
        fill: $colorSuccess;
      }
    }

    svg {
      @include size(24px);
      margin: 0 -4px 0 -8px;
    }
  }

  .insights-number-widget-no-change {
    width: 32px;
    height: 4px;
    margin: 10px 0;
    background-color: $colorTextMuted;
    border-radius: 2px;
  }

  .insights-number-widget-error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 88px;

    > div:last-child {
      font-size: 11px;
      color: $colorTextMuted;
    }
  }

  .insights-number-widget-loading {
    // Spinner
  }
}
