@import 'styles/global/colors';
@import 'styles/global/variables';
@import 'styles/modules/bourbon';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/mixins/spinner';
@import 'styles/helpers/cover';
@import 'styles/helpers/cards';

.CreatorSetupProfile,
.ProfileEditPage {
  padding-bottom: 60px;
}

.CreatorSetupForm {
  color: $colorBlack;

  .setup-photo-button {
    @include size(36px);

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 100%;

    svg {
      @include size(24px);
      margin-top: 1px;
      transition: all 250ms ease-out;
      opacity: 1;
    }

    &:before {
      content: '';
      opacity: 0;
      transition: opacity 250ms ease-out;
    }

    &.loading {
      &:before {
        opacity: 1;
      }

      svg {
        opacity: 0;
      }
    }
  }

  .setup-section {
    padding: 30px;
    background-color: $colorSurface;
    margin-bottom: 30px;
    overflow: hidden;

    @include above(md) {
      border-radius: 16px;
      @include card-near;
    }

    @include below(sm) {
      margin-left: -$containerSpacing;
      margin-right: -$containerSpacing;
      margin-bottom: 30px;
    }

    &:last-of-type {
      @include media-breakpoint-down(sm) {
        border-bottom: none;
        margin-bottom: -20px;
      }
    }

    .setup-section-header {
      margin-bottom: 30px;

      .setup-section-header-title {
        @include typo-headline4;
      }

      .setup-section-header-subtitle {
        color: $colorNine;
        margin-top: 5px;
      }
    }
  }

  .setup-label {
    color: $colorNine;
    margin-bottom: 15px;
  }

  .setup-cover {
    display: block;
    @include userCover;
    position: relative;
    cursor: pointer;
    border: 1px solid $colorBorderLight;

    .setup-cover-upload {
      @include position(absolute, 50% null null 50%);
      transform: translate(-50%, -50%);

      background-color: $colorMessageFrameLight;
      backdrop-filter: saturate(180%) blur(20px);

      svg {
        fill: $colorWhite;
      }

      &:hover {
        svg {
          fill: darken($colorWhite, 5%);
        }
      }

      &:before {
        @include spinner($color: $colorWhite);
      }
    }
  }

  .setup-avatar {
    display: flex;
    justify-content: center;
    @include size(100px);
    position: relative;
    cursor: pointer;

    .Avatar {
      width: 100%;
    }

    .setup-avatar-upload {
      @include position(absolute, null 7px 2px null);

      background-color: $colorWhite;
      box-shadow: 0 0 1px 0 rgba(60, 64, 67, 0.12),
        0 2px 4px 0 rgba(60, 64, 67, 0.12);

      svg {
        fill: $colorMuted;
      }

      &:hover {
        svg {
          fill: darken($colorMuted, 10%);
        }
      }

      &:before {
        @include spinner($color: $colorMuted);
      }
    }
  }

  .setup-social {
    display: flex;
    align-items: center;

    margin-bottom: 15px;

    .setup-social-url {
      color: $colorBorderLight;
      font-size: 13px;
    }

    &.setup-social-add {
      cursor: pointer;

      .setup-social-icon {
        transition: background-color 250ms;
      }

      &:hover {
        .setup-social-icon {
          background-color: darken($colorBorderLight, 3%);
        }
      }
    }

    .setup-social-icon {
      flex-shrink: 0;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      @include size(46px);
      background-color: $colorBorderLight;
      border-radius: 50%;
      margin-right: 15px;

      svg {
        @include size(24px);
      }
    }

    .setup-social-details {
      margin-right: auto;
    }

    .setup-social-remove {
      opacity: 0.4;
      transition: opacity 250ms ease-out;
      cursor: pointer;

      &:hover {
        opacity: 0.6;
      }

      svg {
        @include size(24px);
        fill: $colorTextPrimary;
      }
    }
  }

  .setup-submit {
    @include media-breakpoint-down(sm) {
      width: 100%;
      margin-top: 45px;
    }
    float: right;
  }

  .BoonFormGroup {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  #setupAvatarUpload,
  #setupCoverUpload {
    display: none;
  }
}
