@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/mixins/spacing';

.NavigationTabs {
  $border-color: $colorBorder;
  .navigation-tabs-tablist {
    position: relative;
    width: 100%;
    margin-bottom: 19px;
    list-style: none;
    border-bottom: 1px solid $border-color;
    padding-bottom: 0px;
    text-align: center;
    padding-inline-start: 0px;
    display: flex;
    justify-content: center;
    @include below(sm) {
      justify-content: space-evenly;
    }

    li {
      color: $colorTextSecondary;
      display: inline;
      padding: 16px;
      font-size: 13px;
      font-weight: bold;
      border-left: 1px solid transparent;
      border-top: 1px solid transparent;
      border-right: 1px solid transparent;
      border-radius: 8px 8px 0px 0px;

      @include below(sm) {
        width: 100%;
      }

      svg {
        fill: $colorTextSecondary;
        @include size(24px);
      }

      &:hover {
        color: $colorPrimary;
        color: var(--color-creator-primary);
        svg {
          fill: $colorPrimary;
          fill: var(--color-creator-primary);
        }
      }

      &.active {
        color: $colorPrimary;
        color: var(--color-creator-primary);
        border-left: 1px solid $border-color;
        border-top: 1px solid $border-color;
        border-right: 1px solid $border-color;
        border-bottom: 1px solid white;
        border-radius: 8px 8px 0px 0px;
        background-color: white;
        z-index: 1;
        margin-bottom: -1px;

        svg {
          fill: $colorPrimary;
          fill: var(--color-creator-primary);
        }
      }

      &:hover {
        cursor: pointer;
      }

      .navigation-tabs-tab-name {
        display: inline;
        margin-left: 10px;
        @include below(sm) {
          display: none;
        }
      }
    }
  }
}
