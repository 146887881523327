@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/helpers/cards';
@import 'components/Poll/pollColors';

.PollForm {
  @include card-near;

  .PollThemeSelector {
    margin-bottom: 24px;
    justify-content: space-between;

    @include above(md) {
      .PollThemeSelectorItem {
        flex: 0 0 calc(25% - 8px);
      }
    }

    @include below(sm) {
      overflow-x: scroll;
      margin-right: -16px;

      .PollThemeSelectorItem {
        margin-right: 16px;
        flex: 0 0 150px;
      }
    }
  }

  .poll-form-wrapper {
    padding: 16px;
    border-radius: 16px;
    position: relative;
  }

  .poll-form-inner {
    position: relative;
  }

  .poll-form-background {
    @include position(absolute, 0 0 null 0);
    overflow: hidden;
    display: flex;
    justify-content: center;

    svg {
      margin-top: -25px;
      width: 200px - (40px * 2);
      height: auto;
    }
  }

  .poll-form-header {
    display: flex;
    align-items: center;

    .PollThemeHeader {
      margin-bottom: 0;
    }

    .poll-form-header-remove {
      @include size(24px);
      fill: $colorTextPrimary;
      margin-left: auto;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .poll-form-section-header {
    font-weight: 800;
    margin: 16px 0;
  }

  .poll-form-option {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .BoonTextField {
      flex: 1;
      .boon-text-field-wrapper {
        margin-top: 0;
      }
    }

    .poll-form-option-remove {
      fill: $colorTextSecondary;
      @include size(24px);
      margin: 0 10px 0 16px;

      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .poll-form-options-add {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 24px;

    color: $colorTextSecondary;

    &:hover {
      opacity: 0.8;
    }

    svg {
      @include size(24px);
      fill: $colorTextSecondary;
      margin-right: 8px;
    }
  }

  .boon-dark-checkbox {
    margin-left: 2px;
  }

  .boon-dark-checkbox + label {
    padding-left: 28px;
  }

  .poll-form-end-date {
    margin-top: 16px;
  }

  &.poll-form-theme-default {
    .poll-form-wrapper {
      background-color: $pollDefaultBackground;
    }
  }
  &.poll-form-theme-next-live {
    .poll-form-wrapper {
      background-color: $pollNextLiveBackground;
    }
  }
  &.poll-form-theme-tell-me {
    .poll-form-wrapper {
      background-color: $pollTellMeBackground;
    }
  }
  &.poll-form-theme-ask-me {
    .poll-form-wrapper {
      background-color: $pollAskMeBackground;
    }
  }
}
