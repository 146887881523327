@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.AccessSelectorModal {
  .access-selector-modal-button {
    margin-top: 24px;
    width: 100%;
  }

  .access-selector-count {
    color: $colorTextSecondary;
    margin-left: 5px;
    font-weight: 300;
  }
}

.AccessSelectorRadioButton {
  display: flex;

  &.disabled {
    opacity: 0.5;
  }

  &:not(:last-child) {
    .access-selector-radio-button-inner {
      padding-bottom: 16px;
      border-bottom: solid 1px rgba(black, 0.05);
      margin-bottom: 16px;
    }
  }

  .access-selector-radio-button-radio {
    flex-shrink: 0;
    @include size(24px);
    margin-right: 16px;
    cursor: pointer;
  }

  .access-selector-radio-button-inner {
    flex: 1;
  }

  .access-selector-radio-button-header {
    display: flex;
    cursor: pointer;
  }

  .access-selector-radio-button-title {
    font-weight: 800;
  }

  .access-selector-radio-button-description {
    @include typo-small;
    color: $colorTextSecondary;
  }

  .access-selector-radio-button-icon {
    @include size(24px);
    margin-left: auto;
  }

  .access-selector-radio-button-content {
    margin-top: 24px;
  }
}

.AccessSelectorCheckbox {
  display: flex;
  cursor: pointer;
  margin-bottom: 16px;

  .access-selector-checkbox-check {
    flex-shrink: 0;
    @include size(24px);
    margin-right: 16px;
  }

  .access-selector-checkbox-inner {
    flex: 1;
  }

  .access-selector-checkbox-title {
    font-weight: 800;
  }

  .access-selector-checkbox-description {
    @include typo-small;
    color: $colorTextMuted;
  }
}
