.ChangeSubscriptionModal {
  .change-subscription-modal-description {
    margin-bottom: 20px;
    span {
      font-weight: 600;
    }
  }
  .change-subscription-modal-confirm-button {
    width: 100%;
    margin: 30px 0;
  }

  .change-subscription-modal-cancel {
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
  }
}
