// Brand
$colorPrimary: #0866fa; // The Boon brand color
$colorSecondary: #f4f1e8; // Accent Boon brand color

// Text
$colorTextPrimary: #000000; // Default text color
$colorTextSecondary: #6e7178; // Used for slightly muted text
$colorTextMuted: #c5c6c9; // Used for muted text

$colorDarkTextPrimary: #ffffff;

// Background colors
$colorBackground: #ffffff; // Page background colors
$colorSurface: #ffffff; // Component background colors (eg. cards)
$colorAlternateBackground: #f4f1e8; // Used mainly on the landing page to separate sections
$colorInputFields: #f0f1f4;

$colorDarkBackground: #000000;
$colorPanelBackground: #0f0f0f;
$colorHostPanelBackground: #1f1f1f;
$colorChatMessages: $colorHostPanelBackground;

// Generic colors
$colorGreen: #57bd65;
$colorYellow: #ffd648;
$colorRed: #ff7a5d;
$colorBlue: #68a0f2;

// State colors
$colorSuccess: $colorGreen;
$colorWarning: $colorYellow;
$colorDanger: $colorRed;
$colorInfo: $colorBlue;

// Special cases
$colorLive: #ee5440; // Color for live labels etc.

// Borders
$colorBorder: #e2e4e9; // Default border color
$colorBorderLight: #f0f1f4; // Less prominent border

// Button
$colorSecondaryButton: $colorBorderLight;
$colorSecondaryLightButton: #1a1a1a;

$colorFilterButton: $colorInputFields;
$colorFilterButtonHover: darken($colorFilterButton, 10%);
$colorFilterButtonActive: #222222;

// -- Legacy compatible variables
$colorError: $colorDanger;
$colorAlarm: $colorDanger;

// --- Legacy colors below

// Boon 3.0
$colorDark0: #141415;
$colorDark4: #29292a;
$colorDark8: #303031;
$colorDark24: #39393a;

$colorBackgroundBlack: #0a0a0b;

// Black'n'white
$colorBlack: $colorTextPrimary;
$colorWhite: $colorBackground;

// Greyscale
$colorDark: $colorBlack;
$colorMuted: #646c73;
$colorBasicGrey: #f4f4f4;

// Brand
$colorPurple: #a966f7;
$colorDarkBlue: #292f3a;

// 3rd party
$colorFacebook: #4267b2;

// Other
$colorMessageFrame: rgba(black, 0.4);
$colorMessageFrameLight: rgba(#a5a5a5, 0.4);

// New design
$colorBase: #1a1a1c;
$colorPanel: $colorDark4;
$colorNine: $colorTextSecondary;
$colorThree: #333333;
$colorLightGrey: $colorTextSecondary;
