@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.FreeMembershipCard {
  color: $colorWhite;
  border-radius: 16px;
  background-color: $colorDark4;
  overflow: hidden;

  width: 295px;

  position: relative;

  .free-membership-card-wrapper {
    height: 100%;
    min-height: 420px;
    max-height: 420px;
  }

  .free-membership-card-header {
    position: relative;
    padding-top: (9 / 16 * 100%);
    margin-bottom: 25px;

    background-position: center;
    background-size: cover;

    &:before {
      content: '';
      @include position(absolute, 0 0 0 0);
      background-color: rgba(black, 0.3);
    }

    .free-membership-card-header-inner {
      @include position(absolute, 0 0 0 0);
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    .free-membership-card-header-title {
      @include typo-headline4;
      padding: 20px;
      text-align: center;
    }
  }

  .free-membership-card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .free-membership-card-price {
    font-size: 36px;
    margin-bottom: 10px;

    .free-membership-card-price-interval {
      font-size: 16px;
    }
  }

  .free-membership-card-type {
    @include typo-small;
    text-transform: uppercase;
    background-color: $colorBase;
    border-radius: 3px;
    margin-bottom: 15px;
    padding: 2px 10px;
  }

  .free-membership-card-cta {
    margin-bottom: 20px;
    min-width: 150px;

    &.free-membership-card-cta-active {
      line-height: 1.5;
      font-weight: 800;
      padding: 9px 25px;
      padding-bottom: 11px;
      text-align: center;

      background-color: rgba($colorGreen, 0.1);
      color: $colorGreen;
      border-radius: 999px;
    }
  }

  .free-membership-card-perks {
    padding: 0 20px;

    .free-membership-card-perk {
      margin-bottom: 5px;

      svg {
        @include size(18px);
        fill: $colorWhite;
      }
    }
  }

  .free-membership-card-description {
    padding: 20px;
  }

  &.collapsed {
    .free-membership-card-wrapper {
      mask-image: linear-gradient(
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 1) 80%,
        transparent 100%
      );
    }
  }

  &:not(.collapsed) {
    .free-membership-card-wrapper {
      max-height: none;
    }
  }

  .free-membership-card-expander {
    @include position(absolute, null null 10px 50%);
    transform: translateX(-50%);
    cursor: pointer;

    svg {
      fill: $colorWhite;
      @include size(24px);

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
