@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.PostCardHeader {
  display: flex;
  padding: 16px 24px 0 24px;
  margin-bottom: 16px;

  .post-card-header-user-avatar {
    @include size(40px);
    margin-right: 15px;
    flex-shrink: 0;
  }

  .post-card-header-user-name {
    font-weight: bold;
  }

  .post-card-header-info {
    flex: 1 1 auto;
    min-width: 0;
  }

  .post-card-header-details {
    display: flex;
    @include typo-small;
    color: $colorNine;
  }

  .post-card-header-date {
    flex: 0 0 auto;
    flex-shrink: 0;
    color: #999999;
  }

  svg.post-card-pinned {
    fill: $colorTextMuted;
    @include size(17px);
  }

  .post-card-header-right {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  .post-card-header-categories {
    display: flex;
    flex-shrink: 1;
    min-width: 0;

    .post-card-header-categories-item {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: $colorTextPrimary;

      &:not(:last-child) {
        margin-right: 4px;
      }
    }
  }

  .post-card-header-lock {
    display: flex;
    &:hover {
      background-color: transparent;
    }

    &:before {
      content: '·';
      padding: 0 6px;
      display: inline-block;
    }

    &.open {
      cursor: pointer;
      .post-card-lock-button {
        svg {
          fill: $colorTextPrimary;
        }
      }
    }

    .post-card-lock-button {
      @include size(18px);
      margin-top: -2px;

      .post-card-lock-button-locked,
      .post-card-lock-button-unlocked,
      .post-card-lock-button-public {
        fill: $colorTextMuted;
      }
    }

    .PostCardLockMenu {
      left: 50%;
      transform: translateX(-50%);
      top: 30px;

      &:before {
        top: -30px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}
