@import 'styles/global/colors';
@import 'styles/global/variables';
@import 'styles/modules/bourbon';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.ContactPage {
  color: $colorWhite;
  background-color: $colorPrimary;
  margin-bottom: -$footerMargin;
  padding-bottom: $footerMargin;

  margin-top: -$headerHeight;
  padding-top: 70px + $headerHeight;

  @include media-breakpoint-up(md) {
    min-height: 100vh;
    margin-top: -($headerHeight + $headerMarginBottom);
    padding-top: ($headerHeight + $headerMarginBottom + 70px);
  }

  .contact-page-title {
    @include typo-display4;

    @include media-breakpoint-up(md) {
      @include typo-display1;
    }
  }

  .contact-page-email {
    display: block;
    color: $colorWhite;
    text-decoration: underline;
    @include typo-headline2;
    margin-top: 60px;

    &:hover {
      color: $colorBlack;
    }

    @include media-breakpoint-up(md) {
      @include typo-display4;
    }
  }

  .contact-page-address {
    font-size: 20px;
    margin-top: 45px;
  }
}
