@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/mixins/spinner';

.CheckoutProfile {
  .checkout-profile-title {
    font-size: 20px;
    color: $colorMuted;
    margin-bottom: 30px;
    text-align: center;
  }

  .checkout-profile-intro {
    margin-bottom: 48px;
  }

  .checkout-profile-avatar-row {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;

    .checkout-profile-avatar {
      @include size(120px);
    }

    .checkout-profile-avatar-upload {
      margin-left: 30px;
      margin-bottom: 0;
    }

    #settingsAvatarUpload {
      display: none;
    }
  }

  .checkout-profile-photo-button {
    @include size(36px);

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 100%;

    svg {
      @include size(24px);
      margin-top: 1px;
      transition: all 250ms ease-out;
      opacity: 1;
    }

    &:before {
      content: '';
      opacity: 0;
      transition: opacity 250ms ease-out;
    }

    &.loading {
      &:before {
        opacity: 1;
      }

      svg {
        opacity: 0;
      }
    }
  }

  .checkout-profile-avatar {
    display: flex;
    justify-content: center;
    position: relative;
    @include size(120px);
    cursor: pointer;

    .Avatar {
      width: 100%;
    }

    .checkout-profile-avatar-upload {
      @include position(absolute, null 7px 2px null);

      background-color: $colorWhite;
      box-shadow: 0 0 1px 0 rgba(60, 64, 67, 0.12),
        0 2px 4px 0 rgba(60, 64, 67, 0.12);

      svg {
        fill: $colorMuted;
      }

      &:hover {
        svg {
          fill: darken($colorMuted, 10%);
        }
      }

      &:before {
        @include spinner($color: $colorMuted);
      }
    }
  }

  #checkoutProfileAvatar {
    display: none;
  }

  .checkout-profile-nickname {
    margin-top: 30px;
  }

  .checkout-profile-submit {
    width: 100%;
    margin: 30px 0;
  }
}
