@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/helpers/cards';

.PinnedPosts {
  margin-bottom: 56px;

  @include below(md) {
    border-bottom: 1px solid $colorBorderLight;
  }

  .pinned-title {
    @include typo-headline5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 16px;

    @include below(md) {
      padding: 0 $containerSpacing 16px $containerSpacing;
      border-bottom: 1px solid $colorBorderLight;
    }
  }

  .PostCard {
    margin-bottom: 20px;

    @include below(md) {
      margin-bottom: 8px;
    }

    @include below(sm) {
      box-shadow: none;
      border-radius: 0;
      border: none;
    }
  }

  .PostCard + .PostCard {
    margin-top: 16px;

    @include below(sm) {
      border-top: 8px solid $colorBorderLight;
    }
  }
}
