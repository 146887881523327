@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.ToastComponent {
  background-color: $colorPanel;
  color: $colorWhite;
  border-radius: 4px;
  overflow: hidden;

  .toast-component-container {
    display: flex;
    align-items: center;
  }

  .toast-component-icon {
    padding: 10px 15px 10px 15px;
    background-color: rgba(black, 0.1);

    svg {
      fill: $colorWhite;
      @include size(24px);
    }
  }

  .toast-component-content {
    padding: 10px 15px 10px 15px;
  }

  &.success {
    .toast-component-container {
      background-color: rgba($colorGreen, 0.4);
    }
  }

  &.error {
    .toast-component-container {
      background-color: rgba($colorError, 0.4);
    }
  }

  transition: transform 150ms ease-out, opacity 150ms ease-out;

  // Transitions
  &.entering {
    transform: translate3d(120%, 0, 0);
  }

  &.entered {
    transform: translate3d(0, 0, 0);
  }

  &.exiting {
    transform: translate3d(120%, 0, 0);
  }

  &.exited {
    transform: translate3d(120%, 0, 0);
  }
}
