@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.PollBasicOption {
  position: relative;
  border-radius: 24px;
  background-color: rgba(var(--color-creator-primary-rgb), 0.4);
  padding: 8px 16px;
  margin-bottom: 8px;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  transition: transform 150ms ease-out;

  display: flex;

  .poll-basic-option-bar {
    @include position(absolute, 0 null null 0);
    background-color: var(--color-creator-primary);
    height: 100%;

    transition: width 250ms ease-out;
  }

  &:hover {
    transform: scale(1.02);
  }

  &.interactive {
    padding: 8px 16px;
    margin-bottom: 8px;

    &:hover {
      transform: scale(1.04);
    }
  }

  .poll-basic-option-title {
    color: $colorWhite;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &.compact {
    font-size: 12px;
    padding: 4px 12px;
    margin-bottom: 4px;
  }

  > * {
    position: relative;
  }

  .poll-basic-option-radio {
    @include size(24px);
    fill: $colorWhite;
    margin-right: 10px;
    margin-left: -5px;
    flex-shrink: 0;
  }

  .poll-basic-option-percentage {
    color: $colorWhite;
    margin-left: auto;
    flex-shrink: 0;
    padding-left: 16px;
  }

  &:not(.selected) {
    .poll-basic-option-radio {
      fill: $colorBorder;
    }
  }
}
