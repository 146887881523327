@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/typography';

.WatchEventFeedback {
  .watch-event-feedback-event-title {
    font-weight: 800;
    text-align: center;
    margin-bottom: 10px;
    margin-top: -10px;
  }

  .watch-event-feedback-stars {
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;
    margin-bottom: 30px;

    &:not(:hover) {
      .watch-event-feedback-stars-star {
        &.active {
          .star-border-svg-fill {
            opacity: 1;
          }
        }
      }
    }

    .watch-event-feedback-stars-star {
      cursor: pointer;
      &,
      svg {
        @include size(36px);
      }

      svg {
        fill: $colorPrimary;
        .star-border-svg-fill {
          opacity: 0;
        }
      }
    }

    & > .watch-event-feedback-stars-star:hover,
    &
      > .watch-event-feedback-stars-star:hover
      ~ .watch-event-feedback-stars-star {
      svg {
        .star-border-svg-fill {
          opacity: 1;
        }
      }
    }
  }

  .watch-event-feedback-submit {
    margin-top: 30px;
    width: 100%;
  }
}
