@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/helpers/cover';
@import 'styles/helpers/cards';

.TicketCard {
  color: $colorTextPrimary;
  position: relative;

  @include card-far;
  overflow: hidden;

  .ticket-card-wrapper {
    height: 100%;
    padding: 24px;
    min-height: 470px;
    max-height: 470px;
    position: relative;
    overflow: hidden;

    display: flex;
    flex-direction: column;
  }

  .ticket-card-image {
    position: relative;
    clip-path: url(#boonCircularSquareClipPath);
    background-color: $colorBorderLight;
    object-fit: cover;

    margin-right: 16px;

    @include size(56px);
    display: flex;
    justify-content: center;
    align-items: center;

    .ticket-card-icon {
      @include size(24px);
      fill: $colorPrimary;
      fill: var(--color-creator-primary);
    }
  }

  .ticket-card-header {
    position: relative;
    flex: 0 0 auto;
    display: flex;
    margin-bottom: 24px;

    .ticket-card-header-info {
      display: flex;
      flex-direction: column;
    }

    .ticket-card-title {
      @include typo-headline6;
    }

    .ticket-card-collapsible-radio {
      @include size(24px);
      margin-top: 16px;
      fill: $colorPrimary;
      fill: var(--color-creator-primary);
      margin-left: auto;
      cursor: pointer;
    }
  }

  .ticket-card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ticket-card-price {
    font-size: 26px;
    font-weight: 500;
    letter-spacing: -0.05em;
    line-height: 1em;
    margin-top: 5px;
  }

  .ticket-card-cta {
    margin-bottom: 20px;
    min-width: 150px;
  }

  .ticket-card-perks {
    padding: 16px 24px;

    li:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &.collapsed {
    .ticket-card-wrapper {
      min-height: 0;
      max-height: none;
    }

    .ticket-card-header {
      margin-bottom: 0;
    }

    .ticket-card-cta,
    .ticket-card-perks {
      display: none;
    }
  }

  &.collapsible:not(.collapsed) {
    border-color: $colorPrimary;
    border-color: var(--color-creator-primary);
  }
}
