@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.CreatorPageFaq {
  padding: 60px 0;

  @include above(lg) {
    padding: 180px 0;
  }
}

.CreatorPageFaqItem {
  .creator-page-faq-item-header {
    display: flex;
    align-items: center;
    padding: 16px 0;
    cursor: pointer;
  }

  .creator-page-faq-item-title {
    font-family: $fontFamilyBoon;
    font-weight: 700;
  }

  .creator-page-faq-item-chevron {
    margin-left: auto;
    @include size(24px);
  }

  .creator-page-faq-item-content {
    overflow: hidden;
    padding-bottom: 24px;
    white-space: pre-wrap;
  }

  &.open {
    .creator-page-faq-item-chevron {
      transform: rotate(180deg);
    }

    .creator-page-faq-item-content {
      max-height: 1024px;
    }
  }

  &:not(.open) {
    .creator-page-faq-item-content {
      display: none;
    }
  }
}

.CreatorPageFaqItem + .CreatorPageFaqItem {
  border-top: 1px solid $colorBorderLight;
}
