@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/helpers/cards';

.PostCard {
  @include card-near;
  background-color: $colorSurface;
  width: 100%;
  border-radius: 16px;

  @include below(sm) {
    border-left: none;
    border-right: none;
    border-radius: 0;
    box-shadow: none;
  }

  .EventCard {
    box-shadow: none;

    .event-card-media-inner {
      border-radius: 0;
    }
  }

  .DocumentItem {
    margin: 0 24px;
  }
}
