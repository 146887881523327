@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.FeaturePageContainer {
  .feature-page-section-title {
    @include typo-display5;
    text-align: center;
    margin-bottom: 16px;

    @include above(lg) {
      @include typo-display4;
    }
  }

  .feature-page-section-subtitle {
    font-family: $fontFamilyBoon;
    text-align: center;
    font-size: 24px;
    margin-bottom: 16px;
  }

  .feature-page-section-border-top,
  .feature-page-section-border-bottom,
  .feature-page-section-border-bottom-inverse {
    width: 100%;
    height: 80px;

    &.feature-page-section-border-alternate {
      background-color: $colorAlternateBackground;
    }

    &.feature-page-section-border-background {
      background-color: $colorBackground;
    }

    &.feature-page-section-border-dark {
      background-color: $colorDarkBackground;
    }
  }

  .feature-page-section-border-top {
    @include above(lg) {
      border-top-left-radius: 100%;
      border-top-right-radius: 100%;
    }
  }

  .feature-page-section-border-bottom {
    @include above(lg) {
      border-bottom-left-radius: 100%;
      border-bottom-right-radius: 100%;
    }
  }

  .feature-page-section-border-bottom-inverse {
    @include above(lg) {
      border-top-left-radius: 100%;
      border-top-right-radius: 100%;
    }
  }
}
