@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

$ball-size: 30px;
@mixin ball-scale-multiple($n: 3, $start: 0) {
  @for $i from 2 through $n {
    > div:nth-child(#{$i}) {
      animation-delay: ($i - 1) * 0.2 + s;
    }
  }
}

@keyframes ball-scale-multiple {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  5% {
    opacity: 0.9;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.EventCountdown {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  .event-countdown-static {
    @include position(absolute, 0 0 0 0);
    opacity: 0.06;
    background-size: contain;
  }

  .event-countdown-time {
    display: flex;
    align-items: center;
    z-index: 1;

    .event-countdown-time-duration {
      color: rgba($colorWhite, 0.4);
      padding-left: 5px;
    }

    svg {
      fill: rgba($colorWhite, 0.4);

      &:hover {
        fill: rgba($colorWhite, 0.6);
      }
    }
  }

  .event-countdown-countdown {
    z-index: 1;
  }

  .event-countdown-live {
    display: flex;
    align-items: center;
    font-size: 32px;
    font-weight: 800;
    font-family: $fontFamilyBoon;
    line-height: 1 !important;
    position: relative;

    .event-countdown-live-dots {
      // Lovely SASS by Daniel Hansen
      @include ball-scale-multiple();
      position: absolute;
      top: 50%;
      left: -35px;
      transform: translateY(-50%);

      width: $ball-size;
      height: $ball-size;
      margin-top: 2px;
      > div {
        width: $ball-size;
        height: $ball-size;
        border-radius: 100%;
        background-color: white;
        animation-fill-mode: both;
        position: absolute;
        left: 0px;
        top: 0px;
        opacity: 0;
        margin: 0;
        animation: ball-scale-multiple 1s 0s linear infinite;
      }
    }
  }
}
