@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.PostCardEngagement {
  padding-bottom: 15px;
  margin: 16px 24px 0 24px;
  border-bottom: 1px solid $colorBorderLight;

  &.specific {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  .post-card-engagement-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  .post-card-engagement-message {
    font-size: 14px;
    span {
      font-weight: 600;
    }
  }
  .post-card-engagement-users {
    display: flex;
    flex-direction: row-reverse;
    margin-right: 10px;

    .post-card-engagement-users-avatar {
      @include size(24px);

      &:not(:last-child) {
        margin-left: -4px;
      }
    }
  }

  .post-card-engangment-message {
    line-height: 1.2;
  }
}
