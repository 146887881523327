@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.MarkdownTextEditor {
  border: 1px solid $colorBorderLight;
  border-radius: 3px;
  overflow: hidden;

  .markdown-text-editor-loader {
    height: 110px;
    position: relative;
  }

  .markdown-text-editor-error {
    min-height: 110px;
    padding: 30px 60px;
    text-align: center;

    display: flex;
    flex-direction: column;
    justify-content: center;

    .markdown-text-editor-error-title {
      color: $colorWarning;
    }

    .markdown-text-editor-error-description {
      font-size: 13px;
      color: $colorNine;
    }
  }
}
