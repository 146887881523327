@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/helpers/cards';

.UserHome {
  .UserMembership,
  .ChannelHeader {
    @include below(sm) {
      margin: 0 (-$containerSpacing);
    }
  }

  .ProfileHero {
    margin-bottom: 24px;
  }

  .ProfileFeed,
  .ProfileHero {
    @include below(sm) {
      margin-left: -$containerSpacing;
      margin-right: -$containerSpacing;
    }
  }

  .ProfileHero ~ .ProfileMenuMobile {
    margin-top: -5px;
  }

  .ProfileMenuMobile {
    margin-bottom: 5px;
    padding: 10px 24px;
  }

  .ProfileFeed {
    @include below(md) {
      margin-top: 5px;
    }
  }

  .user-home-section {
    margin: 16px 0;

    .user-home-section-title {
      text-align: center;
      margin-bottom: 24px;
      @include typo-headline2;
    }
  }

  .InviteCodeContainer {
    display: flex;
    justify-content: center;
    margin: 24px 0 48px 0;
    max-height: 200px;

    &.fadeOut {
      max-height: 0;
      transition: max-height 0.5s ease-out;
      overflow: hidden;
    }

    .invite-code-container-inner {
      display: flex;
      background-color: $colorPrimary;
      color: $colorWhite;
      padding: 20px;
      border-radius: 16px;
      max-width: 630px;

      .invite-code-container-left {
        padding: 0 16px 0 0px;
        @include below(md) {
          padding: 0 10px 0 0;
        }

        .invite-code-container-svg-circle {
          background-color: #4389fb;
          border-radius: 50px;
          @include size(40px);
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            @include size(24px);
            fill: $colorWhite;
          }
        }
      }
      .invite-code-container-middle {
        a,
        button {
          margin-top: 10px;
          display: inline-block;
        }
      }

      .invite-code-container-right {
        .invite-code-close {
          fill: white;
          cursor: pointer;
        }
      }
    }
  }
}
