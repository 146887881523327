@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.UserDashboard {
  color: $colorTextPrimary;

  .user-dashboard-title {
    @include typo-headline2;
    margin-bottom: 30px;
  }

  .user-dashboard-container {
    @include above(lg) {
      display: flex;
      justify-content: center;
    }
  }

  .user-dashboard-content {
    @include above(lg) {
      flex: 0 1 630px;
      margin-right: 30px;
    }
  }

  .user-dashboard-sidebar {
    @include above(lg) {
      flex: 0 0 300px;
      padding-top: 33px + 30px;
    }
  }

  .user-dashboard-following {
  }

  .user-dashboard-events {
    margin: 50px 0;
  }

  .user-dashboard-creator {
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    padding: 24px 20px 20px 20px;

    .user-dashboard-creator-title {
      font-weight: 800;
      margin-bottom: 10px;
    }

    .user-dashboard-creator-description {
      color: $colorNine;
      margin-bottom: 24px;
    }
  }
}
