@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/mixins/spacing';

.NewEventPage {
  color: $colorWhite;
  background-color: $colorDark4;

  .CouponAppliedBanner {
    position: static;
  }

  .new-event-page-desktop {
    display: flex;
    height: 100vh;
    overflow: hidden;

    .NewEventMenu {
      flex-shrink: 0;
    }

    .new-event-page-desktop-left {
      flex: 1;
      background-color: $colorBlack;

      display: flex;
      flex-direction: column;
      justify-content: center;

      .NewEventPlayer {
        width: 100%;
      }
    }

    .new-event-page-desktop-right {
      position: relative;
      background-color: $colorDark4;
      flex: 0 0 450px;
      width: 450px;

      @include below(lg) {
        flex-basis: 375px;
      }

      .NewEventChat {
        flex: 1 1;
      }

      .EventPost {
        flex: 1 1;
      }

      .new-event-page-desktop-right-inner {
        @include size(100%);
        display: flex;
        flex-direction: column;
      }
    }

    .NewEventChat {
      overflow: hidden;
    }
  }

  &.new-event-page-mobile {
    display: flex;
    flex-direction: column;

    .new-event-page-mobile-top {
      flex: 0 1 auto;
      overflow: hidden;
    }

    .new-event-page-mobile-content {
      flex: 1 0 80px;
      overflow-y: auto;
    }

    .new-event-page-mobile-cta {
      padding: 15px 0;
      display: flex;
      justify-content: center;
      @include position(fixed, null 0 0 0);

      background-color: rgba($colorBase, 0.8);
      backdrop-filter: saturate(180%) blur(20px);
    }

    .NewEventChat {
      height: 100%;
    }
  }

  &.with-header {
    @include page-no-header-spacing;

    .new-event-page-desktop {
      height: calc(100vh - #{$headerHeight});
    }

    &.new-event-page-mobile {
      margin-top: -$headerHeight + -$headerMarginBottom;
      padding-top: $headerHeight;
    }
  }

  .new-event-page-mock-header {
    height: $headerHeight;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 $containerSpacing;
    background-color: $colorDark4;

    .new-event-page-mock-header-sign-in {
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .PostCardLock {
    &.post-card-header-lock.open:hover svg {
      fill: $colorBorderLight;
    }
  }
}

.NewEventPurchase {
  .new-event-purchase-price {
    color: $colorMuted;
    font-weight: 400;
  }

  @include below(sm) {
    .button {
      width: 100%;
    }
  }
}

.NewEventPlayer {
  padding-top: 56.25%; // 16:9
  position: relative;

  .new-event-player-inner {
    @include position(absolute, 0 0 0 0);

    // Fixes sizing bug in IE
    .video-js {
      width: 100% !important;
      height: 100% !important;
    }
  }
}

.NewEventVideoPlayer {
  position: relative;

  &:not(.has-access) {
    .vjs-paused {
      .NewEventPlayerOverlay {
        display: block;
      }
    }
  }

  .vjs-big-play-button {
    border: none !important;
    background: none !important;

    .vjs-icon-placeholder:before {
      content: '' !important;
      background-color: white;
      mask: url('../../../assets/svg/PlayRoundedSvg.svg') no-repeat center
        center;
      mask-size: 90px;
      border: none !important;
      box-shadow: none !important;
      transition: 0.5s;
    }

    &:hover .vjs-icon-placeholder:before {
      background-color: $colorBorder;
    }
  }
}

.NewEventPlayerOverlay {
  @include position(absolute, 50% null null 50%);
  transform: translate(-50%, -50%);
  display: none;

  .button {
    align-items: center;
    padding-bottom: 9px;

    svg {
      @include size(34px);
      margin-bottom: 1px;
      margin-left: -8px;
      margin-right: 4px;
    }
  }
}

.NewEventMenu {
  display: flex;
  box-shadow: inset 0 -1px 0 0 rgba(255, 255, 255, 0.05);
  padding: 0 24px;
  height: 48px;
  align-items: center;
  background-color: $colorPanelBackground;

  .new-event-menu-item {
    @include typo-medium;
    position: relative;
    align-items: center;
    height: 100%;
    display: flex;

    &.access {
      cursor: pointer;
    }

    &:not(:last-child) {
      margin-right: 20px;
    }

    &.active {
      &:before {
        content: '';
        @include position(absolute, null 0 -1px 0);
        background-color: white;
        height: 3px;
        border-top-left-radius: 99px;
        border-top-right-radius: 99px;
      }
    }

    &.no-access {
      color: $colorTextSecondary;
      cursor: auto;
    }

    .new-event-menu-live {
      @include size(8px);
      border-radius: 50%;
      background-color: $colorGreen;
      margin-top: 3px;
      margin-left: 8px;

      animation: live-animation 2s linear infinite;

      @keyframes live-animation {
        0% {
          opacity: 0.5;
        }
        50% {
          opacity: 1;
        }
        100% {
          opacity: 0.5;
        }
      }
    }
  }
}

.NewEventVideoProcessing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(white, 0.05);
  padding: 60px;
  text-align: center;
  height: 100%;
  width: 100%;

  background-position: center;
  background-size: cover;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: black;
    opacity: 0.5;
  }

  .new-event-video-processing-title {
    @include typo-headline4;
    margin-bottom: 5px;
    position: relative;
  }

  .new-event-video-processing-subtitle {
    position: relative;
  }
}
