@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.CheckoutSuccessPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;

  .checkout-success-page-container {
    width: 100%;
    max-width: 450px;
  }

  .checkout-success-page-next {
    svg {
      fill: white;
      @include size(24px);
    }
  }

  .checkout-success-page-banner {
    background-color: rgba($colorSuccess, 0.1);
    padding: 16px 24px;

    width: 100%;
    border-radius: 16px;
    margin-bottom: 24px;
    text-align: center;

    .checkout-success-page-banner-row {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 16px;
    }

    .checkout-success-page-banner-message {
      color: $colorSuccess;
    }

    .checkout-success-page-banner-icon {
      @include size(24px);
      fill: $colorSuccess;
      margin-right: 8px;
    }

    .checkout-success-page-banner-email {
      color: $colorMuted;
    }
  }

  .checkout-success-page-continue {
    width: 100%;
    margin: 30px 0;
  }
}
