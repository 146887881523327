@import 'styles/global/colors';
@import 'styles/mixins/typography';

.Countdown {
  display: flex;
  justify-content: center;
  color: $colorWhite;

  // Make numbers of equal width, without monospace font
  font-variant-numeric: tabular-nums;

  .countdown-date-component {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .countdown-date-component-value {
    font-size: 32px;
    font-weight: 800;
  }

  .countdown-date-component-name {
    font-size: 13px;
  }

  .countdown-date-component-separator {
    font-size: 32px;
    font-weight: 800;

    margin: 0 2px;

    &:last-child {
      display: none;
    }
  }
}
