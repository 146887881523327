@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/helpers/cards';

.CreatorSettingsPayoutAccount {
  @include card-near;
  background-color: $colorSurface;
  padding: 30px;

  .creator-settings-payout-account-header {
    @include typo-headline5;
    margin-bottom: 20px;
  }

  .creator-settings-payout-account-description {
    margin-bottom: 20px;
    color: $colorTextSecondary;
  }

  .button {
    display: inline-flex;

    @include below(sm) {
      width: 100%;
    }

    &:first-child {
      margin-right: 15px;
    }
  }
}
