@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.PostCardMessage {
  font-size: 16px;
  padding: 0 24px;
  margin: 16px 0;

  white-space: pre-wrap;
  overflow: hidden;
  word-break: break-word;

  .post-card-message-show-hide-link {
    font-weight: bold;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

    &.more {
      margin-left: 5px;
    }
  }

  a {
    text-decoration: underline;
    text-underline-position: under;
    text-decoration-color: $colorTextMuted;

    &:hover {
      text-decoration-color: $colorTextPrimary;
      color: inherit;
    }
  }

  .post-card-message-hidden {
    margin-bottom: 20px;

    .post-card-message-hidden-line {
      background: $colorBorderLight;
      border-radius: 6px;
      height: 12px;
      margin-bottom: 12px;
    }
  }

  .post-card-message-unlock {
    margin-bottom: 16px;
  }
}
