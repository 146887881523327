@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/helpers/cards';

.CreatorMembershipUpsertPlaceholder {
  @include card-far;
  min-height: 200px;

  @include above(lg) {
    height: 473px;
  }

  display: flex;
  align-items: center;
  justify-content: center;
}
