@mixin free-badge() {
  color: $colorWhite;
  background-color: var(--color-creator-primary);
  text-transform: uppercase;
  padding: 2px 6px 2px 6px;
  font-weight: 700;
  margin-left: 6px;
  border-radius: 3px;
  line-height: 18px;
}
