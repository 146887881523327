@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.LightModal {
  z-index: $zOverlay;

  .light-modal-wrapper {
    width: 100%;
    height: 100%;
    max-width: 85vw;
    max-height: 85vh;
    overflow: hidden;

    display: flex;
    flex-direction: column;
  }

  .light-modal-header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;

    .light-modal-header-close {
      fill: $colorWhite;
      @include size(24px);
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .light-modal-content {
    @include size(100%);
    overflow: hidden;

    img {
      max-height: 80vh;
      max-width: 80vw;
      object-fit: scale-down;
    }
  }

  video {
    max-width: 75vw;
    max-height: 75vh;
    border-radius: 4px;

    &:focus {
      outline: none;
    }
  }
}
