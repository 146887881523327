@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/helpers/cards';

.PayoutListItem {
  @include card-near;
  display: block;
  width: 100%;
  padding: 15px 20px;
  overflow: hidden;

  &:hover {
    color: inherit;
    text-decoration: none;
  }

  &:not(.active):hover {
    background-color: darken($colorSurface, 5%);
  }

  &.active {
    background-color: darken($colorSurface, 5%);
  }

  .payout-list-item-header {
    display: flex;

    .payout-list-item-header-date {
      flex: 0 0 auto;
      font-weight: 800;
    }

    .payout-list-item-header-amount {
      flex: 1;
      text-align: right;
    }

    .payout-list-item-header-status {
      text-align: right;
      flex: 0 0 auto;
      margin-left: 15px;
    }
  }

  .payout-list-item-header-status-tag {
    display: inline;
    padding: 2px 6px;
    border-radius: 2px;
    background-color: rgba(white, 0.3);

    &.payout-list-item-header-status-tag-paid {
      background-color: rgba($colorSuccess, 0.5);
    }

    &.payout-list-item-header-status-tag-pending {
      background-color: rgba($colorBlue, 0.5);
    }
  }
}
