@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/mixins/spacing';

.Videos {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(1, minmax(0, 1fr));

  @include above(md) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @include above(lg) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @include above(xl) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  &.videos-loading {
    grid-template-columns: none;
    width: 100%;
    min-height: calc(
      100vh - 58px - 19px - 50px - 20px - #{$headerHeight} - 80px
    ); // Height of tabs, header,
    position: relative;

    .Spinner {
      top: 40px;
    }
  }
}
