@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.ProfileMenuMobileModal {
  display: flex;

  .modal-content {
    width: 25%;
  }
}

.ProfileMenuMobileModal {
  color: $colorTextPrimary;

  .ProfileMenu {
    overflow-y: auto;
    padding-bottom: 16px;
  }

  &.MenuModal {
    .menu-modal-content {
      display: flex;
      flex-direction: column;
      padding: 0;
      height: 100%;
      background-color: $colorSurface;
    }
  }

  .profile-menu-mobile-modal-close {
    cursor: pointer;
    position: absolute;
    right: 18px;
    top: 18px;
    padding: 7px 8px 8px 8px;
    width: 40px;
    height: 40px;
    background-color: $colorBorderLight;
    border-radius: 100%;

    @include below(md) {
      top: initial;
      right: initial;
      bottom: 24px;
      left: 50%;
      transform: translateX(-50%);
    }

    .profile-menu-mobile-modal-close-icon {
      @include size(24px);
    }

    &:hover {
      svg {
        fill: $colorTextMuted;
      }
    }
  }
}
