@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/shadows';
@import 'styles/modules/bourbon';

.SimpleNamedModal {
  .modal-content {
    display: flex;
    flex-direction: column;
    background-color: white;
  }

  // TODO: HACK FOR OVERLAY ON BURGER MENU
  z-index: 2020;

  @include media-breakpoint-down(sm) {
    display: flex;
    justify-content: unset;
    align-items: unset;

    .modal-content {
      flex-direction: column;

      margin: 0;
      width: 100%;
      height: 100vh;
    }

    .modal-overlay {
      display: none;
    }

    &#{&}-enter,
    &#{&}-appear {
      .modal-content {
        bottom: -100%;
      }
    }

    &#{&}-enter-active,
    &#{&}-appear-active {
      .modal-content {
        bottom: 0;
        transition: all 250ms ease-out;
      }
    }

    &#{&}-exit {
      .modal-content {
        bottom: 0;
      }
    }

    &#{&}-exit-active {
      .modal-content {
        bottom: -100%;
        transition: all 250ms ease-in;
      }
    }
  }

  @include media-breakpoint-up(sm) {
    .modal-content {
      width: 400px;
      @include box-shadow-far;
      border-radius: 16px;
      overflow: hidden;
    }

    &#{&}-enter,
    &#{&}-appear {
      .modal-overlay {
        opacity: 0;
      }

      .modal-content {
        opacity: 0;
        transform: scale(0.9);
      }
    }

    &#{&}-enter-active,
    &#{&}-appear-active {
      .modal-overlay {
        opacity: 1;
        transition: all 0.2s ease-in-out;
      }

      .modal-content {
        opacity: 1;
        transform: scale(1);
        transition: all 0.2s ease-in-out;
      }
    }

    &#{&}-exit {
      .modal-overlay {
        opacity: 1;
      }

      .modal-content {
        opacity: 1;
        transform: scale(1);
      }
    }

    &#{&}-exit-active {
      .modal-overlay {
        opacity: 0;
        transition: all 0.2s ease-in-out;
      }

      .modal-content {
        opacity: 0;
        transition: all 0.2s ease-in-out;
        transform: scale(0.9);
      }
    }
  }

  .simple-named-modal-header {
    display: flex;
    align-items: center;

    padding: 30px 30px 30px 30px;

    .simple-named-modal-header-title {
      font-size: 20px;
      font-weight: 800;
    }

    svg {
      @include size(35px);
      cursor: pointer;
      fill: rgba($colorMuted, 0.5);
      margin-left: auto;

      &:hover {
        fill: rgba($colorMuted, 0.3);
      }
    }
  }

  .simple-named-modal-content {
    flex: 1 1 auto;
    padding: 30px;
    background-color: white;
    -webkit-overflow-scrolling: touch;
  }
}
