@import '../../styles/modules/bourbon';
@import '../../styles/global/colors';
@import '../../styles/mixins/responsiveness';
@import '../../styles/mixins/typography';

.CheckoutModal {
  color: $colorTextPrimary;

  .modal-content {
    background-color: $colorBackground;
    position: relative;
    padding: 24px;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    overflow: auto;

    @include above(lg) {
      width: 100%;
      height: 100%;
    }

    @include below(md) {
      width: 100%;
      min-height: 100%;
    }
  }

  .checkout-modal-centered {
    max-height: 100%;
  }

  .checkout-modal-mobile-wrapper {
    .TicketCard,
    .ProductCard {
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }

  .checkout-modal-desktop-wrapper {
    .TicketCard {
      width: 295px;
    }
    .ProductCard {
      width: 295px;
    }
  }

  .checkout-modal-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: 50px;
    margin-bottom: 40px;

    .checkout-modal-header-lock {
      @include size(18px);
      fill: $colorWarning;
      margin-bottom: 25px;
    }

    .checkout-modal-header-title {
      @include typo-headline2;
      text-align: center;
      margin-bottom: 20px;
    }

    .checkout-modal-header-subtitle {
      font-weight: 500;
    }
  }

  .checkout-modal-carousel {
    position: relative;
    //width: 100%;

    .carousel__slider-tray {
      padding-inline-start: 0;
      list-style-type: none;
    }
  }

  .carousel__inner-slide {
    margin: 0 10px;
  }

  .checkout-modal-carousel-transition {
    transition: transform 250ms;
    transition-timing-function: ease-out;
    will-change: transform;
  }

  .checkout-modal-sign-in {
    @include position(absolute, 24px 24px null null);
    cursor: pointer;

    &:hover {
      color: white;
    }
  }

  .checkout-modal-close {
    @include size(40px);
    @include position(absolute, 20px null null 24px);

    display: flex;
    align-items: center;
    justify-content: center;

    margin-left: auto;

    border-radius: 50%;
    cursor: pointer;
    z-index: $zOverlay;

    background: $colorBorderLight;

    .checkout-modal-close-icon {
      @include size(24px);
      fill: #bbb;
    }

    &:hover {
      .checkout-modal-close-icon {
        fill: #aaa;
      }
    }
  }

  .checkout-modal-options {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    min-height: 300px;
    position: relative;

    margin: -10px 0;

    > * {
      margin: 20px 10px;
    }

    .checkout-modal-mock-card {
      position: relative;
      width: 295px;
      height: 420px;
    }
  }

  .checkout-modal-back-button {
    max-width: 150px;
    margin: 0 auto;
  }

  .checkout-modal-empty {
    max-width: 600px;
    text-align: center;
    background-color: $colorWarning;
    padding: 16px;
    border-radius: 16px;
    color: $colorBlack;
    font-weight: 800;
    margin: 0 auto;
  }
}
