/* Typography */
$fontFamily: 'Inter', 'Helvetica', sans-serif;
$fontFamilyBoon: 'Sharp Sans Display No2', 'Helvetica', sans-serif;

$footerMargin: 60px;
$footerBodyHeightDesktop: 80px;
$footerBodyHeightMobile: 100px;
$footerHeightDesktop: $footerMargin + $footerBodyHeightDesktop;
$footerHeightMobile: $footerMargin + $footerBodyHeightMobile;

$headerHeightMobile: 64px;
$headerHeight: 64px;
$headerMarginBottom: 30px;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1600px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1410px,
  xxxl: 1570px,
);

$containerSpacing: 24px;

$borderRadiusLarge: 16px;
$borderRadiusMedium: 8px;
$borderRadiusSmall: 4px;

$zBelowHeader: 90;
$zHeader: 100;
$zPageTitle: 110;
$zBanner: 120;
$zBannerContent: 121;
$zFooter: 125;
$zSidebar: 130; // From old sidebar
$zHeaderLogo: 140;
$zOverlay: 140;
