@import 'styles/global/colors';

$pollDefaultBackground: $colorSurface;
$pollDefaultColor: $colorTextPrimary;
$pollNextLiveBackground: #523334;
$pollNextLiveColor: $colorRed;
$pollTellMeBackground: #314b42;
$pollTellMeColor: #31ce92;
$pollAskMeBackground: #394960;
$pollAskMeColor: $colorBlue;
