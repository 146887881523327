@import 'styles/global/variables';
@import 'styles/global/colors';

.PostList {
  position: relative;

  .post-list-loading {
    min-height: calc(
      100vh - 58px - 19px - 50px - 20px - #{$headerHeight} - 80px
    ); // Height of tabs, header,
    position: relative;

    .Spinner {
      top: 40px;
    }
  }

  .post-list-error {
    display: flex;
    flex-direction: column;
    align-items: center;

    .post-list-error-message {
    }

    .post-list-error-retry {
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        opacity: 0.6;
      }
    }
  }

  .profile-feed-post-button {
    margin-left: auto;
    margin-bottom: 16px;

    svg {
      fill: $colorWhite;
      fill: var(--color-creator-text-primary);
      margin-right: 8px;
    }
  }
}
