@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/helpers/cover';
@import 'styles/helpers/cards';

.CreatorShowcaseCard {
  @include card-extra-far;
  overflow: hidden;
  cursor: pointer;
  transition: transform 250ms ease-out;
  margin-right: 16px;

  &:hover {
    transform: translateY(-16px);
  }

  .creator-showcase-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }

  .creator-showcase-card-cover {
    @include userCover;
    width: 100%;
  }

  .creator-showcase-card-avatar {
    @include size(128px);
    box-sizing: content-box;
    border: 4px solid $colorSurface;
    margin-top: -34px;
  }

  .creator-showcase-card-content {
    padding: 24px;
    text-align: center;
    overflow: hidden;
    width: 100%;
  }

  .creator-showcase-card-name {
    @include typo-headline4;
    overflow: hidden;
    margin-bottom: 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: $colorTextPrimary;
  }

  .creator-showcase-card-title {
    @include typo-small;
    margin-bottom: 48px;
  }

  .creator-showcase-card-stats {
    display: flex;
    justify-content: center;
    min-height: 17px;

    .creator-showcase-card-stats-item {
      @include typo-small;
      &:not(:last-child) {
        margin-right: 16px;
      }
    }
  }
}
