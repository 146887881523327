@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/mixins/spacing';

.UploadPage {
  padding-bottom: 60px;
  color: $colorTextPrimary;

  @include page-no-header-spacing;
  .narrow-container {
    padding-top: $headerMarginBottom;
  }

  .upload-page-title {
    @include typo-headline4;
    @include above(md) {
      @include typo-headline3;
    }
  }
}
