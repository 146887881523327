@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.CheckoutPaymentPrice {
  border: 2px solid #eaeaea;
  position: relative;
  &:not(.active) {
    cursor: pointer;
  }

  &.active {
    border: 2px solid $colorPrimary;
  }
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 10px;
  .checkout-payment-price-header {
    display: flex;
    .checkout-payment-price-name {
      font-weight: bold;
      font-size: 16px;
    }
    .checkout-payment-price-discount {
      margin-left: auto;
      color: $colorPrimary;
    }
  }

  .checkout-payment-invite-code {
    margin-top: 15px;
    display: flex;
    align-items: center;
    .avatar {
      @include size(20px);
      margin-right: 10px;
    }
  }
}
