@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.SubscribePageHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  $productImageHeight: 56px;

  .subscribe-page-header-image {
    display: block;
    position: relative;
    clip-path: url(#boonCircularSquareClipPath);
    background-color: $colorBorderLight;
    object-fit: cover;
    margin-bottom: 12px;
  }

  .subscribe-page-header-title {
    @include typo-headline5;
    margin-bottom: 4px;
  }

  .subscribe-page-header-subtitle {
    @include typo-medium;
  }
}
