@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/helpers/cards';

.HeaderControlsBurgerMenuModal {
  .modal-content {
    overflow-y: scroll;
    color: white;
    background-color: $colorPrimary;
  }

  .header-controls-burger-menu-modal-logo {
    fill: white;
    position: absolute;
    left: 24px;
    top: 17px;
    height: 24px;
    width: auto;
  }

  .header-controls-burger-menu-modal-close {
    position: absolute;

    right: 24px;
    top: 18px;
  }

  .header-controls-burger-menu-modal-close-icon {
    fill: white;
  }

  .header-controls-burger-menu-modal-menu {
    padding-left: 24px;
    padding-right: 24px;
    margin-top: 75px;

    .profile-button.primary.light,
    .login-button.primary.light,
    .signout-button.primary.light {
      background-color: lighten($colorPrimary, 10%);
      border: none;
      color: white;
    }

    .button + .button {
      margin-top: 8px;
    }
  }
}
