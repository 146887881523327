@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.FollowButton {
  color: var(--color-creator-primary) !important;

  &.following {
    color: $colorTextSecondary !important;

    svg {
      fill: $colorTextSecondary;
    }
  }

  &.unfollow {
    transition: none;
    color: $colorWhite !important;
    border-color: $colorRed !important;
    background-color: $colorRed !important;

    svg {
      fill: $colorWhite;
    }
  }

  svg {
    @include size(18px);
    fill: var(--color-creator-primary);
    margin-left: -3px;
    margin-right: 3px;
    pointer-events: none;
  }
}
