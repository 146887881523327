@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.EventPublishModal {
  .event-publish-modal-description {
    color: $colorMuted;
    margin-top: -20px;
    margin-bottom: 20px;
  }

  .event-publish-modal-availability {
    padding-bottom: 10px;
    margin-bottom: 30px;
    border-bottom: 1px solid rgba(black, 0.1);

    .event-publish-modal-availability-title {
      font-weight: 800;
      margin-bottom: 15px;
    }

    .event-publish-modal-availability-item {
      display: flex;
      margin-bottom: 20px;

      .event-publish-modal-availability-item-icon {
        @include size(24px);
        margin-top: 2px;
      }

      .event-publish-modal-availability-item-inner {
        margin-left: 6px;
      }

      .event-publish-modal-availability-item-title {
      }

      .event-publish-modal-availability-item-property {
        @include typo-small;
        color: $colorMuted;
      }
    }
  }

  .event-publish-modal-notify-followers-label {
    .event-publish-modal-notify-followers-label-small {
      @include typo-small;
      color: $colorMuted;
    }
  }

  .event-publish-modal-confirm {
    width: 100%;
    margin-top: 30px;
  }
}
