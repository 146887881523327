@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.PostComment {
  margin: 20px 24px 20px 24px;

  &[highlight='true'] {
    position: relative;
    &:after {
      content: '';
      @include position(absolute, -20px -24px -8px -24px);
      animation: comment-highlight 1000ms ease-out;
      animation-delay: 500ms;
      pointer-events: none;
    }
  }

  .post-comment-replies {
    .PostCommentReply:last-child {
      margin-bottom: 0;
    }
  }

  .post-comment-view-all {
    cursor: pointer;
    margin: 5px 0 15px 0;
    font-weight: 600;
    font-size: 14px;

    color: $colorPrimary;

    &:hover {
      color: $colorBlack;
    }
  }
}

@keyframes comment-highlight {
  0%,
  50%,
  100% {
    background-color: transparent;
  }
  25%,
  75% {
    background-color: rgba(var(--color-creator-primary-rgb), 0.2);
  }
}
