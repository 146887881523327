@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.PostCommentBody {
  display: flex;

  .post-comment-body-user-avatar {
    @include size(32px);
    margin-right: 10px;
    flex-shrink: 0;
  }

  .post-comment-body-inner {
    flex: 1;
  }

  .post-comment-body-message {
    word-break: break-word;
    font-size: 14px;
    white-space: pre-wrap;
    a {
      text-decoration: underline;
    }
  }

  .post-comment-body-header {
    display: flex;
    @include typo-small;
    overflow: hidden;
  }

  .post-comment-body-user-name {
    font-weight: 700;
    flex-shrink: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .post-comment-body-date {
    flex-shrink: 0;
    color: $colorTextSecondary;
    margin-left: 4px;
  }

  .post-comment-actions {
    display: flex;
    align-items: center;

    .post-comment-actions-action {
      margin: 10px 0;
      display: flex;
      align-items: center;
      @include typo-small;
      color: $colorLightGrey;
      cursor: pointer;
      font-weight: 500;

      &:not(:last-child) {
        margin-right: 20px;
      }

      .post-comment-actions-action-icon {
        fill: $colorLightGrey;
        @include size(18px);
        margin-right: 6px;
      }

      &:hover {
        color: $colorTextPrimary;
        .post-comment-actions-action-icon {
          fill: $colorTextPrimary;
        }
      }

      &.active {
        color: $colorPrimary;
        .post-comment-actions-action-icon {
          fill: $colorPrimary;
        }
      }

      &.error {
        color: $colorError;
        .post-comment-actions-action-icon {
          fill: $colorError;
        }
      }
    }
  }

  .post-comment-compose {
    margin-top: 12px;
  }

  &.reply {
    .post-comment-body-user-avatar {
      @include size(24px);
    }
  }
}
