@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/mixins/spacing';

.CouponAppliedBanner {
  width: 100%;
  padding: 16px 24px;
  @include position(fixed, #{$headerHeight} 0 null 0);
  z-index: $zBelowHeader;

  @include below(sm) {
    padding: 5px 15px;
  }

  display: flex;
  align-items: center;

  color: $colorWhite;
  background-color: $colorPrimary;
  flex-wrap: wrap;

  .coupon-applied-banner-remove {
    text-decoration: underline;
    cursor: pointer;
    margin-left: 5px;

    &:hover {
      opacity: 0.8;
    }
  }
}
