@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/helpers/hr';
@import 'styles/mixins/typography';

.AuthModal {
  .BoonTextField {
    margin-bottom: 20px;
  }

  button {
    width: 100%;
    margin-bottom: 15px;

    & + .input-error {
      margin-top: -10px;
    }
  }

  .forgot-password {
    margin-top: 20px;
    text-align: center;
  }

  .signup {
    margin-top: 40px;
    text-align: center;
  }

  .terms {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 40px;
  }

  a,
  .textlink {
    cursor: pointer;
    font-weight: 800;
    text-decoration: underline;
    text-decoration-color: $colorBorder;

    &:hover {
      text-decoration-color: $colorBlack;
    }
  }

  .or {
    @include hr;
    margin-bottom: 20px;
  }

  .signup-avatar-row {
    display: flex;
    align-items: center;

    .signup-field-name {
      margin-left: 20px;
      width: 100%;
    }
  }

  .signup-message {
    @include typo-small;
  }

  .signup-avatar-upload {
    flex: 0;
    margin-bottom: 10px;

    #signup-avatar-upload-input {
      display: none;
    }

    label {
      @include size(90px);
      border-radius: 50%;
      background-color: $colorBackground;
      background-position: center;
      background-size: cover;
      cursor: pointer;

      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        @include size(30px);
      }
    }
  }

  .success-message {
    text-align: center;
    font-size: 24px;
    line-height: 1.25;
    font-weight: 800;
    color: $colorSuccess;
  }
}
