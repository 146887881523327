@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/helpers/cards';

.PostCardLock {
  position: relative;

  &.open {
    .post-card-lock-button {
      //background-color: rgba(black, 0.2);
    }
  }

  .post-card-lock-button {
    @include size(36px);
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      @include size(18px);
    }

    .post-card-lock-button-locked,
    .post-card-lock-button-public {
      fill: $colorTextPrimary;
    }

    .post-card-lock-button-unlocked {
      fill: rgba(black, 0.6);
    }
  }

  &.dark {
    .post-card-lock-button-locked,
    .post-card-lock-button-public {
      fill: $colorWhite;
    }

    .post-card-lock-button-unlocked {
      fill: rgba(white, 0.6);
    }
  }
}

.PostCardLockMenu {
  @include position(absolute, 40px 0 null null);
  @include card-near;
  z-index: 10;

  &:before {
    content: '';
    @include position(absolute, -40px 0 null 0);
    height: 40px;

    @media (hover: none) {
      pointer-events: none;
    }
  }

  color: $colorBlack;
  background-color: white;
  border-radius: 16px;
  padding: 15px;
  min-width: 280px;

  .post-card-lock-menu-header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    .post-card-lock-menu-header-title {
      font-weight: 800;
    }
  }

  .post-card-lock-menu-option {
    display: flex;
    align-items: center;

    .post-card-lock-menu-option-name {
      margin-right: 15px;
      margin-bottom: 8px;
    }

    .post-card-lock-menu-option-price {
      margin-left: auto;
      white-space: nowrap;
      text-align: right;
    }

    .post-card-lock-menu-option-purchased {
      fill: $colorBlack;
      @include size(18px);
    }
  }

  .post-card-lock-menu-cta {
    margin-top: 15px;
    width: 100%;
  }
}
