@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.CreatorPagePerks {
  .creator-page-perks-item {
    display: flex;
    align-items: center;

    font-weight: 600;

    .creator-page-perks-item-check {
      margin-right: 20px;
      @include size(14px, auto);
    }

    a:hover {
      text-decoration: none;
      color: $colorPrimary;
    }
  }

  .creator-page-perks-item + .creator-page-perks-item {
    margin-top: 16px;
  }
}
