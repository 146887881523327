@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/mixins/spacing';
@import 'styles/helpers/cards';

.UpdateSubscriptionPaymentPage {
  @include size(100vw, 100vh);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .update-subscription-payment-page-box {
    @include card-extra-far;
    padding: 32px;
  }

  .update-subscription-payment-page-title {
    @include typo-headline4;
    margin-bottom: 32px;
  }

  .update-subscription-payment-page-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .update-subscription-payment-page-loading-title {
      @include typo-headline3;
      margin-bottom: 48px;
    }

    .Spinner {
      position: static;
      transform: none;
    }
  }

  .update-subscription-payment-page-error {
    .update-subscription-payment-page-error-back {
      width: 100%;
    }
  }
}
