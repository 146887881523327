@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/shadows';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/helpers/box';
@import 'styles/helpers/cards';

.EventEditForm {
  margin-top: 30px;

  .react-datepicker-wrapper {
    width: 100%;

    .react-datepicker__input-container {
      width: 100%;
    }
  }

  .edit-event-form-header {
    display: flex;
    margin-bottom: 60px;

    @include media-breakpoint-down(sm) {
      padding: 0 15px;
    }

    .edit-event-form-header-left {
    }

    .edit-event-form-header-right {
      margin-left: auto;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    .edit-event-form-header-title {
      @include typo-headline2;
    }
  }

  .react-datepicker-popper {
    &[data-placement='top-start'] {
      top: 35px !important;
    }
  }

  .edit-event-form-section {
    @include card-near;
    @include box-mobile-stretch;
    padding: 30px;
    margin-bottom: 30px;

    .BoonFormGroup {
      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }
  }

  .edit-form-submit-bottom {
    display: flex;

    @include media-breakpoint-down(sm) {
      flex-direction: column-reverse;
      text-align: center;
    }

    .edit-form-submit-bottom-text {
      display: inline-block;
      flex: 1;

      @include media-breakpoint-up(md) {
        padding-right: 15px;
      }
    }

    .button {
      margin-bottom: 30px;
      white-space: nowrap;

      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }
  }

  .schedule-page-event-submit {
    display: inline-block;
    margin-left: auto;

    @include media-breakpoint-down(sm) {
      display: block;
      width: 80%;
    }
  }

  .event-edit-form-group-storter {
    .boon-form-group-content {
      max-width: 300px;
    }
  }

  label.test {
    margin-bottom: 0;
  }

  .edit-event-form-categories {
    .boon-form-group-content {
      overflow: visible;
    }
  }
}
