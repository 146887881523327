@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.EventPageModal {
  .page-modal-close {
    background-color: $colorDark4 !important;
    @include below(md) {
      display: none !important;
    }

    &:hover {
      background-color: lighten($colorDark4, 5%) !important;
    }
  }

  .modal-content {
    height: 100% !important;
  }

  .NewEventPage {
    height: 100% !important;
  }
}
