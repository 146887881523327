@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.CreatorSettingsMembership {
  .BoonFormGroup {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  .CreatorSettingsPaymentOptionsPanel {
    margin-bottom: 20px;
  }
}
