@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/helpers/cards';

.FeaturePageNavigation {
  padding: 80px 0;

  .feature-page-navigation-pages {
    margin-top: 40px;

    display: grid;
    grid-template-columns: repeat(auto-fill, 330px);

    grid-gap: 24px;
    justify-content: center;

    @include above(xl) {
      grid-template-columns: repeat(3, 330px);
    }
  }

  .feature-page-navigation-page {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    transition: transform 250ms ease-in-out;

    @include size(330px, 250px);

    @include card-extra-far;
    padding: 24px;

    &:hover {
      color: inherit;
      text-decoration: none;
      transform: translateY(-8px);
    }

    .feature-page-navigation-page-title {
      font-family: $fontFamilyBoon;
      font-size: 20px;
      font-weight: 800;
      margin-bottom: 16px;
    }

    .feature-page-navigation-page-description {
    }

    .feature-page-navigation-page-more {
      font-weight: 600;
      text-decoration: underline;
      text-underline-position: under;
      text-decoration-color: $colorBorderLight;

      margin-top: auto;
    }

    &.active {
      color: $colorDarkTextPrimary;
      background-color: $colorPrimary;
    }
  }
}
