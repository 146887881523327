@import 'styles/global/colors';
@import 'styles/modules/bourbon';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.LeadQualificationAddSocialModal {
  .lead-qualification-add-social-form {
    .BoonTextField + .BoonTextField {
      margin-top: 24px;
    }

    .lead-qualification-add-social-form-submit {
      width: 100%;
      margin-top: 24px;
    }
  }
}
