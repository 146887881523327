@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.RelationsMenuModal {
  color: $colorTextPrimary;

  display: flex;

  .modal-content {
    width: 35%;
  }

  &.MenuModal {
    .menu-modal-content {
      overflow-y: scroll;
      overflow-x: hidden;
      display: flex;
      flex-direction: column;
      padding: 0;
      height: 100%;
      background-color: $colorSurface;
    }
  }

  .relations-menu-modal-header {
    position: absolute;
    width: 100%;
    height: 75px;
    background: $colorSurface;
  }

  .relations-menu-modal-close {
    cursor: pointer;
    position: relative;
    right: 18px;
    top: 18px;
    float: right;
    padding: 7px 8px 8px 8px;
    width: 40px;
    height: 40px;
    background-color: $colorBorderLight;
    border-radius: 100%;

    .relations-menu-modal-close-icon {
      @include size(24px);
    }

    &:hover {
      svg {
        fill: $colorTextMuted;
      }
    }
  }

  .relations-menu-modal-name {
    @include typo-headline4;
    position: absolute;
    left: 18px;
    top: 18px;
    padding: 7px 8px 8px 8px;
  }

  .relations-menu-modal-avatar-wrapper {
    margin: 100px auto 14px auto;
  }

  .relations-menu-modal-avatar {
    height: 150px;
    width: 150px;
  }

  .relations-menu-modal-table-wrapper {
    margin: 16px;
  }

  .relations-menu-modal-table {
    width: 100%;

    tr:not(:last-of-type) {
      border-bottom: 1px solid $colorBorderLight;
    }

    td {
      padding: 8px;
      white-space: nowrap;
    }

    td:first-of-type {
      text-align: left;
      width: 50%;
    }

    td:last-of-type {
      text-align: right;
      overflow: hidden;
    }

    td:last-of-type:not(.relations-menu-modal-table-overflow) {
      text-overflow: ellipsis;
      white-space: nowrap;
      word-break: break-word;
      max-width: 10vw;

      @include below(md) {
        max-width: 50vw;
      }
    }

    td.relations-menu-modal-table-overflow {
      word-break: break-all;
      white-space: normal;
    }

    td.relations-menu-modal-feedback {
      text-align: inherit;
    }

    .relations-menu-modal-feedback-comment {
      @include typo-medium;
      margin: 8px 0;
      white-space: normal;
    }
  }

  .relations-menu-modal-memberships-membership-header,
  .relations-menu-modal-feedback-header {
    margin-top: 16px;
    margin-left: 16px;
  }

  .relations-menu-modal-memberships-membership,
  .relations-menu-modal-feedback-feedback {
    background: $colorBorderLight;
    border-radius: 8px;
    padding: 16px;
    margin: 16px;

    .relations-menu-modal-memberships-membership-name {
      float: left;
      font-weight: 800;
    }

    .relations-menu-modal-memberships-membership-status {
      float: right;

      &.active {
        background: lighten($colorSuccess, 35%);
        color: $colorSuccess;
      }
    }

    .relations-menu-modal-memberships-membership-price {
      @include typo-medium;
      clear: both;
      color: $colorTextSecondary;
    }

    table {
      margin: 0;
      margin-top: 16px;

      tr:not(:last-of-type) {
        border-color: $colorBorder;
      }

      td {
        padding-left: 0;
      }
    }
  }

  .relations-menu-modal-tag {
    padding: 2px 8px;
    border-radius: 8px;
    font-weight: 800;
    background: $colorBorder;
    color: $colorTextPrimary;
  }

  .star,
  .half-star,
  .empty-star {
    margin-right: 5px;
  }

  .star,
  .half-star {
    fill: $colorYellow;
  }

  .empty-star {
    fill: $colorBorder;
  }

  .star-rating {
    vertical-align: middle;
    padding-left: 5px;
  }
}
