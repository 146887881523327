@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.EventPost {
  position: relative;

  overflow-x: hidden;
  overflow-y: auto;

  background-color: $colorPanelBackground;

  @include above(lg) {
    .PostCardEventSpecific {
      position: relative;
    }
  }

  .post-comments-border {
    display: none;
  }
}
