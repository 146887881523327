@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.CategoryOptions {
  .options-menu-button {
    fill: $colorTextMuted;
  }
}
