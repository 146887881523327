@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/typography';

.InsightsVideoFeedbackCard {
  .insights-video-feedback-card-stars {
    display: flex;
    justify-content: flex-end;
    flex-direction: row-reverse;
    margin-bottom: 30px;

    .insights-video-feedback-card-stars-star {
      &.active {
        svg {
          fill: $colorYellow;
        }
      }

      svg {
        @include size(25px);
        fill: $colorBorder;
      }
    }
  }

  .insights-video-feedback-card-text {
  }
}
