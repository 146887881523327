@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.EventMedia {
  position: relative;
  width: 100%;
  padding-top: 56.25%; // 16:9

  .event-media-inner {
    @include position(absolute, 0 0 0 0);
  }

  .event-media-viewer-count {
    display: flex;
    align-items: center;
    border-radius: 9999px;
    background-color: $colorMessageFrame;
    backdrop-filter: saturate(180%) blur(14px);
    padding: 2px 15px;
    font-size: 14px;
    color: $colorWhite;

    @include position(absolute, 15px null null 15px);

    svg {
      @include size(14px);
      margin-right: 8px;
      fill: $colorWhite;
    }
  }
}
