@import 'styles/global/variables';
@import '~bootstrap/scss/bootstrap-reboot';
@import '~bootstrap/scss/bootstrap-grid';
@import '~bootstrap/scss/nav';
@import 'styles/global/buttons';
@import 'styles/global/forms';
//@import 'styles/global/range';
@import 'styles/global/colors';
@import 'styles/global/typography';
@import 'styles/mixins/responsiveness';

@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Mono:400,700&display=swap');

:root {
  --color-creator-primary: #{$colorPrimary};
  --color-creator-primary-rgb: 8, 102, 250;
  --color-creator-text-primary: #{$colorWhite};
}

body {
  margin: 0;
  padding: 0;
  font-family: $fontFamily, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $colorBackground;
}

.App {
  min-height: 100vh;
  position: relative;

  .globalFacebookLogin {
    display: none;
  }
}

.container,
.extended-container,
.fluid-container,
.narrow-container {
  padding: 0 $containerSpacing;
}

.narrow-container {
  max-width: 1100px;
  margin: 0 auto;
}

.extended-container {
  max-width: 1920px;
  margin: 0 auto;
}

.slim-gutters {
  margin-right: -8px;
  margin-left: -8px;

  > .col,
  > [class*='col-'] {
    padding-right: 8px;
    padding-left: 8px;
  }
}

/*.container {
  @include media-breakpoint-up(md) {
    max-width: 550px;
  }
}*/

.react-toast-notifications__container {
  padding: 15px !important;
  padding-top: $headerHeight + 15px !important;
  pointer-events: none;
}
