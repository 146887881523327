@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/mixins/shadows';

.UserCard {
  width: 100%;
  cursor: pointer;
  transition: transform 250ms ease-in-out;
  color: $colorTextPrimary;

  &:hover {
    transform: scale(1.02);
  }

  .user-card-wrapper {
    background: $colorSurface;
    @include box-shadow-panel;
    border-radius: 4px;
    overflow: hidden;
    width: 100%;
  }

  .user-card-cover {
    position: relative;
    padding-top: (9 / 32) * 100%;
    background-size: cover;
    background-position: center;

    &:before {
      content: '';
      @include position(absolute, 0 0 0 0);
      background-color: rgba(black, 0.2);
    }
  }

  .user-card-avatar {
    @include position(absolute, null null -20px 5%);
    @include size(80px);
  }

  .user-card-profile {
    display: flex;
    padding: 35px 20px 15px 20px;
    align-items: flex-end;

    .user-card-profile-left {
      flex: 1 1 auto;
      overflow: hidden;
      margin-right: auto;
      padding-right: 20px;
    }

    .user-card-profile-right {
      flex-shrink: 0 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      text-align: right;
    }
  }

  .user-card-name {
    display: flex;
  }

  .user-card-name-text {
    @include typo-headline6;
    margin-bottom: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .user-card-verified {
    flex-shrink: 0;
    @include size(18px);
    fill: $colorBlue;
    margin-left: 4px;
  }

  .user-card-category {
    @include typo-small;
    color: #999;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .user-card-price {
    @include typo-small;
    font-weight: 800;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .user-card-followers {
    @include typo-small;
    color: #999;
    white-space: nowrap;
  }

  &.small {
    .user-card-avatar {
      @include size(60px);
    }
  }
}
