@import '../../styles/modules/bourbon';
@import '../../styles/global/colors';
@import '../../styles/mixins/responsiveness';
@import '../../styles/mixins/typography';

.CategoryUpsertModal {
  .category-upsert-modal-submit {
    width: 100%;
    margin-top: 24px;
  }
}
