@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.relations-menu-modal-transactions {
  .relations-menu-modal-transaction {
    background: $colorBorderLight;
    border-radius: 8px;
    padding: 16px;
    margin: 16px;
  }

  .relations-menu-modal-transactions-header {
    margin-top: 16px;
    margin-left: 16px;
  }

  .relations-menu-modal-transaction-name {
    float: left;
    font-weight: 800;
  }

  .relations-menu-modal-transaction-status {
    float: right;
  }

  .relations-menu-modal-transaction-date {
    @include typo-medium;
    clear: both;
    color: $colorTextSecondary;
  }

  table.relations-menu-modal-table {
    margin: 0;
    margin-top: 16px;

    tr:not(:last-of-type) {
      border-color: $colorBorder;
    }

    td {
      padding-left: 0;
    }
  }
}
