@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/mixins/shadows';

.HybridMenuBox {
  position: relative;

  .hybrid-menu-box-content {
    @include position(absolute, 10px 0 null null);
    width: 350px;
    border-radius: 16px;
    z-index: $zHeader;
    background-color: $colorSurface;
    @include box-shadow-far;
  }
}
