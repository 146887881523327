@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.Notification {
  @include typo-small;
  color: $colorTextPrimary;
  display: flex;
  align-items: center;
  padding: 8px $containerSpacing 8px $containerSpacing;

  .notification-unopened {
    @include size(8px);
    background-color: $colorBlue;
    border-radius: 50%;
    flex-shrink: 0;
    margin-left: auto;

    &.hidden {
      visibility: hidden;
    }
  }

  .notification-icon {
    @include size(24px);
    flex-shrink: 0;
    margin-left: -8px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;

    &-blue {
      background-color: $colorBlue;
    }
    &-green {
      background-color: $colorGreen;
    }
    &-red {
      background-color: $colorRed;
    }
    &-grey {
      background-color: #717172;
    }

    svg {
      @include size(12px);
      fill: white;
    }
  }

  .notification-user-avatar {
    @include size(40px);
    flex-shrink: 0;
  }

  .notification-wrapper {
    margin-left: 16px;
  }

  .notification-content {
    line-height: 1.2;
    padding-right: 16px;
  }

  .notification-user-name {
  }

  .notification-reason {
  }

  .notification-date {
    color: $colorTextMuted;
  }

  &:hover {
    cursor: pointer;
    color: inherit;
    text-decoration: none;
    background-color: darken($colorSurface, 5%);
  }
}
