@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/shadows';
@import 'styles/modules/bourbon';

.NamedPinnedModal {
  .named-pinned-modal-header {
    display: flex;
    align-items: center;

    margin-top: -5px;
    margin-bottom: 40px;

    .named-pinned-modal-header-title {
      font-size: 20px;
      font-weight: 800;
    }

    .named-pinned-modal-header-close {
      @include size(40px);

      display: flex;
      align-items: center;
      justify-content: center;

      margin-left: auto;

      border-radius: 50%;
      cursor: pointer;
      z-index: $zOverlay;

      background: $colorBasicGrey;
      box-shadow: 0 60px 80px 0 rgba(0, 0, 0, 0.02),
        0 18px 24px 0 rgba(0, 0, 0, 0.01), 0 8px 10px 0 rgba(0, 0, 0, 0.01),
        0 3px 4px 0 rgba(0, 0, 0, 0.01);

      svg {
        @include size(24px);
        fill: $colorLightGrey;
        cursor: pointer;

        &:hover {
          fill: $colorDark;
        }
      }
    }
  }
}
