@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/mixins/spacing';

.CreatorPage {
  .creator-page-section-title {
    @include typo-display5;
    text-align: center;
    margin-bottom: 24px;

    @include above(lg) {
      @include typo-display4;
      margin-bottom: 40px;
    }
  }

  .creator-page-section-border-top,
  .creator-page-section-border-bottom {
    width: 100%;
    height: 80px;

    &.creator-page-section-border-alternate {
      background-color: $colorAlternateBackground;
    }

    &.creator-page-section-border-dark {
      background-color: $colorDarkBackground;
    }
  }

  .creator-page-section-border-top {
    @include above(lg) {
      border-top-left-radius: 100%;
      border-top-right-radius: 100%;
    }
  }

  .creator-page-section-border-bottom {
    @include above(lg) {
      border-bottom-left-radius: 100%;
      border-bottom-right-radius: 100%;
    }
  }

  .creator-page-story {
    .creator-page-story-title {
      @include typo-display6;
      margin-bottom: 24px;

      @include above(lg) {
        @include typo-display5;
      }
    }

    .creator-page-story-description {
      margin-bottom: 48px;
    }

    .CreatorPagePerks {
      margin-bottom: 40px;
    }

    .creator-page-story-actions {
      a.button {
        display: inline-flex;
      }
    }

    &.creator-page-story-large {
      .creator-page-story-title {
        @include typo-display5;

        @include above(lg) {
          @include typo-display4;
        }
      }
    }
  }

  .creator-page-full-size-image {
    width: 100%;
    image-rendering: optimize-contrast;
  }
}
