@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/helpers/cards';

.InsightsGenericFeedbackCard {
  @include card-near;
  @include typo-medium;
  color: $colorTextPrimary;
  background-color: $colorSurface;
  padding: 20px 24px;
  border-radius: 16px;
  cursor: pointer;
  height: 400px;

  .insights-feedback-content-body {
    height: 250px;
    overflow: hidden;
    position: relative;

    mask-image: linear-gradient(
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 70%,
      transparent 100%
    );
  }

  &:hover {
    @include card-far;
  }
}

.InsightsGenericFeedbackContent {
  .insights-feedback-content-headline-label,
  .insights-feedback-content-rating-label,
  .insights-feedback-content-reason-label {
    @include typo-small;
    color: $colorTextSecondary;
    margin-bottom: 8px;
  }

  .insights-feedback-content-header {
    display: flex;
    margin-top: 10px;
    margin-bottom: 20px;
    align-items: center;
    overflow: hidden;

    .insights-feedback-content-avatar {
      width: 24px;
      height: 24px;
      flex-shrink: 0;
      margin-right: 10px;
      vertical-align: middle;
    }

    .insights-feedback-content-right {
      overflow: hidden;

      .insights-feedback-content-name {
        @include typo-medium;
        font-weight: bold;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-right: 24px;
      }
    }

    svg {
      position: absolute;
      transform: rotate(-90deg);
      right: 24px;
      margin-top: -4px;
      fill: $colorTextMuted;
    }

    &:hover {
      cursor: pointer;

      svg {
        fill: $colorTextPrimary;
      }
    }
  }

  .insights-feedback-content-date {
    @include typo-small;
    color: $colorTextSecondary;
  }

  .insights-feedback-content-headline {
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 16px;
    margin-right: 16px;
    max-height: 50px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .insights-feedback-content-header,
  .insights-feedback-content-headline {
    &:hover {
      cursor: pointer;

      svg {
        fill: $colorTextPrimary;
      }
    }

    svg {
      position: absolute;
      transform: rotate(-90deg);
      right: 24px;
      margin-top: -4px;
      fill: $colorTextMuted;
    }
  }
}

.InsightsGenericFeedbacModal {
  .modal-content {
    max-width: 450px;
  }
}
