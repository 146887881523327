@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.CancelSubscriptionModal {
  .named-pinned-modal-header {
    margin-bottom: 16px !important;
  }

  .cancel-subscription-modal-reasons {
    margin: 16px 0;
  }

  .cancel-subscription-modal-other {
    margin-top: 8px;
  }

  .cancel-subscription-modal-submit {
    margin-top: 16px;
    width: 100%;
  }
}
