@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.UserSocial {
  .user-social-header {
    @include typo-list-header;
    margin-bottom: 15px;
  }

  .user-social-items {
    display: flex;

    .user-social-item {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      @include size(34px);
      border-radius: 50%;
      transition: border-color 250ms ease-out;
      background-color: $colorBorderLight;

      &:not(:first-child) {
        margin-left: 8px;
      }

      &:hover {
        background-color: darken($colorBorderLight, 5%);
      }

      svg {
        fill: $colorBackgroundBlack;
        @include size(16px);
      }
    }
  }
}
