@import 'styles/global/colors';
@import 'styles/helpers/box';
@import 'styles/mixins/typography';
@import 'styles/helpers/cover';
@import 'styles/modules/bourbon';
@import 'styles/helpers/play-button';
@import 'styles/helpers/cards';

.EventButton {
  svg {
    margin-right: 5px;
    fill: white;
  }

  .event-page-button {
    margin: 20px 0;
    width: 100%;
    color: white;
    border-color: var(--color-creator-primary);
    background-color: var(--color-creator-primary);

    .event-page-button-price {
      margin-left: 5px;
      color: $colorTextMuted;
      font-weight: 400;
    }

    &.button-attending {
      cursor: auto;
      color: $colorGreen;
    }

    svg {
      @include size(20px);
    }
  }

  > button:not(.event-page-button) {
    svg {
      fill: var(--color-creator-primary);
    }
  }

  .button-attending {
    svg {
      fill: $colorGreen;
      margin-right: 0;
    }
  }
}
