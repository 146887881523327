@import 'styles/global/colors';

.Avatar {
  border-radius: 50%;

  background-color: $colorBackground;
  background-position: center;
  background-size: cover;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.05);
}
