@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.AccessSelector {
  background-color: $colorBasicGrey;
  border-radius: 999px;
  width: 100%;
  padding: 11px 24px;
  cursor: pointer;

  &:hover {
    background-color: #efefef;
  }

  .access-selector-inner {
    display: flex;
    align-items: center;
  }

  .access-selector-icon {
    flex-shrink: 0;
    @include size(18px);
    fill: $colorTextPrimary;
    margin-right: 8px;
  }

  .access-selector-text {
    flex: 1;
    color: $colorTextPrimary;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .access-selector-chevron {
    flex-shrink: 0;
    @include size(24px);
    fill: $colorDark;
    margin-left: 8px;
  }
}
