@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/mixins/shadows';
@import 'styles/helpers/badges';

.UpcomingLiveEvent {
  $dateboxHeight: 56px;

  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding: 16px 4px;
  border-top: 1px solid $colorBorderLight;

  &:last-of-type {
    border-bottom: 1px solid $colorBorderLight;
  }

  &:hover {
    .upcoming-live-link {
      fill: $colorMuted;
    }

    .upcoming-live-title {
      color: var(--color-creator-primary);
    }
  }

  .upcoming-live-date-container {
    @include box-shadow-near;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: $dateboxHeight;
    min-width: $dateboxHeight;
    border: 1px solid $colorBorder;
    border-radius: 8px;
    margin-right: 5px;

    @include below(md) {
      margin-right: 0;
    }

    .upcoming-live-date-month {
      color: $colorTextSecondary;
      font-size: 13px;
    }

    .upcoming-live-date-day {
      @include typo-headline5;
      color: $colorTextPrimary;
      font-weight: 600;
    }
  }

  .upcoming-live-cover-image-container {
    position: relative;
    flex: 1 1 auto;

    @include below(md) {
      display: none;
    }
  }

  .upcoming-live-cover-image {
    @include position(absolute, 0 0 null null);
    height: $dateboxHeight;
    width: 100px;
    background-size: cover;
    background-position: center;
    border-radius: 8px;
  }

  .upcoming-live-link {
    position: relative;
    top: 5px;
    transform: scale(0.55);
    flex-shrink: 0;

    @include below(md) {
      margin-left: auto;
    }
  }

  .upcoming-live-content {
    flex-shrink: 0;
    padding: 5px 0 5px 10px;
    white-space: nowrap;
    word-break: break-word;

    .upcoming-live-title {
      font-weight: 700;
      white-space: nowrap;
      word-break: break-word;
      overflow-x: hidden;
      text-overflow: ellipsis;
      max-width: 400px;

      @include below(md) {
        max-width: 54vw;
      }
    }

    .upcoming-live-status,
    .upcoming-live-datetime,
    .upcoming-live-duration {
      @include typo-small;
    }

    .free-badge {
      @include free-badge;
      font-size: 12px;
      margin-left: 5px;
    }

    .upcoming-live-status {
      color: $colorTextPrimary;

      &.live {
        font-weight: 700;
        color: $colorLive;

        &:before {
          content: '';
          display: inline-block;
          vertical-align: middle;
          width: 6px;
          height: 6px;
          background-color: $colorLive;
          border-radius: 100%;
          margin-right: 5px;
          animation: pulsate infinite 1s;
        }

        @keyframes pulsate {
          0% {
            transform: scale(1, 1);
            opacity: 0.25;
          }
          100% {
            transform: scale(1.2, 1.2);
            opacity: 1;
          }
        }
      }
    }
  }
}
