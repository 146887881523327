@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.WatchWaitingRoom {
  position: relative;
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  &:before {
    content: '';
    @include position(absolute, 0 0 0 0);

    background-color: black;
    opacity: 0.5;
  }

  .watch-waiting-room-content {
    color: $colorWhite;
    text-align: center;
    @include position(absolute, 50% null null 50%);
    transform: translate(-50%, -50%);
    z-index: 10;
  }

  .WatchEventHeader {
    z-index: 10;
  }

  .watch-waiting-room-title {
    display: block;
    @include typo-headline4;
    z-index: 10;

    &.loading:after {
      content: ' .';
      animation: dots 1.5s steps(5, end) infinite;
    }
  }

  .watch-waiting-room-subtitle {
    display: block;
    //font-size: 15px;
    z-index: 10;
  }
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: white;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
  }
}
