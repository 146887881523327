@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/helpers/cards';

.subscription-list {
  .col-lg-4 {
    margin-bottom: 24px;
  }
}

.SubscriptionCard {
  @include card-near;

  border: 1px solid $colorBorderLight;
  border-radius: 16px;
  padding: 24px;
  margin-bottom: 24px;
  height: 100%;

  .subscription-card-header {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .creator-avatar {
      @include size(56px);
    }

    .subscription-card-header-creator {
      margin-left: 24px;
      max-width: 200px;
      @include typo-headline5;
    }
  }

  .subscription-card-title {
    @include typo-headline4;
    margin-bottom: 24px;
  }

  .subscription-card-status {
    margin-bottom: 24px;

    .status-tag {
      font-size: 14px;
      display: inline-flex;
      align-items: center;
      background: $colorBorderLight;
      border-radius: 8px;
      padding: 5px 10px;
      margin-right: 16px;
      color: $colorLightGrey;

      &.active {
        background: lighten($colorSuccess, 35%);
        color: $colorSuccess;
      }

      svg {
        @include size(13px);
        margin-right: 5px;
        fill: $colorLightGrey;
      }
    }
  }

  .subscription-card-label {
    font-weight: bold;
  }

  .subscription-card-edit-payment {
    text-decoration: underline;
    cursor: pointer;
  }

  .subscription-card-cta {
    margin-top: 24px;
  }

  .subscription-card-link {
    text-decoration: underline;
    cursor: pointer;

    &.do-not-renew {
      margin-top: 24px;
      text-align: center;
    }
  }

  .subscription-card-button {
    width: 100%;
  }

  .row {
    margin-bottom: 24px;
  }
}
