@import 'styles/global/colors';

.InsightsCancelFeedbackCard {
  .insights-cancel-feedback-card-reasons {
    margin-bottom: 16px;
  }

  .insights-cancel-feedback-card-reason {
    display: inline-block;
    clear: both;
    font-weight: bold;
    border-radius: 4px;
    margin-bottom: 4px;
    padding: 4px 8px;
    background-color: $colorBorderLight;
  }

  .insights-cancel-feedback-card-text {
    margin-top: 8px;
  }
}
