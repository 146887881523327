@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.PostAttachmentView {
  width: 100%;

  .post-attachment-view-wrapper {
    @include above(md) {
      display: flex;
    }
  }

  .post-attachment-view-attachment {
    background-color: $colorSurface;

    @include below(sm) {
      width: 100%;
    }

    @include above(md) {
      flex: 1 1;
      min-width: 0;
    }
  }
  .post-attachment-view-post {
    @include above(md) {
      flex: 0 0 450px;
      width: 450px;

      @include below(lg) {
        flex-basis: 375px;
      }
    }
  }
}
