@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.SettingsNotifications {
  .settings-notifications-section-title {
    margin-bottom: 30px;
  }

  .settings-notifications-creator-consent-name {
    margin-top: 10px;
    font-weight: bold;
  }

  .settings-notifications-creator-consents {
    max-width: 400px;
  }

  .settings-notifications-creator-consent,
  .settings-notifications-subscriptions-item {
    display: flex;
    padding: 15px 0;

    &:not(:last-child) {
      border-bottom: 1px solid rgba(white, 0.1);
    }

    &:last-child {
      margin-bottom: 45px;
    }

    .settings-notifications-creator-consent-type,
    .settings-notifications-subscriptions-item-message {
      margin-right: 16px;
    }

    .settings-notifications-creator-consent-toggle,
    .settings-notifications-subscriptions-item-toggle {
      margin-left: auto;
      flex-shrink: 0;

      // Loading spinner
      position: relative;
      min-width: 40px;
    }
  }
}
