@import 'styles/global/colors';

.CouponOptions {
  .coupons-link {
    display: flex !important;
    align-items: center;

    .links-svg {
      width: 24px;
      height: 24px;
      margin-right: 16px;

      &.small-icon {
        height: 20px;
      }
    }
  }

  .options-menu-button {
    fill: $colorTextMuted;
    margin-left: 25px;
  }

  .OptionsMenuBox {
    margin-left: 40px;
  }
}
