@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.BalanceTransactionSum {
  display: inline-flex;
  flex-direction: column;

  .balance-transaction-sum-amount {
    display: flex;
    justify-content: space-between;

    > *:first-child {
      margin-right: 40px;
    }

    > *:last-child {
      font-variant-numeric: tabular-nums;
    }
  }
}
