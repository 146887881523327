@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/spinner';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/helpers/cards';

.ChatCompose {
  display: flex;
  align-items: center;

  backdrop-filter: saturate(180%) blur(20px);
  padding: 15px 15px;

  .Avatar {
    @include size(30px);
    margin-right: 15px;
  }

  .chat-compose-message {
    flex: 1;
    display: flex;
    margin-right: 15px;

    textarea {
      width: 100%;
      outline: none;
      color: $colorWhite;
      background: transparent;
      border-radius: 12px;
      min-height: 30px;
      padding: 7px 10px;
      resize: none;

      font-size: 14px;
      font-weight: 400;

      @keyframes focused {
        0%,
        100% {
          transform: scale(1);
        }
        50% {
          transform: scale(1.1);
          background-color: lighten($colorThree, 10%);
        }
      }

      &.focused {
        animation: 400ms focused;
      }
    }
  }

  .chat-compose-button {
    display: flex;
    align-items: flex-end;
    padding: (40px - 24px) / 2 0;
    cursor: pointer;
    position: relative;
    transition: opacity 0.2s;

    svg {
      @include size(24px);
      fill: $colorWhite;
    }

    &:hover {
      opacity: 0.5;
    }

    &.loading {
      svg {
        opacity: 0;
      }

      &:after {
        opacity: 1;
        @include spinner($width: 3px, $color: $colorWhite);
      }
    }

    &.disabled {
      opacity: 0.5;
    }
  }

  .chat-compose-message-field {
    width: 100%;
  }

  .chat-compose-message-field__control {
    background-color: $colorThree;
    padding: 7px 10px;
    border-radius: 32px;
  }

  .chat-compose-message-field__suggestions {
    @include card-near;
    border-radius: 16px;
    color: $colorTextPrimary;

    .chat-compose-message-field__suggestions__list {
      padding: 8px !important;
    }

    .chat-compose-message-field__suggestions__item--focused {
      background-color: $colorInputFields;
      border-radius: 16px;
    }

    .chat-compose-message-field__suggestions-user {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 6px 12px;
    }

    .chat-compose-message-tag-suggestion-avatar {
      @include size(24px);
      flex-shrink: 0;
      margin-right: 8px;
    }
  }
}
