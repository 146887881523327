@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.EventStartWaitingRoom {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;

  &:before {
    content: '';
    @include position(absolute, 0 0 0 0);
    background-color: rgba(black, 0.4);
  }

  .event-start-waiting-room-inner {
    width: 100%;
    height: 100%;

    position: relative;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;

    .EventCountdown {
      height: auto;

      @include above(xl) {
        transform: scale(1.5);
        margin-bottom: 20px;
      }
    }

    .event-start-waiting-room-trailer-button {
      margin-top: 15px;

      svg {
        @include size(24px);
        fill: $colorWhite;
        margin-right: 6px;
        margin-left: -4px;
      }
    }
  }
}

.EventStartWaitingRoomTrailer {
  position: relative;
  .event-start-waiting-room-trailer-exit {
    fill: $colorWhite;
    cursor: pointer;
    @include size(32px);
    @include position(absolute, 30px null null 30px);
    transition: transform 250ms;

    &:hover {
      transform: scale(1.3);
    }
  }
}
