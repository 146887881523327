@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.NewEventChat {
  position: relative;
  background: $colorPanelBackground;

  &.no-access {
    .Chat {
      opacity: 0.3;
      filter: blur(4px);
    }
  }

  .new-event-chat-no-access,
  .new-event-chat-empty {
    @include position(absolute, 50% null null 50%);
    transform: translate(-50%, calc(-50% - 40px));
    color: #999;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    svg {
      fill: #999;
      @include size(24px);
    }
  }

  .new-event-chat-empty {
    svg {
      margin-bottom: 4px;
    }
  }

  .new-event-chat-no-access {
    text-align: center;

    .new-event-chat-no-access-action {
      color: $colorWhite;
      font-weight: 800;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }

    svg {
      margin-bottom: 6px;
    }
  }

  .new-event-chat-load-more {
    margin: 15px auto 25px auto;
  }
}
