@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.PostCardAttachmentViewSpecific {
  background-color: $colorSurface;
  color: $colorTextPrimary;
  width: 100%;
}
