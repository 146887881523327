@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/helpers/cards';

.CreatorPageUsps {
  .creator-page-usps-content {
    background-color: $colorAlternateBackground;

    @include above(lg) {
      padding-top: 40px;
      padding-bottom: 120px;
    }
  }

  .creator-page-usps-description {
    text-align: center;
    margin-bottom: 80px;

    font-family: $fontFamilyBoon;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.5;
  }

  .creator-page-usps-item {
    .creator-page-usps-item-content {
      padding-top: 24px;

      @include above(xxl) {
        padding-top: 140px;
      }

      .creator-page-usps-item-title {
        margin-bottom: 24px;
        @include typo-display5;
      }

      .creator-page-usps-item-description {
        margin-bottom: 32px;
      }
    }

    .creator-page-usps-item-media {
      position: relative;

      .CreatorPageUspQuote {
        @include above(md) {
          @include position(absolute, null 36px 0 36px);
          bottom: -10%;
        }

        @include below(sm) {
          margin-right: 8px;
          margin-top: -24px;
        }
      }

      .creator-page-usps-item-media-image {
        width: 100%;
      }
    }

    &.creator-page-usps-item-reverse {
      @include below(md) {
        flex-direction: column-reverse;

        .creator-page-usps-item-media-image {
          margin-right: -$containerSpacing;
          width: calc(100% + #{$containerSpacing});
        }
      }

      .creator-page-usps-item-media {
        position: relative;

        .CreatorPageUspQuote {
          @include below(sm) {
            margin-left: 8px;
            margin-right: 0;
          }
        }
      }
    }

    &:not(.creator-page-usps-item-reverse) {
      @include below(sm) {
        .creator-page-usps-item-media-image {
          margin-left: -$containerSpacing;
          width: calc(100% + #{$containerSpacing});
        }
      }
    }
  }

  .creator-page-usps-item + .creator-page-usps-item {
    margin-top: 40px;

    @include above(md) {
      margin-top: 80px;
    }

    @include above(lg) {
      margin-top: 300px;
    }
  }
}
