@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/helpers/cards';

.CreatorPagePricing {
  .creator-page-pricing-container {
    background-color: $colorAlternateBackground;
  }

  .creator-page-story {
    @include above(lg) {
      margin-top: 120px;
    }
  }

  .creator-page-pricing-card {
    @include card-extra-far;
    min-height: 600px;
    overflow: hidden;

    .creator-page-pricing-card-header {
      color: $colorDarkTextPrimary;
      background-color: $colorPrimary;
      text-align: center;
      padding: 12px 0;
      font-weight: 800;
      text-transform: uppercase;
    }

    .creator-page-pricing-card-content {
      padding: 24px 40px;
    }

    .creator-page-pricing-card-amount {
      vertical-align: bottom;
      font-weight: 800;
      border-bottom: 1px solid $colorBorderLight;
      margin-bottom: 12px;

      .creator-page-pricing-card-amount-percentage {
        font-size: 32px;
      }
    }

    .creator-page-pricing-card-stripe-fees {
      display: flex;
      align-items: center;
      font-size: 14px;
      margin-bottom: 32px;

      svg {
        margin-right: 16px;
        @include size(24px);
      }
    }

    .creator-page-pricing-card-subtitle {
      font-weight: 800;
      margin-bottom: 16px;
    }

    .creator-page-pricing-card-perks {
      margin-bottom: 24px;

      .creator-page-pricing-card-perks-item {
        margin-bottom: 8px;
        display: flex;
        align-items: center;

        svg {
          fill: $colorPrimary;
          margin-right: 16px;
          @include size(20px);
        }
      }
    }

    .creator-page-pricing-card-cta {
      width: 100%;
    }
  }
}
