@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/global/variables';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/helpers/cards';

// In Safari, somehow this clip-path is broken, but not the one in checkout
// This hack forces Safari not to use it here...
_::-webkit-full-page-media,
_:future,
:root .product-card-image {
  clip-path: none !important;
  border-radius: 16px;
}

.ProductCard {
  color: $colorTextPrimary;
  position: relative;

  @include card-far;
  overflow: hidden;

  .product-card-image {
    display: block;
    position: relative;
    clip-path: url(#boonCircularSquareClipPath);
    background-color: $colorBorderLight;
    object-fit: cover;

    margin-right: 16px;
    flex-shrink: 0;
  }

  .product-card-wrapper {
    height: 100%;
    padding: 24px;
    min-height: 470px;
    max-height: 470px;
    position: relative;
    overflow: hidden;

    display: flex;
    flex-direction: column;
  }

  .product-card-header {
    flex: 0 0 auto;
    display: flex;
    position: relative;
    margin-bottom: 24px;

    .product-card-title {
      @include typo-headline6;
    }

    .ProductCardOptions {
      @include position(absolute, 15px 15px null null);
    }

    .product-card-collapsible-radio {
      @include size(24px);
      margin-top: 16px;
      fill: $colorPrimary;
      fill: var(--color-creator-primary);
      margin-left: auto;
      cursor: pointer;
    }
  }

  .product-card-body {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .product-card-price {
    font-size: 26px;
    font-weight: 500;
    letter-spacing: -0.05em;
    line-height: 1em;
    margin-top: 5px;

    .product-card-price-interval {
      font-size: 16px;
      letter-spacing: initial;
    }
  }

  .product-card-type {
    @include typo-small;
    text-transform: uppercase;
    background-color: #8e8e8e47;
    border-radius: 3px;
    margin-bottom: 15px;
    padding: 2px 10px;
    color: $colorDark4;
  }

  .product-card-discount-link {
    flex: 0 0 auto;
    color: $colorPrimary;
    color: var(--color-creator-primary);
    background-color: rgba($colorPrimary, 0.06);
    margin: 24px -24px -24px -24px;
    text-align: center;
    padding: 8px 0;
    //background-color: rgba(var(--color-creator-primary), 0.6);
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
    @include below(xs) {
      font-size: 13px;
    }
  }
  .product-card-cta {
    flex: 0 0 auto;
    margin-bottom: 20px;
    width: 100%;

    &.product-card-cta-active {
      line-height: 1.5;
      font-weight: 800;
      padding: 9px 25px;
      padding-bottom: 11px;
      text-align: center;

      background-color: rgba($colorGreen, 0.1);
      color: $colorGreen;
      border-radius: 999px;
    }
  }

  .product-card-description {
    flex: 1 1 auto;
    min-height: 0;
    padding: 16px 0 16px 0;
    overflow: hidden;

    p {
      font-size: 14px;
    }
    ul,
    ol {
      padding-inline-start: 20px !important;
      font-size: 14px;
      margin-bottom: 2em;

      li:not(:last-child) {
        margin-bottom: 8px;
      }
    }

    ul {
      list-style-type: disc !important;
    }

    a {
      text-decoration: underline;
      text-underline-position: under;

      &:hover {
        color: inherit;
        opacity: 0.8;
      }
    }
  }

  &.description-collapsed {
    .product-card-description {
      mask-image: linear-gradient(
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 1) 70%,
        transparent 90%,
        transparent 100%
      );
    }
  }

  &:not(.description-collapsed) {
    .product-card-wrapper {
      max-height: none !important;
    }
  }

  &.collapsed {
    .product-card-wrapper {
      min-height: 0;
      max-height: none;
    }

    .product-card-header {
      margin-bottom: 0;
    }

    .product-card-cta,
    .product-card-description,
    .product-card-expander,
    .product-card-discount-link {
      display: none;
    }
  }

  &.collapsible:not(.collapsed) {
    border-color: $colorPrimary;
    border-color: var(--color-creator-primary);
  }

  .product-card-expander {
    width: 100%;
    text-align: center;
    cursor: pointer;
    font-weight: 600;
    &:hover {
      color: var(--color-creator-primary);
    }

    svg {
      fill: $colorDark0;
      @include size(24px);

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
