@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';

.modal {
  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;

  overflow-y: auto;

  .modal-overlay {
    // TODO: Consider bourbon
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;

    background-color: rgba(black, 0.7);
  }

  .modal-content {
    position: relative;
    z-index: 10;

    margin: auto 0;
    //overflow: hidden; Interferes with react-datepicker
  }
}
