@import 'styles/global/colors';
@import 'styles/helpers/box';
@import 'styles/helpers/cover';
@import 'styles/global/variables';
@import 'styles/modules/bourbon';
@import 'styles/mixins/typography';
@import 'styles/mixins/responsiveness';

.Footer {
  height: $footerBodyHeightDesktop;
  background-color: $colorDarkBackground;
  position: relative;

  font-size: 16px;
  color: $colorDarkTextPrimary;

  a {
    color: $colorDarkTextPrimary;

    &:hover {
      color: $colorDarkTextPrimary;
    }
  }

  .extended-container {
    height: 100%;
  }

  .footer-wrapper {
    .footer-copyright {
      opacity: 0.5;
    }
  }

  .footer-links {
    display: flex;

    @include below(sm) {
      align-items: center;
    }

    > a {
      &:hover:before {
        text-decoration: none;
      }
      &:before {
        content: '·';
        padding: 0 8px;
        display: inline-block;
      }

      @include below(sm) {
        &:first-child {
          &:before {
            display: none;
          }
        }
      }
    }
  }

  .footer-social {
    display: flex;
    margin-top: 40px;

    @include above(xl) {
      margin-top: 115px;
    }

    margin-bottom: 32px;

    .footer-social-item {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      @include size(34px);
      border-radius: 50%;
      transition: border-color 250ms ease-out;
      background-color: $colorSurface;

      &:not(:first-child) {
        margin-left: 8px;
      }

      &:hover {
        opacity: 0.9;
      }

      svg {
        fill: $colorTextPrimary;
        @include size(16px);
      }
    }
  }

  &.marginBottom {
    @include media-breakpoint-down(sm) {
      margin-bottom: 120px;
    }
  }

  &.big {
    height: auto;

    .footer-wrapper {
      height: $footerBodyHeightMobile;
      @include media-breakpoint-up(md) {
        height: $footerBodyHeightDesktop;
      }
    }
  }

  .footer-big {
    padding-top: 60px;

    .footer-big-section {
      margin-bottom: 60px;

      .footer-big-section-header {
        font-family: $fontFamilyBoon;
        font-size: 20px;
        font-weight: 800;
        text-transform: uppercase;
        padding-bottom: 32px;
      }

      a {
        display: block;
        padding: 5px 0;
      }
    }
  }

  @include below(sm) {
    height: $footerBodyHeightMobile;
    padding-bottom: 20px;

    .footer-wrapper {
      justify-content: space-evenly;
    }
  }
}
