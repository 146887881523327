@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.CreatorPagePayouts {
  padding: 80px 0;

  @include above(lg) {
    background-image: url(./assets/CreatorPagePayoutsBackgroundSvg.svg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 220px 0 200px 0;
  }

  @include below(md) {
    background-color: $colorAlternateBackground;
  }

  .row {
    @include below(lg) {
      flex-direction: column-reverse;
    }
  }
}
