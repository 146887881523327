@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/helpers/cards';

.InviteFriendsPage {
  .title {
    @include typo-headline4;
    @include above(md) {
      @include typo-headline3;
    }

    &.how-it-works {
      margin: 48px 0 24px 0;
    }
  }
  .sub-title {
    margin-top: 10px;
  }

  .spinner-container {
    position: relative;
    height: 200px;
  }

  .page-col {
    padding-bottom: 40px;
    min-height: calc(100vh - #{$headerHeight} - #{$footerBodyHeightDesktop});
    @include below(sm) {
      min-height: calc(
        100vh - #{$headerHeightMobile} - #{$footerBodyHeightMobile}
      );
    }
  }

  .invite-friends-container {
    @include card-near;
    background-color: $colorSurface;
    width: 100%;
    border-radius: 16px;
    padding: 20px;
    margin-top: 48px;

    .invite-friends-empty {
      display: flex;
      justify-content: center;
      padding: 24px 0;
    }

    .invite-friends-link-info {
      display: flex;
      flex-direction: column;
      text-align: center;
      border-bottom: 1px solid $colorBorderLight;
      padding-bottom: 48px;
      align-items: center;

      .invite-friends-creator {
        display: flex;
        justify-content: center;
        margin-bottom: 24px;
        align-items: center;

        .invite-friends-creator-avatar {
          @include size(45px);
          display: inline;
          margin-right: 6px;
        }
        .invite-friends-creator-username {
          font-weight: bold;
        }
      }

      .invite-friends-helper-text {
        color: $colorTextSecondary;
        margin-bottom: 10px;
      }
      .invite-friends-link {
        font-weight: bold;
        font-size: 18px;
        word-wrap: break-word;
        width: 100%;
      }
      button {
        margin: auto;
        margin-top: 30px;
        width: 220px;

        svg {
          @include size(15px);
          margin-right: 10px;
        }

        &.copied {
          background-color: $colorGreen;
          border-color: $colorGreen;
        }
      }
    }

    .invite-friends-spacer {
      height: 1px;
      width: 100%;
      border: 1px solid $colorBorderLight;
      margin: 48px 0;
    }

    .invite-friends-users {
      margin-bottom: 12px;
      text-align: center;
      margin-top: 48px;

      .invite-friends-users-title {
        @include typo-headline5;
        margin: 20px 0;
      }

      .invite-friends-user {
        display: inline-block;
        margin-right: 12px;
        margin-left: 12px;
        margin-bottom: 24px;
        width: 60px;

        .invite-friends-users-avatar {
          @include size(55px);
          margin-right: 6px;
          margin-bottom: 10px;
        }

        .invite-friends-user-name {
          font-size: 13px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }

    .invite-friends-earned {
      text-align: center;
      .invite-friends-earned-title {
        @include typo-headline5;
        margin: 20px 0;
      }
      .invite-friends-earned-big-number {
        font-family: $fontFamilyBoon;
        font-size: 48px;
        font-weight: 800;
        line-height: 1;
        padding: 8px 0;
      }
    }
  }

  .invite-friends-explainer {
    .invite-friends-explainer-row {
      display: flex;
      margin-bottom: 32px;
      .invite-friends-explainer-left {
        width: 70px;

        span {
          background-color: $colorPrimary;
          border-radius: 50px;
          padding: 20px;
          @include size(16px);
          display: flex;
          justify-content: center;
          align-items: center;
          color: $colorWhite;
          font-weight: bold;
        }
      }
      .invite-friends-explainer-right {
        flex: 1;
        .invite-friends-explainer-works-title {
          font-weight: bold;
          margin-top: 8px;
        }
      }
    }
  }
}
