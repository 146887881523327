@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.SettingsHeader {
  display: flex;
  align-items: center;
  height: 60px;
  box-sizing: border-box;
  box-shadow: inset 0 -1px 0 0 rgba(255, 255, 255, 0.05);
  margin-bottom: 30px;
  padding-bottom: 1px;

  .settings-header-menu {
    display: flex;
    align-self: stretch;
    overflow-y: hidden;
    overflow-x: auto;

    .settings-header-menu-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-self: stretch;
      color: $colorTextSecondary;
      line-height: 100%;
      position: relative;

      &.active {
        color: $colorTextPrimary;

        &:before {
          content: '';
          @include position(absolute, null 0 -1px 0);
          background-color: var(--color-creator-primary);
          height: 4px;
          border-top-left-radius: 100px;
          border-top-right-radius: 100px;
        }
      }

      &:hover {
        text-decoration: none;
        color: $colorTextPrimary;
      }

      &:not(:first-child) {
        margin-left: 20px;
      }
    }
  }
}
