@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.ReactionList {
  .reaction-list-inner {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -8px;
  }

  .ReactionItem {
    margin-bottom: 8px;

    &:not(:last-child) {
      margin-right: 4px;
    }
  }
}

.ReactionItem {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  appearance: none;
  outline: none;
  border: none;

  box-sizing: border-box;
  border: 1px solid transparent;
  padding: 2px 10px 2px 8px;
  border-radius: 999px;

  background-color: rgba(#eeeeee, 0.1);

  &:focus {
    outline: none;
  }

  &:hover {
    opacity: 0.8;
  }

  &.active {
    border-color: $colorBlue;
    background-color: rgba($colorBlue, 0.1);
  }

  .reaction-item-emoji {
    margin-right: 7px;
  }

  .reaction-item-count {
    font-size: 12px;
    font-weight: 800;
    color: $colorWhite;
  }
}
