@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.PostComposeModalAttachmentPhoto {
  border-radius: 16px;
  margin: 20px;
  overflow: hidden;
  position: relative;

  .post-compose-modal-attachment-photo-image {
    width: 100%;
    height: auto;
    background-color: black;

    max-height: 50vh;
    object-fit: contain;
  }

  .post-compose-modal-attachment-photo-image-error {
    height: 150px;
    background-color: rgba(white, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      fill: red;
      margin-right: 10px;
    }
  }

  .post-compose-modal-attachment-photo-loader {
    min-height: 300px;
    background-color: rgba(white, 0.05);
  }

  .post-compose-modal-attachment-photo-close {
    @include position(absolute, 10px 10px null null);
    @include size(40px);

    display: flex;
    align-items: center;
    justify-content: center;

    margin-left: auto;

    border-radius: 50%;
    cursor: pointer;
    z-index: $zOverlay;

    background: rgba(#212124, 0.2);
    //backdrop-filter: saturate(180%) blur(20px);

    .post-compose-modal-attachment-photo-close-icon {
      @include size(24px);
      fill: white;
    }

    &:hover {
      .post-compose-modal-attachment-photo-close-icon {
        opacity: 0.7;
      }
    }
  }
}
