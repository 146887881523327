@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.SubscribePageSummary {
  .subscribe-page-summary-coupon-summary {
    margin-bottom: 24px;
  }

  .subscribe-page-summary-coupon-form-wrapper {
    .subscribe-page-summary-coupon-form-title {
      display: flex;
      flex-direction: row;
      justify-content: stretch;
      cursor: pointer;
      padding-top: 10px;

      &.hidden {
        margin-bottom: -2px;
      }

      .subscribe-page-summary-coupon-form-title-text {
        margin: 0 0 32px;

        &.opened {
          margin: 0 0 8px;
        }
      }

      .subscribe-page-summary-coupon-form-svg {
        &.opened {
          transform: rotate(180deg);
        }
      }
    }

    .subscribe-page-summary-coupon-form-content {
      .subscribe-page-summary-coupon-form-field {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 15px;

        .BoonTextField {
          max-width: calc(85vw - 100px);
          width: 72%;

          .boon-text-field-wrapper {
            margin-top: 0;
          }
        }

        .subscribe-page-summary-coupon-form-field-button {
          width: 90px;
          height: 47px;
        }
      }
    }
  }
}

.SubscribePageSummaryLine {
  display: flex;
  margin-bottom: 2px;

  .subscribe-page-summary-coupon-cancel-svg {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    margin-top: 3px;
    cursor: pointer;
  }

  .subscribe-page-summary-line-title {
  }

  .subscribe-page-summary-line-value {
    margin-left: auto;
  }

  &.big {
    font-weight: 800;
  }

  &.small {
    @include typo-small;
  }
}
