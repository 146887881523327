@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/spinner';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.ChatMessage {
  display: flex;

  .chatmessage-avatar {
    flex-shrink: 0;
    @include size(30px);
    margin-right: 15px;

    svg {
      @include size(100%);
    }
  }

  .chatmessage-bot-avatar {
    svg {
      background: white;
      fill: $colorPrimary;
      border-radius: 100%;
    }
  }

  .chatmessage-content {
    position: relative;
    border-radius: 16px;
    min-width: 100px;
    max-width: 500px;

    .chatmessage-content-wrapper {
      padding: 8px 12px;
      position: relative;
    }

    .chatmessage-header {
      display: flex;
      align-items: center;
    }

    .chatmessage-author {
      font-size: 13px;
      margin-right: 8px;
      font-weight: 800;
    }

    .chatmessage-datetime {
      font-size: 13px;
      opacity: 0.4;
    }

    .chatmessage-message {
      @include typo-medium;
      white-space: pre-wrap;
      overflow: hidden;
      word-break: break-word;

      a {
        text-decoration: underline;

        &:hover {
          color: $colorWhite;
        }
      }
    }

    .ReactionButton {
      @include position(absolute, -7px -7px null null);

      .reaction-button-button {
        border: 1px solid $colorPanelBackground;
      }
    }

    .ReactionList {
      margin-top: 10px;
    }
  }

  &.threaded {
    .chatmessage-avatar {
      @include size(20px);
      margin-right: 5px;
    }
  }

  &.reactions {
    .chatmessage-content-wrapper {
      padding-right: 40px;
    }

    margin-right: 8px;
  }

  &.other {
    .chatmessage-content {
      color: $colorWhite;
      background-color: $colorChatMessages;
    }

    .chatmessage-message {
      color: #cccccc;
    }
  }

  &.me {
    .chatmessage-content {
      color: $colorWhite;
      background-color: $colorBlue;
    }

    .chatmessage-message {
      color: rgba(white, 0.8);
    }
  }

  &.me,
  &.other {
    .chatmessage-content {
      border-top-left-radius: 4px;
    }
  }

  &.service {
    .chatmessage-content {
      color: $colorBlack;
      background-color: $colorWhite;
      border-top-left-radius: 4px;
    }

    .chatmessage-datetime {
      margin-right: 30px;
    }

    .chatmessage-action {
      cursor: pointer;
      font-size: 14px;
      font-weight: 800;
      background-color: rgba(black, 0.05);
      text-align: center;
      padding: 10px;

      &:hover {
        background-color: rgba(black, 0.07);
      }
    }

    .chatmessage-close {
      @include position(absolute, 4px 10px null null);
      @include size(18px);
      cursor: pointer;
      opacity: 0.2;

      svg {
        @include size(100%);
        fill: $colorBlack;
      }

      &:hover {
        opacity: 0.1;
      }
    }
  }
}
