@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/mixins/shadows';
@import 'styles/mixins/spinner';

.UploadVideoField {
  display: flex;
  align-items: center;
  border: 2px dashed $colorBorder;
  border-radius: 4px;
  padding: 30px;
  flex-wrap: wrap;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &.active {
    border-color: rgba(black, 0.4);
  }

  &.hasVideo {
    border-style: solid;
  }

  &.uploading {
    .upload-video-field-video-player {
      position: relative;

      &:before {
        content: '';
        @include position(absolute, 0 0 0 0);
        background-color: rgba(black, 0.6);
      }

      &:after {
        @include spinner();
        @include position(
          absolute,
          calc(50% - 12px) null null calc(50% - 12px)
        );
      }
    }
  }

  .upload-video-field-video {
    width: 210px;
    flex-shrink: 0;
    border-radius: 4px;
    overflow: hidden;

    .upload-video-field-video-sizer {
      width: 100%;
      padding-top: (9 / 16) * 100%;
      position: relative;
    }

    .upload-video-field-video-inner {
      @include position(absolute, 0 0 0 0);

      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        fill: $colorMuted;
        width: auto;
        height: 24px;
      }
    }

    margin-right: 30px;

    background-color: $colorBorderLight;

    @include below(sm) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 15px;
    }
  }

  .upload-video-field-status {
    flex-grow: 1;
    flex-shrink: 1;
    @include below(sm) {
      width: 100%;
    }

    .upload-video-field-status-title {
      font-weight: bold;

      .upload-video-field-status-title-value {
        font-weight: 400;
        margin-left: 5px;
      }
    }

    .upload-video-field-status-progress {
      height: 8px;
      width: 100%;
      border-radius: 10px;
      overflow: hidden;
      background-color: $colorBorder;
      margin-top: 8px;

      @include above(md) {
        max-width: 300px;
      }

      .upload-video-field-status-progress-bar {
        height: 100%;
        background-color: #1a1a1a;
      }
    }
  }

  .upload-video-field-right {
    flex-shrink: 0;
    @include below(sm) {
      width: 100%;
      margin-top: 15px;

      button {
        width: 100%;
      }
    }

    margin-left: auto;
  }

  &.dark {
    color: $colorWhite;
    border-color: $colorThree;

    .upload-video-field-video {
      background-color: rgba(white, 0.05);

      .upload-video-field-video-inner {
        svg {
          fill: rgba(white, 0.5);
        }
      }
    }

    .upload-video-field-status-progress {
      background-color: rgba(white, 0.2);

      .upload-video-field-status-progress-bar {
        background-color: rgba(white, 0.5);
      }
    }
  }

  &.slim {
    padding: 0;
    border: none;
  }
}
