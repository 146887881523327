@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.NewPayoutDetail {
  .payout-detail-header {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .payout-detail-header-title {
      @include typo-headline4;
      font-weight: 400;
    }

    .payout-detail-header-amount {
      margin-top: 12px;
    }

    .payout-detail-header-total {
      margin-bottom: 20px;
    }

    .payout-detail-header-right {
      text-align: right;
    }
  }

  .payout-detail-explanation {
    color: $colorTextSecondary;
    margin-bottom: 40px;
  }

  .payout-detail-download {
    margin-bottom: 40px;

    svg {
      fill: $colorBlack;
      @include size(18px);
      margin-right: 10px;
    }
  }

  .BalanceTransactionSum {
    margin-bottom: 20px;
  }

  .payout-detail-status-tag {
    display: inline;
    font-size: 24px;
    padding: 4px 12px;
    border-radius: 4px;
    font-weight: 800;
    background-color: rgba(black, 0.3);

    &.payout-detail-status-tag-paid {
      background-color: rgba($colorSuccess, 0.5);
    }

    &.payout-detail-status-tag-pending {
      background-color: rgba($colorBlue, 0.5);
    }
  }

  .payout-warning {
    padding: 12px;
    border-radius: 12px;
    background-color: rgba($colorWarning, 0.2);
    margin-bottom: 20px;

    .payout-warning-title {
      @include typo-headline6;
      margin-bottom: 8px;
    }
  }
}
