@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/mixins/shadows';

.UpcomingLiveEventsContainer {
  margin: 48px 0 48px 0;

  @include below(sm) {
    padding-left: 24px;
    padding-right: 24px;
  }

  .upcoming-live-events-container-title {
    @include typo-headline5;
    margin-bottom: 16px;
  }

  .upcoming-live-show-more-button.button.secondary {
    color: $colorPrimary;
    max-width: 150px;
    margin: 20px auto;

    svg {
      fill: $colorPrimary;
    }
  }
}
