@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.PlayButton {
  cursor: pointer;
  @include position(absolute, 50% null null 50%);
  transform: translate(-50%, -50%);

  display: flex;
  justify-content: center;
  align-items: center;

  pointer-events: none;
  transition: background-color 250ms ease-in-out, opacity 250ms ease-out;

  svg {
    height: auto;
    width: 100%;
    fill: rgba($colorWhite, 0.9);
    filter: drop-shadow(0 2px 10px rgba(0, 0, 0, 0.5));
  }
}
