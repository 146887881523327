@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.PostAttachmentViewPoll {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  @include below(sm) {
    background-color: $colorDark4;
  }

  .post-attachment-view-poll-wrapper {
    @include below(sm) {
      width: 100%;
      padding: 16px 24px 0 24px;
    }

    @include above(md) {
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;

      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    @include above(md) {
      width: 450px;
    }
  }
}
