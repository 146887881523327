@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.insights-generic-connected-pagination-list {
  border: none;
  box-shadow: none;
}

.InsightsConnectedPaginationList {
  min-height: 200px;
  position: relative;

  .insights-connected-pagination-list-error {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 150px;

    .insights-connected-pagination-list-error-icon {
      @include size(30px);
      fill: $colorBlack;
    }
  }
}
