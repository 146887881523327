@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.InsightsFilters {
  display: flex;
  margin-bottom: 8px;

  @include below(sm) {
    background-color: $colorSurface;
    padding: 8px 24px;
  }

  @include above(md) {
    margin: 8px 0 24px 0;
  }

  .insights-filters-right {
    margin-left: auto;
  }

  .insights-add-membership-button.button.large {
    font-size: 16px;
  }
}

.InsightsSelect {
  display: flex;
  height: 100%;
  border: none;
  border-radius: 999px;
  outline: none;
  appearance: none;
  margin-bottom: 3px; // Font hack
  padding: 11px 24px;
  background-color: $colorBasicGrey;
  color: $colorTextPrimary;
  cursor: pointer;

  background-image: url('../../assets/svg/ChevronDownSvg.svg');
  background-size: 24px;
  background-position: calc(100% - 16px) center;
  background-repeat: no-repeat;

  font-size: 16px;
  line-height: 1.5;
  font-weight: 800;

  @include above(md) {
    padding: 10px 50px 10px 24px;
    width: auto;
    max-width: 220px;
  }
}
