@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/helpers/cards';

.PostComposeModalAttachmentDocument {
  margin: 0 20px;
  @include card-surface;
  overflow: hidden;

  .post-compose-modal-attachment-document-row {
    display: flex;
    align-items: center;
    min-height: 54px;
    padding: 0 20px;
  }

  .post-compose-modal-attachment-document-icon {
    @include size(24px);
    fill: $colorTextPrimary;
    margin-right: 12px;
  }

  .post-compose-modal-attachment-document-name {
    font-size: 14px;
    font-weight: 700;
    flex: 1 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .post-compose-modal-attachment-document-remove {
    @include size(24px);
    fill: $colorTextPrimary;
    margin-left: auto;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  }

  .post-compose-modal-attachment-document-progress {
    background-color: rgba(black, 0.07);
    width: 100%;
    height: 5px;

    .post-compose-modal-attachment-document-progress-bar {
      height: 100%;
      background-color: black;
    }
  }
}
