@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.LocalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 $containerSpacing;
  color: $colorWhite;
  position: relative;
  background-color: $colorPanelBackground;

  box-shadow: 0 60px 80px 0 rgba(0, 0, 0, 0.1),
    0 18px 24px 0 rgba(0, 0, 0, 0.01), 0 8px 10px 0 rgba(0, 0, 0, 0.1),
    0 3px 4px 0 rgba(0, 0, 0, 0.1);

  height: $headerHeight;

  .local-header-title {
    font-weight: 800;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 20px;
  }

  .local-header-close {
    display: flex;
    align-items: center;

    .local-header-close-icon {
      fill: $colorMuted;
      @include size(24px);
      margin-right: 8px;
    }
  }
}
