@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/mixins/spacing';

.InsightsPage {
  $insightsPageMenuHeight: 56px;

  color: $colorTextPrimary;
  background-color: $colorSurface;
  @include page-no-header-spacing;
  min-height: 100vh;

  .insights-page-menu {
    position: fixed;
    width: 100%;
    z-index: 2;
    font-size: 14px;
    display: flex;
    padding: 0 24px;
    height: $insightsPageMenuHeight;
    align-items: center;
    background-color: $colorBorderLight;

    .insights-page-menu-item {
      position: relative;
      align-items: center;
      height: 100%;
      cursor: pointer;
      display: flex;

      &:not(:last-child) {
        margin-right: 24px;
      }

      &:hover {
        color: $colorPrimary;
        text-decoration: none;
      }

      &:not(.active):hover {
        opacity: 0.8;
      }

      &.active {
        &:before {
          content: '';
          @include position(absolute, null 0 0 0);
          background-color: $colorPrimary;
          height: 3px;
          border-top-left-radius: 99px;
          border-top-right-radius: 99px;
        }
      }
    }
  }

  .insights-page-content {
    padding: calc(#{$insightsPageMenuHeight} + 16px) 0 8px 0;
    overflow: hidden;

    @include above(md) {
      padding: calc(#{$insightsPageMenuHeight} + 16px) $containerSpacing
        $containerSpacing $containerSpacing;
    }

    @include below(sm) {
      .InsightsGenericWidget {
        border-radius: 0;
      }
    }

    .row {
      margin: 0 -8px 0 -8px;

      @include above(md) {
        //margin-bottom: -16px;
      }

      div[class^='col-'] {
        padding: 0 8px;
        margin-bottom: 8px;

        @include above(md) {
          margin-bottom: 16px;
        }
      }
    }
  }
}
