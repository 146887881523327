@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.SubscribePagePlanSelector {
  .subscribe-page-plan-selector-cycle-selector {
    display: flex;
    margin: 32px 0;

    &.disabled {
      opacity: 0.8;
      pointer-events: none;
    }

    .subscribe-page-plan-selector-cycle-selector-option {
      display: flex;
      align-items: center;
      cursor: pointer;

      &:hover {
        opacity: 0.9;
      }

      &:not(:last-child) {
        margin-right: 16px;
      }

      &:not(.active) {
        svg {
          fill: #eaeaea;
        }
      }

      &.disabled {
        opacity: 0.6;
        pointer-events: none;
      }

      svg {
        @include size(24px);
        margin-right: 8px;
        fill: $colorPrimary;
      }
    }
  }
}
