@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.ProfileMenu {
  text-align: center;

  .profile-menu-filters-header {
    display: flex;
    margin-top: 40px;
    margin-bottom: 12px;

    .profile-menu-filters-header-title {
      @include typo-headline5;
      padding-left: 30px;
    }

    .profile-menu-filters-header-add {
      margin-left: auto;
      cursor: pointer;
      @include size(24px);
      fill: $colorTextMuted;
      margin-right: 16px;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .profile-menu-avatar {
    position: relative;
    width: 110px;
    height: 110px;
    margin: 50px auto;
  }

  .profile-menu-name {
    @include typo-medium;
    font-weight: 800;
    margin-bottom: 8px;
  }

  .profile-menu-affiliation {
    @include typo-small;
    color: $colorTextSecondary;
    margin-bottom: 48px;
  }

  .profile-menu-link {
    @include typo-headline2;
    display: block;
    font-weight: 600;
    padding: 18px;

    &:hover {
      text-decoration: none;
      color: $colorTextMuted;
    }
  }
}
