@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.BalanceTransactionList {
  width: 100%;
  margin-bottom: 60px;

  @include below(sm) {
    overflow-y: hidden;
    overflow-x: auto;
    width: calc(100% + 30px);
  }

  .balance-transaction-list-table {
    border-collapse: collapse;
    width: 100%;
    overflow: hidden;
  }

  svg {
    fill: $colorBlack;
    @include size(16px);
    margin-right: 10px;
    margin-top: -3px;
  }

  th,
  td {
    text-align: left;
    padding: 10px;

    &:first-child {
      padding-left: 20px;
    }

    &:last-child {
      padding-right: 20px;
    }

    &:nth-last-child(-n + 5) {
      text-align: right;
    }
  }

  td {
    font-size: 13px;

    &:nth-last-child(-n + 5) {
      font-variant-numeric: tabular-nums;
    }
  }

  td:not(.balance-transaction-list-breakable-column),
  th:not(.balance-transaction-list-breakable-column) {
    word-break: keep-all;
    word-wrap: none;
    white-space: nowrap;
  }

  th {
    background-color: rgba(black, 0.1);
  }

  tr:nth-child(even) {
    background-color: rgba(black, 0.1);
  }

  .balance-transaction-list-breakable-column {
    @include above(lg) {
      word-break: break-word;
    }
  }
}
