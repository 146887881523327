@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.AboutPage {
  padding-bottom: 100px;
  overflow: hidden;

  .about-section {
    padding: 80px 0;

    .about-section-header {
      margin-bottom: 50px;

      .about-section-header-label {
        @include typo-small;
        color: $colorNine;
        text-transform: uppercase;
        margin-bottom: 25px;
      }

      .about-section-header-title {
        margin-top: 5px;
        @include typo-headline4;
        font-weight: normal !important;

        @include above(xl) {
          @include typo-headline2;
        }
      }
    }
  }

  .about-mission {
    max-width: 800px;

    .about-mission-text {
      font-size: 20px;
      line-height: 34px;
      color: $colorNine;
    }
  }

  .about-employees {
    &.about-employees-desktop {
      .AboutEmployee {
        margin-bottom: 110px;
      }
    }
  }

  .about-cta {
    border-radius: 16px;
    background-color: rgba(#3f3f41, 0.5);
    padding: 60px 30px;

    @include above(lg) {
      padding: 60px 60px;
    }

    .about-cta-title {
      @include typo-display4;
      text-transform: uppercase;
      margin-bottom: 20px;
    }

    .about-cta-description {
      color: $colorNine;
      font-size: 24px;
      margin-bottom: 30px;
    }

    .about-cta-button {
      display: inline-block;
    }
  }

  .carousel__inner-slide {
    padding-right: 20px;
  }

  .about-carousel {
    position: relative;

    ul {
      padding-inline-start: 0;
      list-style-type: none;
    }
  }
}

.AboutEmployee {
  .about-employee-image {
    padding-top: 100%;
    background-size: cover;
    background-position: center;
  }

  .about-employee-name {
    @include typo-headline6;
    margin-top: 16px;
  }

  .about-employee-title {
    color: $colorNine;
  }
}
