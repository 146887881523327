@import 'styles/global/colors';
@import 'styles/global/variables';
@import 'styles/mixins/shadows';

@mixin card-surface() {
  background: $colorSurface;
  border: 1px solid $colorBorder;
  border-radius: $borderRadiusLarge;
}

@mixin card-near() {
  background: $colorSurface;
  border: 1px solid $colorBorder;
  border-radius: $borderRadiusLarge;
  @include box-shadow-near;
}

@mixin card-far() {
  background: $colorSurface;
  border: 1px solid $colorBorder;
  border-radius: $borderRadiusLarge;
  @include box-shadow-far;
}

@mixin card-extra-far() {
  background: $colorSurface;
  border-radius: $borderRadiusLarge;
  @include box-shadow-extra-far;
}

@mixin card-mobile-stretch() {
  @include below(sm) {
    border-radius: 0;
    margin-left: -$containerSpacing;
    margin-right: -$containerSpacing;
  }
}
