@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/mixins/shadows';

.SettingsPage {
  color: $colorTextPrimary;

  .settings-page-title {
    @include typo-headline4;
    @include above(md) {
      @include typo-headline3;
    }
  }

  .settings-page-col {
    padding-bottom: 40px;

    min-height: calc(100vh - #{$headerHeight} - #{$footerBodyHeightDesktop});

    @include below(sm) {
      min-height: calc(
        100vh - #{$headerHeightMobile} - #{$footerBodyHeightMobile}
      );
    }
  }

  .settings-section-header {
    @include typo-headline5;
    margin-bottom: 15px;
  }

  .settings-section {
    @include box-shadow-far;
    background-color: $colorBorder;
    border-radius: 4px;
    padding: 30px;
    margin-bottom: 60px;

    a:not(.button) {
      text-decoration: underline;

      &:hover {
        color: #999;
      }
    }
  }

  .settings-mobile-warning {
    padding: 10px 15px;
    margin-bottom: 30px;
    background-color: rgba($colorAlarm, 0.5);
    border-radius: 4px;
    font-weight: 800;

    @include above(sm) {
      display: none;
    }
  }
}
