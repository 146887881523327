@import 'styles/global/colors';
@import 'styles/global/variables';
@import 'styles/modules/bourbon';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.BoonTextField {
  // Elements above wrapper
  .boon-text-field-label {
    display: flex;
    align-items: center;

    @include typo-label;

    .boon-text-field-label-required {
      font-weight: normal;
      color: $colorMuted;
      padding-left: 2px;
    }
  }

  .boon-text-field-tooltip {
    display: flex;
    align-items: center;

    margin-left: auto;

    .InfoTooltip {
      font-weight: normal;
      .info-tooltip-box {
        width: 300px;
      }
    }
  }

  // Input wrapper w. addons
  .boon-text-field-wrapper {
    display: flex;
    border: 1px solid $colorBorder;
    border-radius: 3px;
    margin-top: 10px;
    overflow: hidden;
  }

  // Actual <input>
  .boon-text-field-input,
  .boon-text-field-textarea {
    flex: 1;
    outline: none;
    border: none;
    min-width: 0;
  }

  .boon-text-field-textarea {
    min-height: 24px * 3 + 11px * 2;
  }

  .boon-text-field-input,
  .boon-text-field-textarea,
  .boon-text-field-addon-text {
    padding: 11px 15px;
  }

  // Error
  .boon-text-field-error {
    font-size: 13px;
    color: $colorRed;
  }

  // Addons
  .boon-text-field-addon {
    flex-shrink: 0;
    background-color: $colorBackground;
  }

  .boon-text-field-prepend {
    border-right: 1px solid $colorBorder;
  }

  .boon-text-field-append {
    border-left: 1px solid $colorBorder;
  }

  .boon-text-field-addon-main-color {
    background-color: $colorInputFields;
  }

  .boon-text-field-addon-text {
  }

  .boon-text-field-helper-text {
    color: $colorTextSecondary;
  }

  .boon-text-field-under-text {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    color: $colorTextSecondary;
    margin-top: 7px;
    margin-bottom: 0;
  }

  &.dark {
    .boon-text-field-label {
      color: $colorWhite;

      .boon-text-field-label-required {
        color: #999;
      }
    }

    .boon-text-field-helper-text {
      color: #999;
    }

    .boon-text-field-input,
    .boon-text-field-textarea {
      color: $colorBlack;
      background-color: transparent;

      &:disabled {
        opacity: 0.3;
      }
    }

    .boon-text-field-wrapper {
      border: 1px solid $colorBorder;
    }

    .boon-text-field-addon {
      background-color: rgba(white, 0.03);
    }

    .boon-text-field-prepend {
      border-right-color: $colorBorder;
    }

    .boon-text-field-append {
      border-left-color: $colorBorder;
    }
  }

  .BoonInputAddonSelect {
    > svg {
      fill: $colorBlack;
    }
  }
}
