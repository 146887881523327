@import 'styles/global/variables';
@import 'styles/global/colors';

@mixin typo-p() {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

@mixin typo-small() {
  font-size: 13px;
  font-weight: 400;
  line-height: 17px;
}

@mixin typo-medium() {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
}

// New text styles

@mixin typo-headline1() {
  font-size: 40px;
  font-weight: 800;
  line-height: 1.05;
}

@mixin typo-headline2() {
  font-size: 32px;
  font-weight: 800;
  line-height: 1.05;
}

@mixin typo-headline3() {
  font-size: 28px;
  font-weight: 800;
  line-height: 1.05;
}

@mixin typo-headline4() {
  font-size: 24px;
  font-weight: 800;
  line-height: 1.05;
}

@mixin typo-headline5() {
  font-size: 20px;
  font-weight: 800;
  line-height: 1.05;
}

@mixin typo-headline6() {
  font-size: 16px;
  font-weight: 800;
  line-height: 1.1;
}

@mixin typo-body() {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
}

@mixin typo-body-larger() {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.4;
}

@mixin typo-list-header {
  color: #999999;
  font-size: 13px;
  text-transform: uppercase;
}

// Inputs

@mixin typo-label() {
  font-size: 16px;
  font-weight: 800;
  line-height: 1.5;
}

// Creator Landing Page

@mixin typo-display1() {
  font-family: $fontFamilyBoon;
  font-size: 88px;
  font-weight: 800;
  line-height: 1.2;
}

@mixin typo-display2() {
  font-family: $fontFamilyBoon;
  font-size: 72px;
  font-weight: 800;
  line-height: 1.2;
}

@mixin typo-display3() {
  font-family: $fontFamilyBoon;
  font-size: 64px;
  font-weight: 800;
  line-height: 1.2;
}

@mixin typo-display4() {
  font-family: $fontFamilyBoon;
  font-size: 56px;
  font-weight: 800;
  line-height: 1.2;
}

@mixin typo-display5() {
  font-family: $fontFamilyBoon;
  font-size: 40px;
  font-weight: 800;
  line-height: 1.2;
}

@mixin typo-display6() {
  font-family: $fontFamilyBoon;
  font-size: 32px;
  font-weight: 800;
  line-height: 1.2;
}

@mixin typo-radio-text1() {
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: $colorBackgroundBlack;
}
