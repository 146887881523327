@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/helpers/box';
@import 'styles/helpers/cover';
@import 'styles/helpers/cards';

.UserAboutBox {
  border-radius: 16px;
  color: $colorBackgroundBlack;
  position: relative;
  display: flex;
  flex-direction: column;

  @include card-far;
  max-width: 630px;
  margin: 28px auto;

  @include below(sm) {
    margin-bottom: 16px;
  }

  .user-about-box-media {
    display: flex;
    height: 280px;
    @include below(sm) {
      height: 255px;
    }
  }

  .UserSocial {
    display: flex;
    padding-left: 24px;
    padding-bottom: 24px;
  }

  .user-about-box-cover-wrapper {
    width: 100%;
  }

  .user-about-box-cover {
    @include border-top-radius(16px);
    overflow: hidden;
    position: relative;
    background-position: center;
    background-size: cover;
    background-color: black;
    cursor: pointer;
    width: 100%;
    height: 100%;
    //aspect-ratio: 16/9;
    //padding-top: 56.25%;
    //height: 255px;

    .user-about-box-cover-play-button {
      @include size(60px);
    }
  }

  .user-about-box-container {
    padding: $containerSpacing;
    padding-bottom: 0;
    display: flex;
    flex-grow: 1;
    max-height: 200px;
    mask-image: linear-gradient(
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 70%,
      transparent 90%,
      transparent 100%
    );

    &:only-child {
      border-radius: 16px;
    }
  }

  .user-about-box-video {
    @include border-top-radius(16px);
    overflow: hidden;
    background-color: black;

    video {
      max-height: 80vh;
      background-color: black;
    }
  }

  .user-about-box-info {
    flex: 1 1;
    overflow: hidden;
    max-height: 100px;

    .user-about-box-title {
      @include typo-headline4;
      margin-bottom: 10px;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      @include above(lg) {
        @include typo-headline3;
      }
    }

    .user-about-box-description {
      @include typo-body-larger;

      a {
        text-decoration: underline;
        text-underline-position: under;

        &:hover {
          color: inherit;
          opacity: 0.8;
        }
      }
    }

    .user-about-box-more {
      display: block;
      position: relative;
      text-decoration: underline;
      text-underline-position: under;
      margin-bottom: 20px;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .user-about-box-continue-reading {
    cursor: pointer;
    font-weight: 600;
    padding-left: 24px;
    padding-bottom: 24px;
    margin-top: 10px;

    &:hover {
      color: var(--color-creator-primary);
    }
  }

  &.expanded {
    .user-about-box-description {
      white-space: pre-wrap;
    }
  }
}
