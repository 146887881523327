@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.InsightsTablePagination {
  text-align: center;

  .insight-table-pagination-prev,
  .insight-table-pagination-next {
    cursor: pointer;
    fill: var(--color-creator-primary);

    &.disabled {
      cursor: not-allowed;
      fill: rgba(255, 255, 255, 0.3);
    }
  }

  .insight-table-pagination-prev {
    transform: rotate(90deg);
  }

  .insight-table-pagination-next {
    transform: rotate(-90deg);
  }

  .insight-table-pagination-dots,
  .insight-table-pagination-number {
    display: inline-block;
    margin: 2px 3px;
    padding: 7px 15px;
    min-width: 20px;
    text-align: center;
    font-weight: bold;
  }

  .insight-table-pagination-number {
    cursor: pointer;
    background-color: $colorBorderLight;
    border-radius: 10px;

    &.active {
      background-color: var(--color-creator-primary);
      color: var(--color-creator-text-primary);
    }

    &:hover,
    &:focus {
      &:not(.active) {
        background-color: var(--color-creator-primary);
        color: var(--color-creator-text-primary);
      }
    }
  }
}
