@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.AnimatedModal {
  z-index: $zOverlay;
  overflow: auto;

  &#{&}-enter,
  &#{&}-appear {
    .modal-overlay,
    .modal-content {
      opacity: 0;
    }

    .modal-content {
      transform: scale(0.9);
    }
  }

  &#{&}-enter-active,
  &#{&}-appear-active {
    .modal-overlay,
    .modal-content {
      opacity: 1;
      transition: all 250ms ease-out;
    }

    .modal-content {
      transform: scale(1);
    }
  }

  &#{&}-exit {
    .modal-overlay,
    .modal-content {
      opacity: 1;
    }

    .modal-content {
      transform: scale(1);
    }
  }

  &#{&}-exit-active {
    .modal-overlay,
    .modal-content {
      opacity: 0;
      transition: all 250ms ease-in;
    }

    .modal-content {
      transform: scale(0.9);
    }
  }
}
