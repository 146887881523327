@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/shadows';
@import 'styles/modules/bourbon';

@mixin menu-modal-direction($direction) {
  &.#{$direction}#{&}-enter,
  &.#{$direction}#{&}-appear {
    .modal-content {
      margin-#{$direction}: -64px;
    }
  }

  &.#{$direction}#{&}-enter-active,
  &.#{$direction}#{&}-appear-active {
    .modal-content {
      margin-#{$direction}: 0;
    }
  }

  &.#{$direction}#{&}-exit {
    .modal-content {
      margin-#{$direction}: 0;
    }
  }

  &.#{$direction}#{&}-exit-active {
    .modal-content {
      margin-#{$direction}: -64px;
    }
  }
}

.MenuModal {
  z-index: $zOverlay;

  @include position(fixed, 0 0 0 0);

  display: flex;
  justify-content: unset;
  align-items: unset;
  overflow: hidden;

  &.right {
    justify-content: flex-end;
  }

  .modal-content {
    display: flex;
    //@include position(fixed, 0 null 0 null);
    flex-direction: column;
    margin: 0;
    width: calc(100% - 64px);
  }

  &#{&}-enter,
  &#{&}-appear {
    .modal-overlay,
    .modal-content {
      opacity: 0;
    }
  }

  &#{&}-enter-active,
  &#{&}-appear-active {
    .modal-overlay,
    .modal-content {
      opacity: 1;
      transition: all 250ms ease-out;
    }
  }

  &#{&}-exit {
    .modal-overlay,
    .modal-content {
      opacity: 1;
    }
  }

  &#{&}-exit-active {
    .modal-overlay,
    .modal-content {
      opacity: 0;
      transition: all 150ms linear;
    }
  }

  &.full-screen {
    .modal-content {
      width: 100%;
    }
  }

  @include menu-modal-direction($direction: 'right');
  @include menu-modal-direction($direction: 'left');
  @include menu-modal-direction($direction: 'bottom');
}
