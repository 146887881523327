@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.PostCardEventSpecific {
  color: $colorWhite;

  .PostCommentCompose {
    .post-comment-compose-attachment-bar {
      margin-top: 4px;
    }
  }

  .PostCommentBody {
    .post-comment-actions {
      .post-comment-actions-action {
        &:hover {
          color: $colorWhite;
          .post-comment-actions-action-icon {
            fill: $colorWhite;
          }
        }

        &.active {
          color: $colorWhite;
          .post-comment-actions-action-icon {
            fill: $colorWhite;
          }
        }

        &.error {
          color: $colorError;
          .post-comment-actions-action-icon {
            fill: $colorError;
          }
        }
      }
    }
  }
}
