@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.ChannelCarousel {
  .channel-carousel-provider {
    position: relative;

    ul {
      padding-inline-start: 0;
      list-style-type: none;
    }
  }

  .channel-carousel-transition {
    transition: transform 250ms;
    transition-timing-function: ease-out;
    will-change: transform;
  }
}
