@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.PostComposeForm {
  .post-compose-form-avatar {
    @include size(40px);
    flex-shrink: 0;
    margin-right: 15px;
  }

  .post-compose-form-text-field {
    display: flex;
    padding: 4px 16px;
    background-color: rgba(black, 0.07);
    border-radius: 23px;
    align-items: center;
    cursor: pointer;
    width: 100%;

    &:hover {
      background-color: rgba(black, 0.09);
    }

    .post-compose-form-text-field-icon {
      fill: $colorBlack;
      @include size(24px);
      margin-right: 16px;
    }

    .post-compose-form-text-field-bogus {
      svg {
        fill: $colorBlack;
        @include size(24px);
      }
    }
  }

  .post-compose-form-message {
    font-size: 20px;
    line-height: 24px;
    padding: (36px - 24px) / 2 0;
    color: $colorTextPrimary;
    flex: 1;
  }

  .post-compose-form-wrapper {
    display: flex;
    align-items: center;
  }

  .post-compose-form-footer {
    display: flex;
    align-items: center;
    transition: margin-top 250ms ease-out;
  }

  .post-compose-form-footer-attachments-bar {
    display: flex;
    align-items: center;
    margin-left: 70px;
    margin-top: 15px;

    .PostComposeAttachmentButton ~ .PostComposeAttachmentButton {
      margin-left: 16px;
    }
  }

  .post-compose-form-submit-dummy {
    margin-left: auto;
  }

  #postComposePhoto,
  #postComposeDocument,
  #postComposeUpdatePhoto,
  #postComposeUpdateDocument {
    display: none;
  }
}
