@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.HeaderProfile {
  @include position(fixed, 0 null null 0);
  z-index: $zPageTitle;
  color: $colorBlack;

  max-width: calc(100% - 150px);

  .header-profile-wrapper {
    display: flex;
    align-items: center;
    padding: 1px $containerSpacing 0 $containerSpacing;

    height: $headerHeight - 1px;
    background-color: $colorBackground;

    > * {
      transition: opacity 250ms ease-out;
    }

    &:hover {
      .header-profile-name {
        opacity: 0.6;
      }
      color: inherit;
      text-decoration: none;
    }
  }

  .header-profile-menu {
    margin-left: 12px;
    transform: rotate(90deg);
    fill: $colorTextMuted;
    flex: none;

    &:hover {
      fill: $colorTextSecondary;
    }
  }

  .header-profile-back {
    @include size(24px);
    fill: $colorLightGrey;
    margin-right: 15px;

    display: flex;
    align-items: center;
  }

  .header-profile-avatar {
    @include size(32px);
    margin-right: 10px;
    flex-shrink: 0;
  }

  .header-profile-burger {
    @include size(24px);
    fill: $colorWhite;
    margin-right: 16px;
    flex-shrink: 0;
  }

  .header-profile-name {
    @include typo-headline6;
    line-height: initial;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 2px;
  }

  &.dark {
    .header-profile-wrapper {
      background-color: $colorPanelBackground;
      color: $colorDarkTextPrimary;
    }
  }
}
