@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.PostCardAttachmentPoll {
  margin: 0 24px;
  border-radius: 16px;
  overflow: hidden;
}
