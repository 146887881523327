@import 'styles/modules/bourbon';
@import 'styles/mixins/typography';
@import 'styles/mixins/spacing';
@import 'styles/mixins/responsiveness';
@import 'styles/helpers/play-button';
@import 'styles/global/colors';
@import 'styles/global/variables';

.UserPage {
  min-height: 100vh;
  position: relative;
  @include page-no-header-spacing;

  padding-bottom: 80px;

  .user-page-banners {
    @include above(md) {
      margin-top: -30px;
      margin-bottom: 30px;
    }
  }

  .user-page-container {
    display: flex;
    max-width: 1440px;
  }

  .user-page-side-menu {
    width: 315px;
    flex: 0 0 315px;
    flex-shrink: 0;
    margin-right: 60px;
    min-height: 100vh;

    .ProfileMenu {
      @include position(sticky, $headerHeight + 30px null null null);
      max-height: calc(100vh - #{$headerHeight} - 30px);
      padding-bottom: 15px;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }

  .user-page-content {
    flex: 1;
    min-width: 0;
  }

  .user-content-header {
    @include typo-headline4;
    margin-bottom: 20px;
  }

  .user-page-row {
    display: flex;

    .user-page-row-left {
      flex: 1;
    }

    .user-page-row-right {
      flex-shrink: 0;
      width: 350px;

      margin-left: 30px;
      padding-left: 30px;
      border-left: 1px solid rgba(#d8d8d8, 0.1);
    }
  }

  // Sub page
  .user-page-sub-container {
    display: flex;
    justify-content: center;
  }

  .user-page-sub-sidebar {
    width: 315px;
    margin-left: 60px;
    flex-shrink: 0;
    position: relative;

    .user-page-sub-sidebar-inner {
      @include position(sticky, $headerHeight + 20px null null null);
    }
  }

  .user-page-sub-content {
    flex: 1;
    min-width: 0;

    @include above(xl) {
      max-width: 700px;
    }
  }

  .no-post {
    text-align: center;
    margin-top: 100px;
    font-size: 19px;
  }
}
