@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.InsightsTableWidget {
  min-height: 600px;
  margin-top: 10px;

  .insights-table-widget-error {
    @include position(absolute, 50% 0 0 50%);
    transform: translate(-50%, -50%);
    text-align: center;
    height: 10%;
  }

  .insights-table-widget-error-icon {
    @include size(30px);
    fill: $colorBlack;
  }

  .insights-table-widget-avatar {
    width: 22px;
    height: 22px;
    display: inline-block;
    margin-right: 10px;
    vertical-align: bottom;
  }

  .insights-table-widget-mobile-data {
    padding: 10px 0 20px 0;

    border-bottom: 1px solid rgba(0, 0, 0, 0.05);

    &:first-of-type {
      border-top: 1px solid rgba(0, 0, 0, 0.05);
    }
  }

  .insights-table-widget-mobile-data-element {
    &:not(:last-of-type) {
      padding-bottom: 10px;
    }
  }

  .insights-table-widget-video-img {
    width: 70px;
    height: 42px;
    padding: 5px;
    border-radius: 10px;
    margin-right: 5px;
  }

  .insights-table-widget-avatar-name {
    vertical-align: middle;
    display: inline-block;
  }

  .insights-table-widget-controls {
    display: flex;
    align-items: center;
  }
  .insights-table-widget-search-text-field {
    display: inline-block;
    background-color: $colorBorderLight;
    border-radius: 999px;
    width: 100%;

    &:hover,
    &:focus {
      background-color: $colorBorder;
    }

    @include above(md) {
      width: 180px;
    }

    .BoonTextField {
      .boon-text-field-wrapper {
        margin-top: 0;
        border: none;
      }

      .boon-text-field-addon {
        background-color: transparent;
      }

      .boon-text-field-prepend {
        border: none;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
      }

      .boon-text-field-input {
        color: $colorTextPrimary;
        padding-top: 8px;
        padding-bottom: 8px;
      }
    }

    .insights-table-widget-search-text-field-icon {
      fill: $colorBlack;
      margin: 9px -4px 0 16px;
    }

    &::placeholder {
      color: $colorNine !important;
    }

    &:focus {
      outline: none;
    }
  }

  .insights-table-widget-meta-filters {
    margin-top: 24px;
    margin-bottom: 24px;
    @include above(md) {
      margin-bottom: 0px;
    }
  }

  .insights-table-widget-meta {
    display: inline-block;
  }

  .insights-table-widget-table-sort {
    display: inline-block;
  }

  .insights-table-widget-table-sort-up,
  .insights-table-widget-table-sort-down {
    fill: $colorTextMuted;

    &.active {
      fill: var(--color-creator-primary);
    }
  }

  .insights-table-widget-table-sort-up {
    transform: translate(-24px, -4px) rotate(180deg);
  }

  .insights-table-widget-table-sort-down {
    transform: translate(0, 2px);
  }

  .insights-table-widget-column-selector {
    fill: rgba(0, 0, 0, 0.3);
    float: right;
    cursor: pointer;

    &:hover,
    &:focus {
      fill: rgba(0, 0, 0, 0.4);
    }
  }

  .insights-table-widget-selector {
    position: relative;
    display: block;
    float: right;
    top: 8px;
  }

  .insights-table-widget-selector-menu {
    position: absolute;
    top: 25px;
    right: 0;
    background-color: white;
    border-radius: 8px;
    min-width: 250px;
    padding: 8px 8px 4px 8px;
    color: $colorBlack;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.1), 0 3px 12px 0 rgba(0, 0, 0, 0.05),
      0 10px 40px 0 rgba(0, 0, 0, 0.1);
    z-index: 1;

    strong {
      padding-left: 8px;
    }
  }

  .insights-table-widget-selector-list {
    margin: 6px 0 0 -35px;

    li {
      list-style-type: none;

      input[type='checkbox'] {
        display: none;
      }
    }
  }

  .insights-table-widget-selector-list-item.disabled {
    color: gray;

    cursor: not-allowed;

    label,
    svg {
      cursor: not-allowed;
    }

    svg {
      fill: gray;
    }
  }

  .insights-table-widget-selector-checkbox {
    margin-right: 5px;
    vertical-align: top;
  }

  .insights-table-widget-filters {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    @include above(lg) {
      margin-left: 10px;
      margin-bottom: 0;
    }
  }

  .insights-table-widget-button {
    display: inline-block;

    @include above(lg) {
      &:not(:first-of-type) {
        margin-left: 10px;
      }
    }
  }

  .insights-table-widget-button-download {
    margin-left: auto;
  }

  .insight-table-widget-clear-filters {
    text-decoration: underline;
    font-weight: bold;
    margin-left: 5px;
    cursor: pointer;
  }

  .insights-table {
    font-size: 14px;
    width: 100%;
    margin-bottom: 2rem;
    border-collapse: collapse;
    table-layout: fixed;

    thead th,
    tbody td {
      height: 40px;
    }

    thead th {
      vertical-align: bottom;
      border-bottom: 1px solid rgba(0, 0, 0, 5%);
      cursor: pointer;
      line-height: 18px;

      &:first-of-type {
        width: 20vw;
      }
    }

    tr {
      border-bottom: 1px solid rgba(0, 0, 0, 5%);

      &.clickable {
        cursor: pointer;

        &:hover {
          background-color: rgba(0, 0, 0, 5%);
        }
      }
    }

    td {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 4px 8px 4px 0px;
      vertical-align: baseline;

      &:not(:first-of-type) {
        padding: 4px 8px;
      }
    }

    .pill {
      display: inline;
      font-weight: bold;
      padding: 2px 6px;
      border-radius: 4px;
      background-color: $colorBorderLight;
      margin-right: 3px;
    }

    .star {
      fill: $colorYellow;
    }
  }

  .star,
  .half-star,
  .empty-star {
    transform: scale(0.75);
    margin-right: -5px;
  }

  .star,
  .half-star {
    fill: $colorYellow;
  }

  .empty-star {
    fill: $colorBorder;
  }

  .star-rating {
    vertical-align: middle;
    padding-left: 5px;
  }

  .insights-table-widget-table-options-menu-header {
    width: 24px;
  }
}
