@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/helpers/cover';

.EventVisibilityModal {
  z-index: $zOverlay;
  color: $colorBlack;

  @include above(md) {
    padding: 32px 0;
  }

  .modal-content {
    background-color: white;
    position: relative;
    overflow: hidden;
    border-radius: 16px;

    @include above(md) {
      width: 450px;
    }

    @include below(md) {
      width: 90%;
    }
  }

  .event-visibility-modal-top {
    padding: $containerSpacing;
    background-color: rgba($colorGreen, 0.2);
  }

  .event-visibility-modal-header {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .event-visibility-modal-header-title {
      @include typo-headline5;
    }

    .event-visibility-modal-header-close {
      margin-left: auto;
      fill: $colorMuted;
      @include size(24px);

      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }
  }

  .event-visibility-modal-result {
    display: flex;
    align-items: center;

    .event-visibility-modal-result-icon {
      fill: $colorGreen;
      margin-right: 8px;
    }

    .event-visibility-modal-result-title {
    }

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  .event-visibility-modal-bottom {
    padding: $containerSpacing;

    .event-visibility-modal-bottom-title {
      @include typo-headline5;
      margin-bottom: 16px;
    }
  }

  .event-visibility-modal-card {
    display: flex;
    border-radius: 16px;
    overflow: hidden;

    .event-visibility-modal-card-left {
      width: 170px;

      .event-visibility-modal-card-cover {
        @include eventCover;
        @include size(100%);
      }
    }

    .event-visibility-modal-card-content {
      flex: 1 1;
      padding: 16px;
      background-color: #f0f1f2;
    }

    .event-visibility-modal-card-cover-title {
      font-weight: bold;
    }
  }

  .BoonTextField {
    margin-top: 24px;

    .boon-text-field-append {
      background-color: transparent;
      border: none;

      display: flex;
      align-items: center;
      padding: 0 16px;

      svg {
        @include size(24px);

        &:hover {
          opacity: 0.8;
          cursor: pointer;
        }
      }
    }
  }

  .event-visibility-modal-tips {
    margin-top: 24px;

    .event-visibility-modal-tips-item {
      cursor: pointer;
      display: flex;
      align-items: center;

      &:hover {
        opacity: 0.8;
      }

      svg {
        margin-right: 8px;
        fill: $colorMuted;
      }
    }
  }
}
