@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/helpers/cards';

.HeaderControlsSearchBox {
  // Elastic Search UI
  .sui-search-box {
    position: relative;

    &__submit {
      // Hide the search button
      display: none;
    }

    &__text-input {
      @include typo-medium;
      border: none;
      border-radius: 999px;
      padding: 6px 16px 6px 38px;
      margin-right: 16px;
      height: 40px;

      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);

      &:focus {
        outline: none;
      }
    }

    &__wrapper::before {
      content: '';
      position: relative;
      display: inline-block;
      left: 34px;
      top: 7px;
      bottom: 0;
      width: 24px;
      height: 24px;
      background-image: url('../../assets/svg/SearchSvg.svg');
    }

    .sui-search-box-cancel {
      cursor: pointer;
      position: absolute;
      display: inline-block;
      right: 32px;
      top: 8px;
      width: 24px;
      height: 24px;
    }

    &__autocomplete-container {
      z-index: $zHeader;

      @include card-far;
      position: absolute;
      background: $colorSurface;
      margin-top: 5px;
      margin-left: 24px;
      border-radius: 16px;
      padding: 16px 0;
      width: 300px;
      height: auto;
    }

    .autocomplete-result {
      cursor: pointer;
      text-decoration: none;
      padding: 8px 16px;
      display: flex;
      align-items: center;

      &.active,
      &:hover {
        background-color: $colorBorderLight;
      }

      .autocomplete-title {
        @include typo-medium;
        color: $colorTextPrimary;
        text-overflow: ellipsis;
        overflow-x: hidden;
      }

      .autocomplete-avatar {
        @include size(30px);
        display: inline-block;
        margin-right: 10px;
      }
    }
  }
}
