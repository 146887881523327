@import 'styles/global/colors';
@import 'styles/modules/bourbon';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.AddSocialModal {
  .add-social-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .add-social-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;

      flex: 0 0 33%;
      padding: 20px 15px;

      &:hover {
        .add-social-item-icon {
          background-color: darken($colorBasicGrey, 3%);
        }
      }

      .add-social-item-icon {
        flex-shrink: 0;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        @include size(69px);
        background-color: $colorBasicGrey;
        border-radius: 50%;
        transition: background-color 250ms;
        margin-bottom: 5px;

        svg {
          @include size(50%);
        }
      }
    }
  }

  .add-social-form {
    .add-social-form-submit {
      width: 100%;
      margin-top: 50px;
    }
  }
}
