@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.CreatorMembershipUpsert {
  .creator-membership-upsert-items {
    .ProductCard {
      width: 100%;
      margin-bottom: 16px;
    }
  }
}
