@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/helpers/cards';

.UserMemberships {
  margin: 45px 0 20px 0;
  @include above(sm) {
    margin: 45px 0px 20px 0px;
  }
  @include above(md) {
    margin: 45px 150px 20px 150px;
  }

  .user-memberships-title {
    @include typo-headline2;
    margin-bottom: 24px;
    text-align: center;
  }

  .ProductCard {
    margin-bottom: 16px;
  }

  .button-show-more {
    margin-top: 20px;
  }
}
