@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.CreatorPagePartnership {
  padding: 40px 0;

  .creator-page-story {
    @include above(lg) {
      margin-top: 24px;
    }
  }

  .creator-page-full-size-image {
    @include below(lg) {
      margin-top: 24px;
    }
  }
}
