@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/helpers/cover';

.PostCardAttachmentEvent {
  .EventCard {
    border-radius: 0 !important;
    border: none;
    background-color: rgba(white, 0.05) !important;

    &.playing {
      border: none;
      box-shadow: none;
    }

    .event-card-wrapper {
      border-radius: 0 !important;
      border: none;
    }

    .event-card-media,
    .Giffer {
      border-radius: 0 !important;
      border: none;
    }
  }
}
