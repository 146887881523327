@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.FeedFilter {
  display: flex;
  position: relative;
  margin-bottom: 24px;

  &.feed-filter-active-Activity {
    max-width: 630px;
    margin: auto;
  }

  &.feed-filter-active-Videos {
    margin: auto 0;
  }

  svg {
    fill: black;
  }

  .button + .button {
    margin-left: 8px;
  }

  .button {
    &.bookmark-filter {
      padding-left: 7px;
      padding-right: 7px;
    }
    .emoji {
      font-family: emoji;
      display: inline-block;
    }

    &.secondary.active {
      color: var(--color-creator-text-primary);
      background-color: rgba(var(--color-creator-primary-rgb), 0.2);
      border-color: transparent;

      svg {
        fill: var(--color-creator-primary);
      }
    }

    .filter-active-filter-count {
      border-radius: 100%;
      background-color: var(--color-creator-primary);
      width: 22px;
      padding: 4px;
      margin-left: 2px;
    }

    max-width: 200px + 40px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .button-filter-shortcut {
    &.secondary.active {
      color: var(--color-creator-primary);
    }
    svg {
      margin-right: 4px;
    }
  }

  .button.filter.active {
    svg {
      fill: $colorWhite;
      fill: var(--color-creator-text-primary);
    }
  }

  .button-group {
    @include below(sm) {
      overflow-x: auto;
      flex-wrap: nowrap;
      margin: 0 (-$containerSpacing);
      padding: 0 $containerSpacing;
    }

    .button {
      flex: 0 0 auto;
    }
  }
}

.modal.FeedFilterModal {
  color: $colorTextPrimary;

  .modal-content {
    .emoji {
      font-family: emoji;
      display: inline-block;
      margin-right: 4px;
    }

    background-color: $colorWhite;
    position: relative;
    padding: 25px;

    @include above(md) {
      max-width: 450px;
      border-radius: 16px;
    }

    @include below(md) {
      width: 100%;
      min-height: 100%;
    }

    hr {
      border: none;
      border-top: 1px solid $colorSecondary;
      margin-top: 10px;
    }

    .feed-filter-header {
      padding-top: 10px;
      margin-bottom: 10px;
    }

    .button {
      &.secondary.active {
        color: var(--color-creator-text-primary);
        background-color: rgba(var(--color-creator-primary-rgb), 0.2);
        border-color: transparent;

        svg {
          fill: var(--color-creator-primary);
        }
      }
    }

    .button-filter-shortcut {
      &.secondary.active {
        color: var(--color-creator-primary);
      }
      svg {
        margin-right: 4px;
      }
    }
  }

  .filter-done-button {
    width: 100%;
    margin-top: 20px;
  }
}
