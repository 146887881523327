@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/helpers/cover';

.ChannelHeader {
  $avatarSize: 160px;
  margin-bottom: 48px;

  .channel-header-image {
    @include userCover;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    filter: brightness(0.98);

    @include below(md) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .channel-header-profile {
    position: relative;
    width: $avatarSize;
    height: $avatarSize;
    border-radius: 9999px;
    top: calc(-#{$avatarSize} / 2);
    right: -25px;
    border: 3px solid $colorWhite;
    filter: brightness(0.98);

    @include below(md) {
      width: calc(#{$avatarSize} / 2);
      height: calc(#{$avatarSize} / 2);
      top: calc(-#{$avatarSize} / 4);
      right: calc(-50% + #{$avatarSize} / 4);
    }
  }

  .channel-header-content {
    position: relative;
    margin-top: calc(-#{$avatarSize} + 24px);
    right: calc(-#{$avatarSize} - 40px);
    max-width: calc(100% - #{$avatarSize} - 40px);

    @include below(md) {
      max-width: 100%;
      margin-top: -24px;
      left: 0;
      padding: 0 $containerSpacing;
      text-align: center;
    }
  }

  .channel-header-name {
    @include typo-headline5;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
  }

  .channel-header-title {
    @include typo-small;
    font-weight: 500;
  }

  .channel-header-follow-button {
    position: absolute;
    top: 0;
    right: 24px;

    @include below(md) {
      position: relative;
      top: 10px;
      margin: auto auto 24px auto;
      right: 0;
    }
  }
}
