@import 'styles/global/colors';

$datepicker__selected-color: #1b2530;
$datepicker__border-radius: 0;
$datepicker__background-color: white;
$datepicker__font-family: inherit;

@import '~react-datepicker/src/stylesheets/datepicker.scss';

.react-datepicker {
  border: none;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.1), 0 3px 12px 0 rgba(0, 0, 0, 0.05);
  width: 320px;
  border-radius: 16px;
}

.react-datepicker__month-container {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  overflow: hidden;
}

.react-datepicker__time-container {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow: hidden;
  border-left-color: rgba(black, 0.1);
}

.react-datepicker__triangle {
  &:before {
    border-top-color: $colorBorder !important;
    //border: none !important;
  }
}

.react-datepicker__header {
  .react-datepicker__current-month {
    margin: 10px 0;
  }

  border: none;
}

.react-datepicker__day {
  border-radius: 14px !important;

  &:not(.react-datepicker__day--selected) {
    &:hover {
      background-color: lighten($datepicker__selected-color, 80);
    }
  }
}

.react-datepicker__time-list-item {
  height: auto !important;
  border-radius: 14px;
  margin: 0 5px;

  &:not(.react-datepicker__time-list-item--selected) {
    &:hover {
      background-color: lighten($datepicker__selected-color, 80) !important;
    }
  }
}

.react-datepicker__navigation {
  margin-top: 10px;
}
