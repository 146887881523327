@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/helpers/cards';

.HeaderControlsAccordionMenu {
  margin-top: 36px;

  .header-controls-accordion-menu-elements {
    h4 {
      display: inline-block;
    }

    svg {
      fill: white;
      float: right;
    }

    &:not(:first-of-type) {
      margin-top: 16px;
    }
  }

  .header-controls-accordion-menu-chevron {
    transform: rotate(0deg);
    transition: transform 0.5s ease;

    &.active {
      transform: rotate(-180deg);
    }
  }

  .header-controls-accordion-menu-subelements {
    max-height: 0;
    padding: 0;
    opacity: 0;
    transition: all 0.5s;
    overflow: hidden;

    &.active {
      opacity: 1;
      max-height: 1000px;
    }

    a {
      text-decoration: none;
      display: block;
      margin-top: 16px;

      &:last-of-type {
        margin-bottom: 24px;
      }

      &:hover,
      &:active,
      &:focus {
        color: white;
        text-decoration: none;
      }
    }
  }
}
