@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.CreatorPageHow {
  padding-top: 80px;

  .creator-page-section-title {
    margin-bottom: 0;
  }

  .creator-page-how-section {
    padding: 70px 0;

    @include above(lg) {
      padding: 100px 0;
    }

    .creator-page-how-section-row {
    }

    .creator-page-how-section-index {
      @include above(lg) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .creator-page-how-section-row-number {
        @include size(40px, auto);
        fill: $colorPrimary;

        @include above(lg) {
          @include size(70px, auto);
        }
      }

      .creator-page-how-section-row-arrow {
        margin-top: 16px;
        fill: $colorPrimary;

        @include below(md) {
          display: none;
        }
      }
    }

    .creator-page-how-section-content {
    }

    .creator-page-how-section-media {
      display: flex;
      justify-content: center;

      .creator-page-how-section-media-image {
        width: 80%;
        object-fit: contain;
        align-self: flex-start;
      }
    }

    &.creator-page-how-section-dark {
      background-color: $colorDarkBackground;
      color: $colorDarkTextPrimary;

      .creator-page-perks-item-check {
        fill: $colorDarkTextPrimary;
      }
    }

    &.creator-page-how-section-streaming {
      padding-bottom: 0;
    }
  }

  @include below(sm) {
    .creator-page-how-section-index {
      order: 1;
      margin-bottom: 16px;
    }

    .creator-page-how-section-content {
      order: 2;
    }

    .creator-page-how-section-media {
      order: 0;
    }
  }

  .creator-page-how-streaming {
    position: relative;

    .creator-page-how-streaming-image {
      width: 100%;
      margin-top: 40px;

      @include above(lg) {
        margin-top: 120px;
      }
    }

    .creator-page-how-streaming-card {
      @include below(md) {
        display: none;
      }

      width: 300px;
      @include position(absolute, 0 0 null null);
      transform: translateY(-400px);
    }
  }
}
