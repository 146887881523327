@import 'styles/global/colors';
@import 'styles/global/variables';
@import 'styles/mixins/typography';

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  appearance: none;
  outline: none;
  border: none;

  box-sizing: border-box;
  border: 2px solid;
  border-radius: 999px;

  cursor: pointer;
  user-select: none;

  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;

  // States
  &.disabled,
  &:disabled {
    opacity: 0.3;

    cursor: default;
    pointer-events: none;
  }

  &:hover {
    text-decoration: none;
  }

  // Sizes

  // Default size, medium
  font-size: 14px;
  line-height: 1;
  font-weight: 700;
  padding: 10px 17px;
  height: 40px;

  svg {
    height: 24px;
    width: 24px;
  }

  &.small {
    font-size: 13px;
    font-weight: 700;
    padding: 5px 14px;
    height: 32px;

    svg {
      height: 18px;
      width: 18px;
    }
  }

  &.large {
    font-size: 20px;
    font-weight: 700;
    padding: 6px 24px;
    height: 48px;
  }

  // Colors
  &.primary {
    background-color: $colorPrimary;
    border-color: $colorPrimary;
    color: $colorWhite;

    svg {
      fill: $colorWhite;
    }

    &:hover,
    &:focus {
      background-color: lighten($colorPrimary, 10%);
      border-color: lighten($colorPrimary, 10%);
    }
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px rgba($colorPrimary, 0.05);
    }
    &:active,
    &.active {
      background-color: darken($colorPrimary, 10%);
      border-color: darken($colorPrimary, 10%);
    }

    &.loading:before {
      background-color: $colorPrimary;
    }

    &.light {
      background-color: $colorWhite;
      border-color: $colorWhite;
      color: $colorBlack;

      &:hover,
      &:focus {
        background-color: darken($colorWhite, 10%);
        border-color: darken($colorWhite, 10%);
      }
      &:focus {
        outline: none;
        box-shadow: 0 0 0 2px rgba($colorWhite, 0.05);
      }
      &:active,
      &.active {
        background-color: lighten($colorWhite, 10%);
        border-color: lighten($colorWhite, 10%);
      }
    }
  }

  &.secondary {
    background-color: $colorSecondaryButton;
    border-color: $colorSecondaryButton;
    color: $colorTextPrimary;

    &:hover,
    &:focus {
      background-color: darken($colorSecondaryButton, 5%);
      border-color: darken($colorSecondaryButton, 5%);
    }
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px rgba($colorSecondaryButton, 0.05);
    }
    &:active,
    &.active {
      background-color: darken($colorSecondaryButton, 10%);
      border-color: darken($colorSecondaryButton, 10%);
    }

    &.loading {
      color: transparent;

      &:before {
        color: $colorSecondaryButton !important;
      }

      &:after {
        border-top-color: $colorTextPrimary;
        border-left-color: $colorTextPrimary;
      }
    }

    &.light {
      background-color: $colorSecondaryLightButton;
      border-color: $colorSecondaryLightButton;
      color: $colorDarkTextPrimary;

      &:hover,
      &:focus {
        background-color: lighten($colorSecondaryLightButton, 5%);
        border-color: lighten($colorSecondaryLightButton, 5%);
      }
      &:focus {
        outline: none;
        box-shadow: 0 0 0 2px rgba($colorSecondaryLightButton, 0.05);
      }
      &:active,
      &.active {
        background-color: lighten($colorSecondaryLightButton, 10%);
        border-color: lighten($colorSecondaryLightButton, 10%);
      }
    }
  }

  &.surface {
    background-color: $colorSurface;
    border-color: $colorSurface;
    color: $colorTextPrimary;

    &:hover,
    &:focus {
      background-color: darken($colorSurface, 5%);
      border-color: darken($colorSurface, 5%);
    }
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px rgba($colorSurface, 0.05);
    }
    &:active,
    &.active {
      background-color: darken($colorSurface, 10%);
      border-color: darken($colorSurface, 10%);
    }

    &.loading {
      color: transparent;

      &:before {
        color: $colorSurface !important;
      }

      &:after {
        border-top-color: $colorTextPrimary;
        border-left-color: $colorTextPrimary;
      }
    }

    &.light {
      background-color: $colorSecondaryLightButton;
      border-color: $colorSecondaryLightButton;
      color: $colorDarkTextPrimary;

      &:hover,
      &:focus {
        background-color: lighten($colorSecondaryLightButton, 5%);
        border-color: lighten($colorSecondaryLightButton, 5%);
      }
      &:focus {
        outline: none;
        box-shadow: 0 0 0 2px rgba($colorSecondaryLightButton, 0.05);
      }
      &:active,
      &.active {
        background-color: lighten($colorSecondaryLightButton, 10%);
        border-color: lighten($colorSecondaryLightButton, 10%);
      }
    }
  }

  &.creator-primary {
    background-color: $colorPrimary;
    background-color: var(--color-creator-primary);
    border-color: $colorPrimary;
    border-color: var(--color-creator-primary);
    color: $colorWhite;
    color: var(--color-creator-text-primary);

    &:hover,
    &:focus {
      filter: brightness(1.05);
    }
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px rgba($colorPrimary, 0.05);
      box-shadow: 0 0 0 2px rgba(var(--color-creator-primary), 0.05);
    }
    &:active,
    &.active {
      filter: brightness(1.1);
    }

    &.loading:before {
      background-color: $colorPrimary;
      background-color: var(--color-creator-primary);
    }
  }

  &.facebook {
    background-color: $colorFacebook;
    border-color: $colorFacebook;
    color: $colorWhite;

    &:hover {
      background-color: darken($colorFacebook, 7.5%);
      border-color: darken($colorFacebook, 7.5%);
    }
    &:active {
      background-color: darken($colorFacebook, 10%);
      border-color: darken($colorFacebook, 10%);
    }

    &.loading:before {
      background-color: darken($colorFacebook, 5%);
    }
  }

  // Loading buttons

  &.load {
    &:before {
      content: '';
      transition: opacity 0.3s ease-out;
      opacity: 0;
    }
    &:after {
      content: '';
      transition: opacity 0.5s ease-out;
      opacity: 0;
    }
  }

  &.loading {
    &:before {
      content: '';
      position: absolute;
      display: inline-block;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      opacity: 1;
      border-radius: 999px;
    }

    &:after {
      $spinner-color: #fff;

      content: '';
      position: absolute;
      top: calc(50% - 15px);
      left: calc(50% - 15px);
      display: inline-block;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 4px solid $spinner-color;
      border-bottom-color: transparent;
      border-right-color: transparent;
      opacity: 1;
      animation: spinner 1s linear infinite;

      box-sizing: border-box;
      display: inline-block;
      vertical-align: middle;

      @keyframes spinner {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }

  &.loading.small {
    &:after {
      @include size(20px);
      top: calc(50% - 10px);
      left: calc(50% - 10px);
      border-width: 2px;
    }
  }

  &.loading {
    &[data-progress] {
      &:before {
        color: $colorWhite;
        content: attr(data-progress) '%';
        text-align: left;
        padding-left: 10px;
        padding-top: 10px;
      }

      &:after {
        right: 10px;
      }
    }

    &:before {
      content: '';
      position: absolute;
      display: inline-block;
      box-sizing: border-box;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      opacity: 1;
    }

    &:after {
      $spinner-color: #fff;

      content: '';
      position: absolute;
      display: inline-block;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 4px solid $spinner-color;
      border-bottom-color: transparent;
      border-right-color: transparent;
      opacity: 1;
      animation: spinner 1s linear infinite;

      box-sizing: border-box;
      display: inline-block;
      vertical-align: middle;

      @keyframes spinner {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }
}

.textbutton {
  display: flex;
  align-items: center;
  justify-content: center;

  appearance: none;
  outline: none;
  border: none;
  background: none;
  padding: 0;

  box-sizing: border-box;

  cursor: pointer;
  user-select: none;

  &.secondary {
    color: $colorSecondary;
    font-weight: 800;
  }
}

.button-group {
  display: flex;
  flex-wrap: wrap;
  margin-top: -5px;

  .button {
    margin: 5px 5px 5px 0;

    &:last-of-type {
      margin-right: 0;
    }
  }
}
