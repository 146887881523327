@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/helpers/cards';

.InsightsSimpleNumberWidget {
  @include card-near;
  color: $colorTextPrimary;
  background-color: $colorSurface;
  padding: 16px 24px;
  border-radius: 16px;
  position: relative;

  .insights-simple-number-widget-title {
    font-weight: 800;
  }

  .insights-simple-number-widget-value {
    font-family: $fontFamilyBoon;
    font-size: 32px;
    font-weight: 800;
    line-height: 1;
  }

  .insights-simple-number-widget-error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 32px;
    color: $colorTextMuted;
  }

  .star,
  .half-star {
    fill: orange;
  }

  .empty-star {
    fill: $colorMuted;
  }

  .star-rating {
    padding-left: 5px;
  }
}
