@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/mixins/spacing';
@import 'styles/helpers/cards';

.Gallery {
  $gutterSize: -16px;
  $mobileGutterSize: -8px;
  .loading-spinner {
    min-height: 100px;
    width: 100%;
    position: relative;
  }

  .gallery-masonry-grid {
    display: flex;
    margin-left: $gutterSize;
    width: auto;
    @include below(md) {
      margin-left: $mobileGutterSize;
    }
  }
  .gallery-masonry-grid-column {
    padding-left: $gutterSize * -1;
    background-clip: padding-box;
    @include below(md) {
      padding-left: $mobileGutterSize * -1;
    }
  }

  .placeholder {
    border: 1px solid $colorBorder;
    border-radius: 20px;
    height: 183px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $colorBorderLight !important;
    margin-bottom: 8px;

    svg {
      fill: $colorPrimary;
    }
    @include above(md) {
      //display: none;
    }
  }

  .PostCompose {
    margin-top: -48px;
  }
}
