@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.MembershipNavigation {
  display: flex;
  justify-content: center;
  overflow: hidden;
  //overflow-y: hidden;
  margin-bottom: 48px;
  margin-left: -24px;
  margin-right: -24px;

  & + .ProductCard {
    margin: 24px auto;
    width: 33%;
  }

  .membership-navigation-inner {
    display: inline-flex;
    overflow-y: hidden;
    margin: 0 auto;
    padding: 0 8px;

    // Remove scrollbar
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera */
    }
  }
}
