@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/global/variables';

.EventStatus {
  font-family: $fontFamily;

  // FONT
  font-size: 13px;
  font-weight: 400;
  color: $colorWhite;
  overflow: hidden;

  display: flex;

  > span {
    display: flex;
    align-items: center;
    padding: 2px 6px 2px 6px; // FONT HACK
  }

  .event-status-live-icon {
    fill: $colorWhite;
    @include size(24px);
  }

  .event-status-pending {
    background-color: #2e4465;
    border-radius: 3px;
  }

  .event-status-live-text {
    background-color: $colorLive;
    border-radius: 3px;
    margin-left: 6px;
    display: inline-block;
    font-weight: 700;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  .duration {
    background-color: $colorDarkBackground;
    font-weight: bold;
    border-radius: 3px;
    margin-left: 6px;
  }

  .liveIn {
    color: $colorBlack;
    background-color: $colorWhite;
  }

  .release {
    color: $colorBlack;
    background-color: $colorWhite;
  }

  .live {
    background-color: $colorRed;
  }

  .rewatch {
    display: flex;
    align-items: center;
    background-color: $colorMessageFrame;
    border-radius: 3px;
    backdrop-filter: saturate(180%) blur(20px);
  }

  .liveIn,
  .release,
  .live {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  .liveIn,
  .release,
  .live,
  .rewatch {
    font-weight: 800;
  }

  $ball-size: 20px;
  @mixin ball-scale-multiple($n: 3, $start: 0) {
    @for $i from 2 through $n {
      > div:nth-child(#{$i}) {
        animation-delay: ($i - 1) * 0.2 + s;
      }
    }
  }

  .loader-inner {
    // Lovely SASS by Daniel Hansen
    @include ball-scale-multiple();
    position: relative;
    transform: translate(-$ball-size/2, -$ball-size/2);
    margin-left: 7px;
    width: 12px;
    > div {
      width: $ball-size;
      height: $ball-size;
      border-radius: 100%;
      background-color: white;
      animation-fill-mode: both;
      position: absolute;
      left: 0px;
      top: 0px;
      opacity: 0;
      margin: 0;
      animation: ball-scale-multiple 1s 0s linear infinite;
    }
  }

  @keyframes ball-scale-multiple {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    5% {
      opacity: 0.9;
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }
}
