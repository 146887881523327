@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.EmptyList {
  display: flex;
  flex-direction: column;
  width: 400px;
  margin: auto;
  text-align: center;
  margin-top: 200px;
  margin-bottom: 200px;

  img {
    margin: auto;
    max-width: 197px;
  }

  @include below(md) {
    width: 100%;
    margin-top: 100px;
    margin-bottom: 0;
  }

  p {
    @include typo-body-larger;
  }

  .button {
    margin: auto;
  }
}
