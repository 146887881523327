@import 'styles/global/colors';
@import 'styles/mixins/typography';

.browser {
  padding: 32px;
}

.CouponModal {
  .modal-content {
    padding: 32px;
    width: 700px;
    max-width: 700px;
  }

  .coupon-modal-form-name-wrapper {
    margin-bottom: 40px;
    margin-top: 20px;
  }

  .coupon-modal-form-code-wrapper {
    margin-bottom: 40px;
  }

  .coupon-modal-form-type-wrapper {
    margin-bottom: 40px;

    .coupon-modal-form-type-title {
      @include typo-label;
      margin-bottom: 20px;
    }

    p {
      @include typo-radio-text1;
    }

    .coupon-modal-form-type-edit {
      margin-bottom: 10px;

      p {
        margin: -15px 0 0 0;
      }
    }

    .coupon-modal-form-type-radio {
      display: flex;
      flex-direction: column;

      .coupon-modal-form-type-value {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 8px;
        cursor: pointer;

        .coupon-modal-form-type-radio-svg {
          height: 20px;
          width: 20px;
          margin-right: 10px;
        }

        .checked {
          fill: $colorPrimary;
        }

        .unchecked {
          fill: $colorTextSecondary;
        }
      }
    }
  }

  .coupon-modal-form-edit-discount-wrapper {
    margin-bottom: 40px;

    .coupon-modal-form-edit-discount-title {
      @include typo-label;
      margin-bottom: 5px;
    }

    .coupon-modal-form-edit-discount-text {
      @include typo-radio-text1;
    }
  }

  .coupon-modal-form-discount-value-wrapper {
    margin-bottom: 40px;
  }

  .coupon-modal-form-applies-to-wrapper {
    margin-bottom: 40px;

    .coupon-modal-form-applies-to-title {
      @include typo-label;
    }

    .coupon-modal-form-applies-to-radio {
      display: flex;
      flex-direction: column;

      .coupon-modal-form-applies-to-value {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 8px;
        cursor: pointer;

        p {
          @include typo-radio-text1;
        }

        .coupon-modal-form-applies-to-svg {
          height: 20px;
          width: 20px;
          margin-right: 10px;
        }

        .checked {
          fill: $colorPrimary;
        }

        .unchecked {
          fill: $colorTextSecondary;
        }
      }
    }

    .coupon-modal-form-applies-to-products-wrapper {
      padding-left: 30px;

      .coupon-modal-form-product-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;

        p {
          @include typo-radio-text1;
        }

        .coupon-modal-form-product-checkbox-svg {
          width: 18px;
          height: 18px;
          margin-right: 10px;
        }

        .checked {
          fill: $colorPrimary;
        }

        .unchecked {
          fill: $colorTextSecondary;
        }
      }
    }

    .coupon-modal-form-edit-applies-to-ul {
      padding-left: 20px;

      li {
        @include typo-p;
      }
    }

    .coupon-modal-form-edit-applies-to-value {
      margin-top: -15px;
    }
  }

  .coupon-modal-form-duration-select {
    margin-top: 20px;

    .coupon-modal-form-duration-title {
      @include typo-label;
    }

    .coupon-modal-form-edit-duration-value {
      @include typo-radio-text1;
      margin: -15px 0 0 0;
      text-transform: capitalize;
    }

    .BoonInputAddonSelect {
      border: 1px solid $colorBorder;
      box-sizing: border-box;
      border-radius: 3px;
      height: 46px;

      select {
        width: 100%;
      }
    }

    .coupon-modal-form-duration-help-text {
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 20px;
      color: $colorTextSecondary;
      margin-top: 7px;
      margin-bottom: 0;
    }
  }

  .coupon-modal-submit {
    width: 100%;
    margin-top: 40px;
  }

  .coupon-modal-edit-deactivate {
    width: 100%;
    margin-top: 5px;
  }
}
