@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/mixins/spacing';

.PhotoItem {
  margin-bottom: 16px;
  position: relative;
  background-color: black;
  border-radius: 18px;

  @include below(md) {
    margin-bottom: 8px;
  }

  &:hover {
    cursor: pointer;
  }

  .masonry-item-image {
    width: 100%;
    border-radius: 16px;
    opacity: 0.85;
    min-height: 150px;
  }

  .masonry-item-activity {
    position: absolute;
    bottom: 24px;
    padding-left: 24px;
    color: $colorWhite;
    font-size: 12px;
    display: flex;
    align-items: center;
    z-index: 1;
    @include below(md) {
      padding-left: 10px;
      bottom: 10px;
    }

    svg {
      fill: $colorWhite;
      margin-right: 5px;
      @include size(12px);
    }

    svg:nth-of-type(2) {
      margin-left: 10px;
    }
  }

  .masonry-item-photo-locked {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  .masonry-item-title {
    position: absolute;
    padding: 24px;
    font-size: 13px;
    color: $colorWhite;
    font-weight: bold;
    z-index: 1;
    @include below(md) {
      padding: 10px;
    }
  }

  .PostOptions {
    @include position(absolute, 18px 12px null null);
  }
}
