@mixin box-shadow-near {
  box-shadow: 0 0 2px 0 rgba(60, 64, 67, 0.02),
    0 2px 2px 0 rgba(60, 64, 67, 0.03);
}

@mixin box-shadow-far {
  box-shadow: 0 0 2px 0 rgba(60, 64, 67, 0.02),
    0 4px 12px 0 rgba(60, 64, 67, 0.06);
}

@mixin box-shadow-extra-far {
  box-shadow: 0 0 2px 0 rgba(60, 64, 67, 0.02),
    0 4px 12px 0 rgba(60, 64, 67, 0.06), 0 4px 50px 0 rgba(60, 64, 67, 0.06);
}

@mixin box-shadow-panel {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 2px 60px 0 rgba(0, 0, 0, 0.05);
}
