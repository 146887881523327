@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/helpers/cards';

.EventInsightsPage {
  .EventInsightsMenu {
    position: relative;
  }

  .event-insights-header-menu-button {
    float: right;
  }
}

.EventInsightsMenu {
  .event-insights-header-menu-button {
    cursor: pointer;
    fill: $colorTextMuted;

    &:hover {
      fill: $colorTextSecondary;
    }
  }

  .event-insights-header-menu-wrapper {
    position: absolute;
    margin-top: -20px;
    right: 35px;
  }

  .event-insights-header-menu {
    @include card-near;
    color: $colorTextPrimary;
    position: absolute;
    top: 25px;
    right: 0;
    min-width: 250px;
    padding: 14px;
    z-index: 1;
  }

  .event-insights-header-menu-list {
    margin: 0 0 0 -35px;

    li {
      cursor: pointer;
      list-style-type: none;
      padding: 8px 14px;

      &:hover {
        border-radius: 14px;
        background-color: $colorBorderLight;
      }

      a,
      a:hover {
        color: inherit;
        text-decoration: none;
      }
    }
  }
}
