@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/mixins/spinner';
@import 'styles/helpers/cards';

.PostCommentCompose {
  width: 100%;

  &.empty {
    .post-comment-compose-footer {
      margin-top: -35px;
    }
  }

  .post-comment-compose-avatar-wrapper {
    display: flex;
    align-items: center;
    height: 32px;
  }

  .post-comment-compose-avatar {
    @include size(32px);
    flex-shrink: 0;
    margin-right: 8px;
  }

  &.small {
    //padding-right: 30px;

    .post-comment-compose-avatar {
      @include size(24px);
    }
  }

  .post-comment-compose-wrapper {
    display: flex;
  }

  .post-comment-compose-message-wrapper {
    flex: 1;
  }

  .post-comment-compose-message {
    border-radius: 16px;
    min-height: 32px;

    .post-comment-compose-message-row {
      display: flex;
    }
  }

  .post-comment-compose-message-field {
    color: $colorBlack;
    width: 100%;
    resize: none;
    border: 1px solid rgba(white, 0.1);
    background-color: $colorInputFields;
    font-size: 14px;
    line-height: 16px;
    padding: 8px 14px;
    align-self: center;
    border-radius: 14px;

    &::placeholder {
      color: $colorNine !important;
    }

    &:focus {
      outline: none;
    }
  }

  .post-comment-compose-footer {
    display: flex;
    transition: margin-top 250ms ease-out;
    margin-top: 10px;
  }

  .post-comment-compose-submit {
    margin-left: auto;
  }

  .post-comment-compose-attachment-bar {
    display: flex;
    align-items: flex-start;
    margin-top: 2px;
    margin-left: 8px;

    .post-comment-compose-attachment-bar-attachment {
      position: relative;
      cursor: pointer;
      &,
      svg {
        @include size(24px);
      }

      display: flex;
      align-items: center;

      &:hover {
        opacity: 0.8;
      }

      svg {
        fill: $colorTextSecondary;
      }
    }

    .post-comment-compose-attachment-bar-attachment-photo {
      margin: 0;
    }

    .post-comment-compose-attachment-bar-attachment-photo-input {
      display: none;
    }
  }

  .photo-comment-compose-attachment-photo {
    position: relative;
    width: 33%;
    margin-top: 16px;
    padding-bottom: 5px;

    .photo-comment-compose-attachment-photo-image {
      border-radius: 4px;
      width: 100%;
    }

    .photo-comment-compose-attachment-photo-remove {
      cursor: pointer;
      @include position(absolute, 6px 6px null null);
      @include size(18px);
      fill: $colorTextPrimary;

      &:hover {
        fill: white;
      }
    }
  }

  .post-comment-compose-right {
    position: relative;

    .post-comment-compose-right-send {
      @include size(24px);
      fill: $colorTextSecondary;
      margin-left: 16px;
      cursor: pointer;

      &:not(.disabled):hover {
        opacity: 0.8;
      }

      &.disabled {
        opacity: 0;
      }
    }

    .Spinner {
      left: calc(50% + 8px);
    }
  }

  .post-comment-compose-message-field__suggestions {
    @include card-near;
    border-radius: 16px;

    .post-comment-compose-message-field__suggestions__list {
      padding: 8px !important;
    }

    .post-comment-compose-message-field__suggestions__item--focused {
      background-color: $colorInputFields;
      border-radius: 16px;
    }

    .post-comment-compose-message-field__suggestions-user {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 6px 12px;
    }

    .post-comment-compose-tag-suggestion-avatar {
      @include size(24px);
      flex-shrink: 0;
      margin-right: 8px;
    }
  }
}
