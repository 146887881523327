@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.PollBasicAddOption {
  display: flex;
  align-items: center;
  margin-top: 16px;

  .poll-basic-add-option-icon {
    fill: rgba($colorWhite, 0.4);
    flex-shrink: 0;
    margin: 0 10px 0 12px;
  }

  .poll-basic-add-option-input {
    flex: 1 1 auto;
    background-color: transparent;
    outline: none;
    border: none;
    color: $colorTextPrimary;

    &::placeholder {
      color: rgba($colorWhite, 0.4);
    }
  }

  .poll-basic-add-option-button {
    margin-left: 8px;

    &.hidden {
      visibility: hidden;
    }
  }
}
