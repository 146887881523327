@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.CreatorSetupMenu {
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
  padding-bottom: 1px;

  @include above(md) {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
  }

  .creator-setup-menu {
    display: flex;
    align-self: stretch;
    overflow-y: hidden;
    overflow-x: auto;
    height: 60px;
    box-sizing: border-box;

    .creator-setup-menu-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-self: stretch;
      color: #999999;
      line-height: 100%;
      position: relative;

      &.active {
        color: $colorTextPrimary;
        font-weight: 500;

        &:before {
          content: '';
          @include position(absolute, null 0 -1px 0);
          background-color: $colorPrimary;
          height: 3px;
          border-top-left-radius: 100px;
          border-top-right-radius: 100px;
        }
      }

      &.disabled {
        pointer-events: none;
      }

      &:not(.active) {
        opacity: 0.8;
      }

      &:hover {
        text-decoration: none;
        color: $colorBlack;
      }

      &:not(:first-child) {
        margin-left: 20px;
      }
    }
  }

  .creator-setup-menu-actions {
    flex-shrink: 0;

    @include above(md) {
      margin-left: auto;
    }
  }
}
