@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.SubscribePageActions {
  .subscribe-page-actions-pay,
  .subscribe-page-actions-cancel {
    width: 100%;
    margin-bottom: 8px;
  }

  .subscribe-page-actions-cancel {
    margin-top: 24px;
    display: flex;
    justify-content: center;
    text-decoration: underline;
  }

  .subscribe-page-actions-pay {
    margin-top: 32px;
  }
}
