@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/spinner';

.SettingsAccount {
  .settings-account-submit {
    width: 100%;
  }

  .BoonTextField {
    width: 100%;
    margin-bottom: 30px;
  }

  .settings-account-avatar-row {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    margin-top: 30px;

    .settings-account-avatar {
      @include size(120px);
    }

    .settings-account-avatar-upload {
      margin-left: 30px;
      margin-bottom: 0;
    }

    #settingsAvatarUpload {
      display: none;
    }
  }

  .settings-account-cancel-membership {
    display: block;
    text-align: center;
    margin: 60px auto 30px auto;
    color: $colorMuted;
  }

  .settings-account-terminate {
    display: block;
    text-align: center;
    margin: 30px auto;
    color: $colorMuted;
  }

  // TODO: Deduplicate
  .settings-avatar {
    display: flex;
    justify-content: center;
    position: relative;
    @include size(120px);
    cursor: pointer;

    .Avatar {
      width: 100%;
      border: 1px solid rgba(0, 0, 0, 0.1);
    }

    .settings-avatar-upload {
      @include position(absolute, null 7px 2px null);

      background-color: $colorWhite;
      box-shadow: 0 0 1px 0 rgba(60, 64, 67, 0.12),
        0 2px 4px 0 rgba(60, 64, 67, 0.12);

      svg {
        fill: $colorPrimary;
      }

      &:hover {
        svg {
          fill: darken($colorMuted, 10%);
        }
      }

      &:before {
        @include spinner($color: $colorMuted);
      }
    }
  }

  .settings-photo-button {
    @include size(36px);

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 100%;

    svg {
      @include size(24px);
      margin-top: 1px;
      transition: all 250ms ease-out;
      opacity: 1;
    }

    &:before {
      content: '';
      opacity: 0;
      transition: opacity 250ms ease-out;
    }

    &.loading {
      &:before {
        opacity: 1;
      }

      svg {
        opacity: 0;
      }
    }
  }
}
