@import 'styles/global/colors';
@import 'styles/mixins/typography';
@import 'styles/helpers/cards';

.ResetPasswordPage {
  background-color: white;
  padding-top: 60px;
  min-height: 100vh;

  .reset-password-page-form,
  .reset-password-page-success {
    @include card-near;
    padding: 48px;
  }

  .reset-password-page-success {
    text-align: center;

    svg {
      padding: 16px;
      width: 48px+16px;
      height: 48px+16px;
      border-radius: 100%;
      background-color: $colorBorder;
      margin-bottom: 16px;
    }

    .button {
      margin-top: 36px;
    }
  }

  .reset-password-page-title {
    @include typo-headline1;
    margin-bottom: 30px;
  }

  .reset-password-page-field {
    width: 100%;
    margin-bottom: 10px;
  }

  .reset-password-page-button {
    margin-top: 30px;
    width: 100%;
  }

  .reset-password-page-success-title {
    text-align: center;
    font-size: 24px;
    line-height: 1.25;
    font-weight: 800;
    margin-bottom: 48px;
  }
}
