@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/mixins/spacing';

.CreatorSetupPage {
  color: $colorTextPrimary;
  padding-bottom: 60px;
  background-color: $colorSurface;
  @include page-exchange-header-spacing;
  min-height: 100vh;

  .ProfileCompletion {
    font-size: 14px;
    @include below(md) {
      margin-top: 30px;
    }
  }

  .creator-setup-page-header {
    @include above(sm) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .creator-setup-page-title {
    @include typo-headline5;

    @include below(md) {
      margin-bottom: 15px;
    }
  }

  .creator-setup-page-actions {
    display: flex;

    > *:first-child {
      margin-right: 15px;
    }

    @include below(sm) {
      > * {
        flex: 1;
      }
    }
  }
}
