@import 'styles/global/colors';
@import 'styles/helpers/box';
@import 'styles/mixins/typography';
@import 'styles/helpers/cover';
@import 'styles/modules/bourbon';
@import 'styles/helpers/play-button';
@import 'styles/helpers/cards';
@import 'styles/helpers/badges';

.EventCard {
  @include card-near;
  cursor: pointer;
  width: 100%;
  position: relative;
  color: $colorTextPrimary;
  background-color: $colorSurface;
  transition: transform 250ms ease-in-out, box-shadow 250ms ease-in-out;

  .event-card-wrapper {
    border-radius: 16px;
  }

  .event-card-media,
  .event-card-media-inner,
  .Giffer {
    @include border-top-radius(15px);
  }

  &:hover {
    @include card-far;
    .Bookmark,
    .EventHostOptions {
      svg {
        filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.4));
      }
    }
  }

  .event-card-content-wrapper {
    padding: 16px 24px;
  }

  .event-card-media-wrapper {
    .event-card-media {
      background-position: center;
      background-size: cover;
      position: relative;
      cursor: pointer;
      background-color: $colorSurface;
      padding-top: (9 / 16) * 100%;

      .EventHostOptions {
        @include position(absolute, 15px 15px null null);
      }

      .event-card-archived {
        color: $colorWhite;
        font-size: 24px;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        display: flex;
        justify-content: center;
      }

      .event-card-media-bookmark-container {
        position: absolute;
        top: 15px;
        right: 20px;
        z-index: 2;

        &.bookmark-creator {
          right: 45px;
        }
      }

      .event-card-media-inner {
        @include position(absolute, 0 0 0 0);
        background-color: transparent;
        transition: 250ms background-color;

        .EventCountdown {
          height: 100%;
        }

        &.event-card-media-inner-dimmed {
          background-color: rgba(black, 0.1);
        }

        &.event-card-media-inner-extra-dimmed {
          background-color: rgba(black, 0.25);
        }

        &.event-card-media-inner-extra-extra-dimmed {
          background-color: rgba(black, 0.55);
        }

        .EventStatus {
          @include position(absolute, 15px null null 15px);
        }

        .event-card-free-badge {
          @include free-badge;
        }

        progress[value] {
          -webkit-appearance: none;
          appearance: none;
          width: 100%;
          height: 4px;
          position: absolute;
          bottom: 0;

          &::-webkit-progress-bar {
            background-color: $colorBorderLight;
          }

          &::-webkit-progress-value {
            background-color: var(--color-creator-primary);
          }
        }
      }
    }
  }

  .event-card-media-play-button {
    width: 60px;
    transition: opacity 200ms ease-out !important;
  }

  .EventStatus {
    border-radius: 2px;
  }

  .event-card-property {
    color: $colorWhite;
    padding: 2px 6px 2px 6px;

    font-size: 13px;
    border-radius: 2px;

    &:not(:first-child) {
      margin-left: 6px;
    }

    &.event-card-property-published {
      background-color: $colorGreen;
    }

    &.event-card-property-draft {
      background-color: $colorBlue;
    }
    &.event-card-property-test {
      background-color: $colorYellow;
      color: black;
    }
  }

  .event-card-seats {
    display: flex;

    .EventParticipants {
      margin-right: 10px;
    }

    .event-card-seats-amount {
      @include typo-small;
      line-height: 20px;
    }
  }

  .event-card-info {
    @include typo-small;
    color: #7f7f81;

    > * {
      &:not(:first-child) {
        &:before {
          content: '·';
          padding: 0 6px;
          display: inline-block;
        }
      }
    }
  }

  .event-card-title {
    word-break: break-word;
    hyphens: auto;
  }

  .event-card-host {
    @include position(absolute, 15px null null 15px);
    display: flex;

    .event-card-host-avatar {
      @include size(20px);
    }

    .event-card-host-name {
      @include typo-small;
      line-height: 20px;
      margin-left: 5px;
      text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    }

    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }

  .event-card-host-info {
    display: flex;
    align-items: center;

    .event-card-host-info-sales {
      @include typo-small;
    }

    .event-card-property {
      margin-right: 10px;
    }
  }

  .event-card-content {
    flex: 1;
    align-self: stretch;
  }

  .event-card-details {
    display: flex;

    .event-card-details-avatar {
      flex-shrink: 0;
      margin-right: 15px;
      @include size(40px);
    }

    .event-card-details-title {
      @include typo-small;
      flex: 1 1 auto;
      font-weight: 700;
      font-size: 14px;
    }

    .event-card-details-name {
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 13px;
      line-height: 1.1;
      margin-top: 5px;

      border-bottom: 2px solid rgba(#b3b8bd, 0.25);

      &:hover {
        text-decoration: none;
        color: inherit;

        border-bottom-color: $colorWhite;
      }
    }

    .event-card-details-left {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-right: 16px;

      .event-card-details-stats {
        flex-shrink: 0;
        display: flex;
        color: $colorTextSecondary;
        font-size: 13px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-top: 2px;

        > * {
          &:not(:first-child) {
            &:before {
              content: '·';
              padding: 0 6px;
              display: inline-block;
            }
          }
        }
      }
    }

    .event-card-details-right {
      flex-shrink: 0;
      margin-left: auto;
    }

    &:not(.large) {
      .event-card-details-title {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &.large {
      .event-card-details-avatar {
        @include size(50px);
      }

      .event-card-details-title {
        @include typo-headline3;
        margin-bottom: 4px;
      }
    }
  }

  &.playing {
    .event-card-media-play-button {
      opacity: 0;
    }

    .event-card-media-inner {
      &:not(.event-card-media-inner-dimmed) {
        background-color: transparent !important;
      }
    }
  }
}
