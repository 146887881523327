@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.PostLikesModal {
  .modal-content {
    background-color: $colorSurface;
    position: relative;

    @include above(md) {
      width: 300px;
      height: 400px;
      border-radius: 16px;
      overflow: hidden;

      display: flex;
      flex-direction: column;
    }

    @include below(md) {
      width: 100%;
      min-height: 100%;
    }
  }

  .named-animated-modal-content {
    flex: 1 1 auto;
    overflow: hidden;
  }

  .post-likes-modal-list {
    position: relative;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.LikeCard {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  .like-card-avatar {
    @include size(32px);
    margin-right: 16px;
  }

  .like-card-name {
  }

  .like-card-date {
    @include typo-small;
    color: $colorTextSecondary;
  }
}
