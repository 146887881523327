@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.PostAttachmentModal {
  .LocalHeader {
    color: $colorTextPrimary;
    box-shadow: none;
  }

  .modal-content {
    min-height: 100vh;
    background-color: $colorSurface;
    display: flex;
    flex-direction: column;
  }

  .page-modal-close {
    position: absolute;
    border: 1px solid $colorBorder;
    background-color: $colorSurface;

    &:hover {
      border-color: $colorTextPrimary;
      .page-modal-close-icon {
        fill: $colorTextPrimary;
      }
    }

    @include below(sm) {
      display: none !important;
    }
  }

  @include above(md) {
    .modal-content {
      height: 100vh;
      overflow: hidden;
    }

    .PostAttachmentView,
    .post-attachment-view-wrapper {
      height: 100%;
    }

    .post-attachment-view-post {
      border-left: 1px solid $colorBorderLight;
      overflow-y: auto;
    }
  }
}
