@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/mixins/spacing';
@import 'styles/helpers/box';

.CreatorSettingsPage {
  color: $colorTextPrimary;
  padding-bottom: 60px;
  background-color: $colorSurface;
  @include page-exchange-header-spacing;
  position: relative;
  min-height: 100vh;

  .narrow-container {
    .creator-settings-page-title {
      color: $colorBlack;
      font-weight: bold;
      font-size: 32px;
      line-height: 64px;
      letter-spacing: -0.6px;
      margin-bottom: 20px;
    }
  }

  .creator-settings-section {
    @include box;
    padding: 30px;
    margin-bottom: 30px;

    a:not(.button) {
      text-decoration: underline;

      &:hover {
        color: #999;
      }
    }

    .creator-settings-section-header {
      @include typo-headline5;
      margin-bottom: 30px;
    }
  }

  .CategorySelector {
    .category-selector-item {
      border-bottom: 1px solid $colorBorderLight;
      padding: 10px 0;
      label {
        margin-bottom: 0;
      }
    }

    .CategoryOptions svg {
      transform: rotate(90deg);
    }

    .category-selector-add {
      margin-top: 14px;
    }
  }
}
