@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/helpers/box';
@import 'styles/helpers/cover';

.UserAboutPage {
  border-radius: 16px;
  color: $colorBackgroundBlack;
  //height: 517px;

  .user-about-box-media {
    @include below(sm) {
      margin-left: -24px;
      margin-right: -24px;
    }
  }

  .user-about-box-cover-wrapper {
    max-height: 244px;
  }

  .user-about-box-cover {
    @include border-top-radius(16px);
    overflow: hidden;
    position: relative;
    background-position: center;
    background-size: cover;
    background-color: black;
    cursor: pointer;
    width: 100%;
    aspect-ratio: 16/9;

    .user-about-box-cover-play-button {
      @include size(60px);
    }
  }

  .user-about-box-container {
    margin-top: 40px;
    background: white;
    @include border-bottom-radius(16px);

    &:only-child {
      border-radius: 16px;
    }

    &.no-video {
    }
  }

  .user-about-box-video {
    background-color: $colorBlack;
    video {
      max-height: 80vh;
      background-color: $colorBlack;
    }
  }

  .user-about-box-info {
    flex: 1 1;
    overflow: hidden;

    .user-about-box-title {
      @include typo-headline4;
      margin-bottom: 10px;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      @include above(lg) {
        @include typo-headline3;
      }
    }

    .user-about-box-description {
      a {
        text-decoration: underline;
        text-underline-position: under;

        &:hover {
          color: inherit;
          opacity: 0.8;
        }
      }
    }

    .UserSocial {
      margin-top: 24px;
      position: relative;
    }
  }
}
