@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/helpers/cards';

.InvoicesPage {
  color: $colorTextPrimary;
  padding-bottom: 60px;

  .invoices-page-title {
    @include typo-headline2;
    margin-bottom: 30px;
  }

  .invoices-page-item {
    @include card-near;
    display: flex;
    background-color: $colorSurface;
    align-items: center;
    padding: 10px 15px;
    border-radius: 16px;

    &:not(:last-child) {
      margin-bottom: 15px;
    }

    .invoices-page-item-name {
      flex: 1 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .invoices-page-item-date {
      flex: 0 0 125px;
      font-weight: 800;
    }

    .invoices-page-item-download {
      flex: 0 0 175px;
      text-align: right;
      display: flex;
      justify-content: flex-end;

      svg {
        fill: $colorBlack;
        @include size(14px);
      }
    }

    @include below(sm) {
      flex-wrap: wrap;
      .invoices-page-item-name {
        text-align: right;
      }

      .invoices-page-item-download {
        flex: 0 0 100%;
        margin-top: 10px;

        .button {
          width: 100%;
        }
      }
    }
  }
}
