@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/mixins/spinner';
@import 'styles/helpers/cards';

.CreatorMembershipUpsertProduct {
}

.CreatorMembershipUpsertProductModal {
  @include above(md) {
    padding: 32px 0;
  }

  .modal-content {
    @include above(lg) {
      width: 700px;
    }
  }
}

.CreatorMembershipUpsertProductForm {
  .creator-membership-upsert-product-form-submit {
    margin-left: auto;
    margin-top: 15px;

    @include below(sm) {
      width: 100%;
      margin-top: 30px;
    }
  }

  .BoonFormGroup {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  .creator-membership-upsert-product-form-photo-button {
    @include size(36px);

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 100%;

    svg {
      @include size(24px);
      margin-top: 1px;
      transition: all 250ms ease-out;
      opacity: 1;
    }

    &:before {
      content: '';
      opacity: 0;
      transition: opacity 250ms ease-out;
    }

    &.loading {
      &:before {
        opacity: 1;
      }

      svg {
        opacity: 0;
      }
    }
  }

  .creator-membership-upsert-product-form-cover {
    position: relative;
    cursor: pointer;

    display: flex;
    justify-content: center;

    margin: 24px 0;

    .creator-membership-upsert-product-form-cover-image {
      display: block;
      position: relative;
      clip-path: url(#boonCircularSquareClipPath);
      background-color: $colorBorderLight;
      @include size(88px);
      background-position: center;
      background-size: cover;
    }

    .creator-membership-upsert-product-form-cover-upload {
      @include position(absolute, null null 0px 50%);
      transform: translate(-50%, 50%);

      backdrop-filter: saturate(180%) blur(20px);

      background: white;
      box-shadow: 0 0 2px 0 rgba(60, 64, 67, 0.03),
        0 2px 8px 0 rgba(60, 64, 67, 0.03);

      border-radius: 50%;

      svg {
        fill: $colorTextSecondary;
      }

      &:hover {
        svg {
          fill: $colorTextMuted;
        }
      }

      &:before {
        @include spinner($color: $colorTextPrimary);
      }
    }
  }

  #membershipCover {
    display: none;
  }

  .creator-membership-upsert-product-pricing {
    margin: 16px 0; // Temp
    @include card-near();
    padding: 16px;

    display: flex;
    flex-wrap: wrap;

    @include below(md) {
      justify-content: space-between;
    }

    .creator-membership-upsert-product-pricing-name {
      display: flex;
      align-items: center;
      flex: 0 1 200px;

      font-weight: 700;
      margin-right: 16px;

      label {
        margin-bottom: 0;
      }

      @include below(md) {
        flex-basis: 150px;
      }
    }

    .creator-membership-upsert-product-pricing-adjustment {
      flex: 0 1 140px;
      overflow: hidden;

      .BoonTextField .boon-text-field-wrapper {
        margin-top: 0;
      }

      @include below(xs) {
        flex-basis: 120px;
      }
    }

    .creator-membership-upsert-product-pricing-currency,
    .creator-membership-upsert-product-pricing-discount {
      display: flex;
      align-items: center;
      height: 100%;
      background-color: #fafafa;
      padding: 0 16px;

      @include below(sm) {
        padding: 0 12px;
      }
    }

    .creator-membership-upsert-product-pricing-result {
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include above(lg) {
        flex: 1 1 auto;
        border-left: 1px solid $colorBorderLight;
        margin-left: 16px;
        padding-left: 16px;
      }

      @include below(md) {
        flex: 0 0 100%;
        border-top: 1px solid $colorBorderLight;
        margin-top: 16px;
        padding-top: 16px;
      }
    }

    &.inactive {
      border-color: rgba($colorBorderLight, 0.7);

      .creator-membership-upsert-product-pricing-adjustment,
      .creator-membership-upsert-product-pricing-result {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }

  .creator-membership-upsert-product-price-helper {
    color: $colorTextSecondary;
    @include typo-small;
    margin: 16px 0 32px 0;
  }
}
