@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/helpers/cards';

.CategoryCardContainer {
  position: relative;
  margin: 0 auto 16px auto;

  &.category-card-container-activity {
    max-width: 630px;
  }

  .category-card-container-button-group {
    display: flex;
    justify-content: space-between;
  }

  .category-card-container-active-title {
    @include typo-headline5;
    margin-top: 16px;
  }

  .category-card-container-button {
    color: $colorPrimary !important;
    background-color: lighten($colorPrimary, 45%) !important;
    border-color: lighten($colorPrimary, 45%) !important;
    max-width: 100%;
    transition: all 0.5s;

    .emoji {
      font-family: emoji;
      padding-right: 8px;
    }

    .name {
      padding-right: 8px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    svg {
      transition: fill 0.5s;
      fill: $colorTextSecondary;
    }

    &:hover {
      background-color: lighten($colorPrimary, 40%) !important;
      border-color: lighten($colorPrimary, 40%) !important;
      svg {
        fill: $colorTextPrimary;
      }
    }
  }

  .category-card-container-categories {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 16px;
    margin-bottom: 32px;

    @include above(md) {
      grid-template-columns: repeat(3, 1fr);
    }

    .CategoryCard {
      margin-left: 0;
      margin-top: 16px;
    }
  }

  &.category-card-container-videos {
    .category-card-container-categories {
      grid-template-columns: repeat(2, 1fr);

      @include above(md) {
        grid-template-columns: repeat(6, 1fr);
      }
    }
  }
}

.modal.CategoriesModal {
  .modal-content {
    width: 100%;

    @include above(sm) {
      width: 465px;
    }

    @include above(md) {
      width: 700px;
    }

    .categories-modal-container {
      display: grid;
      column-gap: 16px;
      row-gap: 16px;
      grid-template-columns: repeat(2, 1fr);
      margin-bottom: 20px;

      @include above(md) {
        grid-template-columns: repeat(3, 1fr);
      }

      .CategoryCard {
        margin-bottom: 16px;
        margin-left: 0;
        height: 100%;

        .category-card-title {
          max-width: 160px;
          @include below(md) {
            max-width: 120px;
          }
        }
      }
    }
  }
}
