@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.CreatorMenu {
  .creator-menu-item {
    font-size: 14px;
    display: block;
    color: $colorTextPrimary;
    position: relative;
    padding: 10px 0 10px 30px;

    svg {
      fill: $colorTextPrimary;
      @include size(24px);
      margin-right: 15px;
    }

    &:hover {
      border-radius: 9999px;
      color: $colorTextPrimary;
      text-decoration: none;
      background-color: $colorBorderLight;
    }
  }

  &.noActive {
    .creator-menu-item {
      color: $colorTextPrimary;
      svg {
        fill: $colorTextPrimary;
      }
    }
  }

  &:not(.noActive) {
    .creator-menu-item {
      &.active {
        position: relative;
        color: $colorTextPrimary;
        svg {
          fill: $colorTextPrimary;
        }

        &:before {
          content: '';
          @include position(absolute, 0 null 0 0);
          width: 3px;
          background-color: $colorSurface;
          border-radius: 9999px;
        }
      }
    }
  }
}
