@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/mixins/spacing';

.InviteAppliedBanner {
  height: 77px;

  .invite-applied-banner-container {
    color: $colorWhite;
    background-color: $colorPrimary;
    @include position(fixed, #{$headerHeight} 0 null 0);
    z-index: $zBelowHeader;

    padding: 16px 24px;
    display: flex;

    &.error {
      color: $colorBlack;
      background-color: $colorWarning;

      svg {
        margin-right: 12px;
      }
    }
  }

  &.error {
    height: 56px;
  }

  @include below(sm) {
    padding: 5px 15px;
  }

  .invite-applied-banner-avatar {
    @include size(40px);
    min-width: 40px;
  }

  .invite-applied-banner-headline {
    font-weight: bold;
  }

  .invite-applied-banner-subtext {
    font-size: 14px;
  }

  .invite-applied-banner-info {
    margin-left: 20px;
  }
}
