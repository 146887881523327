@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/helpers/cards';

.UploadForm {
  padding-top: 30px;

  .upload-form-section {
    @include card-near;
    @include card-mobile-stretch;
    padding: 30px;
    margin-bottom: 30px;

    .BoonFormGroup {
      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }

    .upload-form-section-title {
      @include typo-headline4;
      margin-bottom: 30px;
    }
  }

  .upload-form-actions {
    display: flex;
    justify-content: flex-end;

    button {
      @include below(sm) {
        width: 100%;
      }
    }
  }

  .upload-form-categories {
    .boon-form-group-content {
      overflow: visible;
    }
  }

  #uploadCoverUpload {
    display: none;
  }
}
