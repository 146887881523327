@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.PurchasesPage {
  color: $colorTextPrimary;
  padding-bottom: 60px;

  .purchases-page-title {
    @include typo-headline2;
    margin-bottom: 30px;
  }

  .purchases-page-events {
    margin: 50px 0;
  }
}
