@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/helpers/cards';

.ProfileCompletion {
  color: $colorTextPrimary;
  padding: 25px 20px;
  @include card-near;

  @include above(lg) {
    @include position(sticky, $headerHeight + 30px null null null);
  }

  .profile-completion-section {
    .profile-completion-section-header {
      @include typo-headline6;
      margin-bottom: 20px;
    }
  }

  .profile-completion-criteria {
    .profile-completion-criteria-header {
      display: flex;
      align-items: center;
    }

    .profile-completion-criteria-action {
      display: block;
      @include typo-small;
      color: $colorTextSecondary;
      text-decoration: underline;
      text-underline-position: under;
      margin-left: 24px + 15px;

      &:hover {
        color: $colorPrimary;
      }
    }

    &:not(:last-child) {
      margin-bottom: 15px;
    }

    svg {
      fill: $colorMuted;
      margin-right: 15px;
      @include size(24px);
    }

    &.profile-completion-criteria-checked {
      svg {
        fill: $colorPrimary;
      }
    }
  }

  &[highlight='true'] {
    animation: highlight 500ms ease-out;
    animation-delay: 500ms;
  }

  &.tried-launching {
    .profile-completion-criteria:not(.profile-completion-criteria-checked) {
      color: $colorRed;
    }
  }
}

@keyframes highlight {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: rgba(var(--color-creator-primary-rgb), 0.2);
  }
  100% {
    background-color: transparent;
  }
}
