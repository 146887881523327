@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.InsightsChartWidget {
  height: 380px;

  .insights-chart-widget-error {
    @include position(absolute, 50% 0 0 50%);
    transform: translate(-50%, -50%);
    text-align: center;
    height: 10%;
  }

  .insights-chart-widget-error-icon {
    @include size(30px);
    fill: $colorBlack;
  }
}
