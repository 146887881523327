@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.SubscriptionUnpaidBanner {
  width: 100%;
  padding: 0 15px;

  @include above(md) {
    height: 40px;
  }

  @include below(sm) {
    padding: 5px 15px;
  }

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba($colorError, 0.5);
  color: $colorWhite;
  flex-wrap: wrap;
  text-decoration: underline;
  text-underline-position: under;

  a:hover {
    opacity: 0.8;
    color: inherit;
    text-decoration: underline;
  }
}
