@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.AvailabilityPanel {
  .availability-panel-row {
    margin-top: 24px;
    margin-right: 15px;
    margin-left: 15px;

    &.hide {
      display: none;
    }
  }

  .availability-panel-content {
    hr {
      border: none;
      height: 1px;
      background-color: $colorBorderLight;
    }

    .availability-item {
      margin-top: 16px;

      .availability-panel-item-description {
        @include typo-small;
        color: $colorTextSecondary;
      }

      .availability-panel-item-seats {
        margin-left: 15px;
        .boon-text-field-label {
          font-weight: 600;
        }
      }
    }
  }

  .availability-panel-header {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    .availability-panel-header-title {
      @include typo-headline5;
    }
  }

  .BoonTextField {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  .availability-section + .availability-section {
    @include below(sm) {
      margin-top: 25px;
    }
  }

  .availability-section-header {
    font-weight: 600;
    margin-bottom: 6px;
  }

  .availability-section-subheader {
    color: $colorNine;
    @include typo-small;
    margin-bottom: 15px;
  }

  .availability-panel-indent {
    margin-left: 11px;
    padding-left: 20px;
  }

  .product-info {
    .plan-info {
      color: $colorNine;
    }
  }

  .BoonTextField {
    max-width: 250px;
  }
}
