@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/shadows';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.EventHostHeader {
  height: 46px;
  display: flex;
  align-items: center;

  padding: 0 15px 3px 15px;
  color: $colorTextPrimary;

  .container {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .header-status-title {
    font-weight: 800;
    margin-right: 5px;
  }

  &.draft {
    background-color: $colorPrimary;
    color: $colorWhite;
  }

  &.live {
    background-color: rgba($colorLive, 0.4);
    .header-status-title {
      color: $colorLive;
    }
  }

  &.completed {
    background-color: rgba($colorGreen, 0.4);
    .header-status-title {
      color: $colorGreen;
    }
  }

  .event-card-property {
    color: $colorWhite;
    padding: 2px 6px 2px 6px;
    margin-right: 10px;

    font-size: 13px;
    border-radius: 2px;

    &.event-card-property-test {
      background-color: $colorYellow;
      color: black;
    }
  }

  .header-controls {
    margin-left: auto;

    .header-controls-control {
      font-weight: 800;
      transition: opacity 0.2s ease-in-out;

      &:not(:last-child) {
        margin-right: 8px;
      }

      &:hover {
        opacity: 0.6;
        color: inherit;
        text-decoration: none;
      }
    }
  }
}
