/*
  100: Ultrathin
  200: Thin
  300: Light
  400: Book
  500: Medium
  600: Semibold
  700: Bold
  800: Extrabold
  900: Black
 */

@font-face {
  font-family: 'Sharp Sans Display No2';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url('../../assets/font/SharpSansDispNo2-UltraThin.woff2') format('woff2'),
    url('../../assets/font/SharpSansDispNo2-UltraThin.woff') format('woff');
}
@font-face {
  font-family: 'Sharp Sans Display No2';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url('../../assets/font/SharpSansDispNo2-UltraThinObl.woff2')
      format('woff2'),
    url('../../assets/font/SharpSansDispNo2-UltraThinObl.woff') format('woff');
}

@font-face {
  font-family: 'Sharp Sans Display No2';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url('../../assets/font/SharpSansDispNo2-Thin.woff2') format('woff2'),
    url('../../assets/font/SharpSansDispNo2-Thin.woff') format('woff');
}
@font-face {
  font-family: 'Sharp Sans Display No2';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url('../../assets/font/SharpSansDispNo2-ThinObl.woff2') format('woff2'),
    url('../../assets/font/SharpSansDispNo2-ThinObl.woff') format('woff');
}

@font-face {
  font-family: 'Sharp Sans Display No2';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../../assets/font/SharpSansDispNo2-Light.woff2') format('woff2'),
    url('../../assets/font/SharpSansDispNo2-Light.woff') format('woff');
}
@font-face {
  font-family: 'Sharp Sans Display No2';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url('../../assets/font/SharpSansDispNo2-LightObl.woff2') format('woff2'),
    url('../../assets/font/SharpSansDispNo2-LightObl.woff') format('woff');
}

@font-face {
  font-family: 'Sharp Sans Display No2';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../../assets/font/SharpSansDispNo2-Book.woff2') format('woff2'),
    url('../../assets/font/SharpSansDispNo2-Book.woff') format('woff');
}
@font-face {
  font-family: 'Sharp Sans Display No2';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('../../assets/font/SharpSansDispNo2-BookObl.woff2') format('woff2'),
    url('../../assets/font/SharpSansDispNo2-BookObl.woff') format('woff');
}

@font-face {
  font-family: 'Sharp Sans Display No2';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../../assets/font/SharpSansDispNo2-Medium.woff2') format('woff2'),
    url('../../assets/font/SharpSansDispNo2-Medium.woff') format('woff');
}
@font-face {
  font-family: 'Sharp Sans Display No2';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url('../../assets/font/SharpSansDispNo2-MediumObl.woff2') format('woff2'),
    url('../../assets/font/SharpSansDispNo2-MediumObl.woff') format('woff');
}

@font-face {
  font-family: 'Sharp Sans Display No2';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../../assets/font/SharpSansDispNo2-Semibold.woff2') format('woff2'),
    url('../../assets/font/SharpSansDispNo2-Semibold.woff') format('woff');
}
@font-face {
  font-family: 'Sharp Sans Display No2';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url('../../assets/font/SharpSansDispNo2-SemiboldObl.woff2')
      format('woff2'),
    url('../../assets/font/SharpSansDispNo2-SemiboldObl.woff') format('woff');
}

@font-face {
  font-family: 'Sharp Sans Display No2';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../../assets/font/SharpSansDispNo2-Bold.woff2') format('woff2'),
    url('../../assets/font/SharpSansDispNo2-Bold.woff') format('woff');
}
@font-face {
  font-family: 'Sharp Sans Display No2';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url('../../assets/font/SharpSansDispNo2-BoldObl.woff2') format('woff2'),
    url('../../assets/font/SharpSansDispNo2-BoldObl.woff') format('woff');
}

@font-face {
  font-family: 'Sharp Sans Display No2';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url('../../assets/font/SharpSansDispNo2-Extrabold.woff2') format('woff2'),
    url('../../assets/font/SharpSansDispNo2-Extrabold.woff') format('woff');
}
@font-face {
  font-family: 'Sharp Sans Display No2';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url('../../assets/font/SharpSansDispNo2-ExtraboldObl.woff2')
      format('woff2'),
    url('../../assets/font/SharpSansDispNo2-ExtraboldObl.woff') format('woff');
}

@font-face {
  font-family: 'Sharp Sans Display No2';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('../../assets/font/SharpSansDispNo2-Black.woff2') format('woff2'),
    url('../../assets/font/SharpSansDispNo2-Black.woff') format('woff');
}
@font-face {
  font-family: 'Sharp Sans Display No2';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url('../../assets/font/SharpSansDispNo2-BlackObl.woff2') format('woff2'),
    url('../../assets/font/SharpSansDispNo2-BlackObl.woff') format('woff');
}
