@import 'styles/global/colors';
@import 'styles/modules/bourbon';

.input-label {
  font-size: 16px;
  font-weight: 800;
  text-transform: uppercase;
  float: left;
}

.input-label-value,
.input-label-value-right {
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  color: $colorMuted;
}

.input-label-value-right {
  float: right;
}

.input-error {
  font-size: 12px;
  font-weight: 400;
  color: $colorAlarm;
  margin-bottom: 15px;
}

.MuiInputBig {
  .MuiFormLabel-root {
    &:not(.MuiInputLabel-shrink) {
      font-size: 24px;
    }
  }

  .MuiInput-input {
    font-size: 24px;
  }
}

.boon-checkbox,
.boon-dark-checkbox {
  opacity: 0;
  width: 0;

  & + label {
    position: relative;
    cursor: pointer;
    padding-left: 20px + 10px;

    // CSS checkbox
    &:before {
      content: '';
      display: inline-block;
      @include position(absolute, 1px null null 0);
      @include size(20px);
      border-radius: 3px;

      border: 2px solid $colorMuted;
    }

    // In-CSS checkmark hack
    &:after {
      content: '';
      @include position(absolute, 8px null null 9px);
      top: 5px;
      left: 8px;

      width: 5px;
      height: 10px;

      transform: rotate(45deg);

      border-width: 2px;
      border-style: solid;
      border-color: #fff;
      border-top: 0;
      border-left: 0;
    }
  }
  &:checked + label {
    &:after {
      display: block;
    }
  }
  &:disabled + label {
    opacity: 0.5;
  }
}

.boon-checkbox {
  & + label {
    &:before {
      border: 2px solid $colorMuted;
    }

    // In-CSS checkmark hack
    &:after {
      border-color: #fff;
    }
  }

  &:checked + label {
    &:before {
      border: $colorPrimary;
      background: $colorPrimary;
    }
  }

  &:focus + label:before {
    box-shadow: 0 0 0 2px rgba($colorWhite, 0.05);
  }
}

.boon-dark-checkbox {
  & + label {
    &:before {
      border: 2px solid rgba($colorBlack, 0.25);
    }

    // In-CSS checkmark hack
    &:after {
      border-color: transparent;
    }
  }

  &:checked + label {
    &:after {
      border-color: $colorBlack;
    }
  }

  &:focus + label:before {
    box-shadow: 0 0 0 2px rgba($colorBlack, 0.05);
  }
}

.boon-dark-radio {
  $size: 20px;
  $borderSize: 2px;
  $dotSize: 10px;

  &:checked,
  &:not(:checked) {
    position: absolute;
    left: -9999px;
  }
  & + label {
    position: relative;
    padding-left: $size + 10px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      @include size($size);
      border: $borderSize solid $colorTextPrimary;
      border-radius: 100%;
    }

    &:after {
      content: '';
      @include size($dotSize);
      background: $colorPrimary;
      position: absolute;
      top: $size / 2 - $dotSize / 2;
      left: $size / 2 - $dotSize / 2;
      border-radius: 100%;
      transition: all 0.2s ease;
    }
  }
  &:not(:checked) + label {
    &:after {
      opacity: 0;
      transform: scale(0);
    }
  }

  &:checked + label {
    &:after {
      opacity: 1;
      transform: scale(1);
    }
  }
}

.boon-select {
  display: block;
  margin-bottom: 15px;
  width: 100%;

  appearance: none;
  border: none;
  outline: none;

  position: relative;
  background-color: $colorSurface;
  border: 1px solid $colorBorder;
  padding: 8px 15px;
  border-radius: 4px;
  font-size: 20px;
  background-image: url('../../assets/svg/ChevronDownSvg.svg');
  background-size: 24px;
  background-position: calc(100% - 16px) center;
  background-repeat: no-repeat;
}

.boon-dark-select {
  display: block;
  margin-bottom: 15px;
  width: 100%;

  appearance: none;
  border: none;
  outline: none;

  position: relative;
  background-color: transparent;
  color: $colorBlack;
  border: 1px solid $colorBorder;
  padding: 8px 15px;
  border-radius: 4px;
  font-size: 16px;
  background-image: url('../../assets/svg/ChevronDownSvg.svg');
  background-size: 24px;
  background-position: calc(100% - 16px) center;
  background-repeat: no-repeat;

  &:disabled {
    opacity: 0.3;
  }
}

.boon-dark-select-label {
  @include typo-label;
  color: $colorBlack;
  margin-bottom: 10px;
}
