@import 'styles/global/colors';
@import 'styles/global/variables';
@import 'styles/mixins/shadows';
@import 'styles/modules/bourbon';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.InfoTooltip {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  $iconSize: 18px;
  $arrowSize: 10px;
  position: relative;
  color: $colorBlack;

  &.open {
    .info-tooltip-box {
      display: block;
    }
  }

  &.left {
    .info-tooltip-box {
      @include position(absolute, 50% ($iconSize + $arrowSize + 5px) auto auto);
      transform: translateX(0) translateY(-50%);

      &:before {
        @include position(absolute, 50% (2 * -$arrowSize) auto auto);
        transform: rotate(90deg) translateX(-50%);
      }
    }
  }

  .info-tooltip-icon {
    @include size($iconSize);
    cursor: pointer;

    fill: rgba($colorBlack, 0.7);
    &:hover {
      fill: rgba($colorBlack, 0.5);
    }
  }

  .info-tooltip-box {
    @include position(absolute, ($iconSize + $arrowSize + 10px) null null 50%);
    background-color: $colorWhite;
    padding: 15px;
    text-align: center;
    transform: translateX(-50%);
    z-index: 3;
    filter: drop-shadow(rgba(0, 0, 0, 0.1) 0 4px 6px);
    display: none;

    &:before {
      @include position(absolute, null null 100% 50%);
      border: solid transparent;
      content: ' ';
      height: 0;
      width: 0;
      pointer-events: none;
      border-bottom-color: $colorWhite;
      border-width: $arrowSize;
      margin-left: -$arrowSize;
    }
  }
}
