@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.CreatorSettingsPayout {
  .BoonTextField {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  .CreatorSettingsPayoutAccount {
    margin-bottom: 30px;
  }

  .creator-settings-payout-referral {
    .creator-settings-payout-referral-value {
      margin-top: 15px;
      color: #999;

      .creator-settings-payout-referral-value-code {
        color: $colorTextPrimary;
      }
    }
  }

  .BusinessInfoBox {
    .business-info-box-description {
      font-weight: 800;
      margin-bottom: 15px;
    }

    .boon-dark-checkbox {
      margin-bottom: 30px;
    }

    .BoonTextField,
    .boon-dark-select {
      margin-bottom: 15px;
    }

    .button {
      margin-top: 15px;
    }
  }
}
