@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/mixins/spacing';
@import 'styles/mixins/shadows';

.CreatorPageHero {
  margin-bottom: 64px;

  .creator-page-hero-banner {
    @include page-behind-header;
    padding-top: $headerHeight;
    padding-bottom: 120px;
    background-color: $colorAlternateBackground;

    @include above(lg) {
      padding-top: 120px;
    }

    .creator-page-hero-banner-container {
      @include above(lg) {
        display: flex;
        flex-direction: row-reverse;
      }
    }

    .creator-page-hero-banner-media {
      @include above(lg) {
        flex: 1 1 auto;
      }

      @include below(lg) {
        margin: 0 (-$containerSpacing) 24px (-$containerSpacing);
        overflow: hidden;
      }

      .creator-page-hero-banner-media-image {
        width: 100%;

        @include below(lg) {
          display: block;
          width: 95%;
          min-height: 205px;
          margin: 0 auto;
        }
      }
    }

    .creator-page-hero-banner-content {
      @include above(lg) {
        padding-top: 72px;
        width: 500px;
        padding-right: 24px;
        flex-shrink: 0;
      }
    }

    .creator-page-hero-banner-arrow {
      @include box-shadow-near;
      border-radius: 50%;
      background-color: $colorSurface;
      transition: box-shadow 250ms ease-out;

      @include size(56px);
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      cursor: pointer;

      @include below(lg) {
        display: none;
      }

      &:hover {
        @include box-shadow-extra-far;
      }

      svg {
        fill: $colorTextPrimary;
      }
    }
  }

  .creator-page-hero-title {
    @include typo-display5;
    margin-bottom: 24px;
    line-height: 1.1;

    @include above(lg) {
      @include typo-display3;
      margin-bottom: 32px;
    }
  }

  .creator-page-hero-subtitle {
    font-family: $fontFamilyBoon;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 20px;

    @include above(lg) {
      font-size: 24px;
      margin-bottom: 40px;
    }
  }

  .creator-page-hero-actions {
    display: flex;

    @include above(md) {
      > .button:not(:first-child) {
        margin-left: 8px;
      }
    }

    @include below(sm) {
      flex-direction: column;

      > .button {
        width: auto;
      }

      > .button:not(:first-child) {
        margin-top: 16px;
      }
    }
  }

  .creator-page-hero-product {
    position: relative;
    transform: translateY(-150px);

    @include below(sm) {
      margin-bottom: -120px;
    }

    .creator-page-hero-product-image {
      width: 100%;
      @include box-shadow-extra-far;
      border-radius: $borderRadiusLarge;
    }

    .creator-page-hero-product-image-alternate {
      @include position(absolute, null 0 0 null);
      transform: translate(-25px, 45px);

      @include box-shadow-extra-far;
      border-radius: $borderRadiusLarge;

      width: 100px;

      @include above(lg) {
        transform: translate(72px, 120px);
        width: 260px;
      }
    }
  }
}
