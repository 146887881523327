@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/helpers/cards';

.DocumentItem {
  @include card-near;
  margin-top: 16px;

  .document-item-type {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    background-color: $colorSurface;

    img {
      height: 75%;
    }
  }

  .document-item-file-ext {
    position: absolute;
    text-transform: uppercase;
    font-weight: 800;
    margin-top: 46px;
  }

  .document-item-content {
    position: relative;
    height: 64px;
    display: flex;
    flex-direction: row;
    padding: 14px;
  }

  .document-item-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  .document-item-title {
    @include typo-small;
    font-weight: 700;
    font-size: 14px;
    width: 60%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
  }

  .document-item-numbers {
    @include typo-small;
    display: flex;
    color: $colorTextSecondary;
    margin-top: 2px;
  }

  .document-item-download-button {
    position: absolute;
    right: 16px;
    bottom: 12px;
  }
}
