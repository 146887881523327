@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.BrowserSupportBanner {
  padding: 8px 15px 10px 15px;
  width: 100%;
  background-color: rgba($colorLive, 0.4);
  //margin-bottom: 10px;
  display: flex;

  .browser-support-banner-icon {
    fill: $colorWhite;
    @include size(24px);
    flex-shrink: 0;
    margin-top: 2px;
  }

  .browser-support-banner-message {
    margin-left: 10px;

    a {
      text-decoration: underline;
      text-underline-position: under;

      &:hover {
        color: inherit;
        opacity: 0.8;
      }
    }
  }
}
