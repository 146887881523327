@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/helpers/badges';

.PostCardEventSpecificContent {
  position: relative;
  border-radius: 16px;
  min-height: 100px;
  padding: 10px 0;
  margin: 0 24px;

  .post-card-event-specific-content-inner {
    overflow: hidden;
  }

  .post-card-event-specific-content-live {
    display: flex;
    color: $colorNine;
    margin-top: 10px;

    .post-card-event-specific-content-live-date {
      color: $colorLive;
    }
  }

  .post-card-event-specific-content-video {
    display: flex;
    color: #999999;
    margin-top: 2px;
  }

  .free-badge {
    @include free-badge;
    font-size: 14px;
    margin-left: 0;
  }
  .post-card-event-specific-content-title {
    @include typo-headline4;
    margin-top: 10px;
  }

  .NewEventPurchase {
    margin: 20px 0;
    &,
    button {
      width: 100%;
    }
  }

  .post-card-event-specific-content-description {
    color: #eeeeee;
    white-space: pre-wrap;
    margin-top: 10px;
    font-size: 14px;

    a {
      text-decoration: underline;
      text-underline-position: under;
      text-decoration-color: $colorLightGrey;

      &:hover {
        text-decoration-color: $colorTextPrimary;
        color: inherit;
      }
    }
  }

  &.collapsed {
    .post-card-event-specific-content-inner {
      max-height: 300px;
      mask-image: linear-gradient(
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 1) 60%,
        transparent 100%
      );
    }
  }

  .post-card-event-specific-content-expander {
    @include position(absolute, null null 10px 50%);
    transform: translateX(-50%);
    cursor: pointer;

    svg {
      fill: $colorWhite;
      @include size(24px);

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .post-card-event-specific-content-insights-button {
    margin: 20px 0;
  }
}
