@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.HeaderBurger {
  color: $colorTextPrimary;

  &.MenuModal {
    .menu-modal-content {
      display: flex;
      flex-direction: column;
      padding: 0;
      height: 100%;
      background-color: $colorSurface;
    }
  }

  .header-burger-header {
    display: flex;
    align-items: center;
    height: $headerHeightMobile;
    flex-shrink: 0;
    margin: 0 16px;

    .header-burger-header-close {
      fill: $colorTextPrimary;
      @include size(24px);
      margin-right: 8px;

      &:hover {
        svg {
          fill: $colorTextSecondary;
        }
      }
    }
  }

  .header-burger-wrapper {
    overflow-y: auto;
    margin-top: -15px;
  }
}
