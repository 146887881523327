@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/mixins/spacing';

.CreatorProfileIncompleteBanner {
  height: 64px;

  .creator-profile-incomplete-banner-container {
    color: $colorWhite;
    background-color: $colorPrimary;
    @include position(fixed, #{$headerHeight} 0 null 0);
    z-index: $zBelowHeader;

    padding: 16px 16px;
    display: flex;
    align-items: center;
  }

  .creator-profile-incomplete-banner-left {
    margin-right: 15px;
    @include below(md) {
      font-size: 13px;
    }
  }

  .creator-profile-incomplete-banner-right {
    flex-shrink: 0;
    margin-left: auto;
  }
}
