@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.NewPayoutPage {
  min-height: calc(100vh - #{$headerHeight});

  .payout-container {
    display: flex;
    padding: 40px 0;
  }

  .payout-title {
    display: flex;
    align-items: center;
    @include typo-headline2;
    margin-bottom: 30px;

    &:hover {
      opacity: 0.8;
      color: inherit;
      text-decoration: none;
    }
  }

  .payout-title-back {
    @include size(32px);
    fill: $colorBlack;
    transform: rotate(90deg);
    margin-left: -5px;
    margin-right: 5px;
    margin-top: 3px;
  }
}
