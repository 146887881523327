@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/helpers/cards';
@import './pollColors';

.PollBasic {
  @include card-near;
  color: $colorTextPrimary;
  width: 100%;
  padding: 16px 16px 0 16px;
  border-radius: 16px;
  position: relative;

  .poll-basic-background {
    @include position(absolute, 0 0 null 0);
    overflow: hidden;
    display: flex;
    justify-content: center;

    svg {
      margin-top: -25px;
      width: 200px - (40px * 2);
      height: auto;
    }
  }

  .poll-basic-wrapper {
    position: relative;
  }

  .poll-basic-header {
    display: flex;
    margin-bottom: 24px;
    align-items: flex-start;

    .poll-basic-header-options {
      margin-left: auto;
    }
  }

  .poll-basic-title {
    @include typo-headline5;
    margin-bottom: 24px;
    text-align: center;
  }

  &:not(.interactive) {
    .poll-basic-title {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  &.interactive {
    .poll-basic-title {
      @include typo-headline4;
    }
  }

  .poll-basic-footer {
    display: flex;
    align-items: center;
    padding: 16px 16px;
    margin: 16px -16px 0 -16px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .poll-basic-time {
    display: flex;
    align-items: center;
    opacity: 0.8;

    @include typo-small;
  }

  .poll-basic-voters-wrapper {
    display: flex;
    margin-top: 8px;
  }

  .poll-basic-voters {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    margin-right: 10px;

    .poll-basic-voters-avatar {
      @include size(20px);

      &:not(:last-child) {
        margin-left: -4px;
      }
    }
  }

  .poll-basic-votes {
    @include typo-small;
    color: $colorTextSecondary;
  }

  .poll-basic-vote-cta {
    display: flex;
    align-items: center;
    font-weight: 800;
    margin-left: auto;
    cursor: pointer;

    svg {
      fill: $colorTextPrimary;
      @include size(24px);
      margin-bottom: -1px;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  .poll-basic-submit {
    margin: 24px 0 16px 0;
    padding: 6px 6px;
    width: 100%;
  }

  .poll-basic-live {
    @include size(8px);
    border-radius: 50%;
    margin-right: 8px;
    margin-left: 4px;
    margin-top: 1px;
    animation: live-animation 2s linear infinite;

    @keyframes live-animation {
      0% {
        opacity: 0.5;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0.5;
      }
    }
  }

  .poll-basic-options-more {
    padding: 8px 16px;
    border-radius: 24px;
    cursor: pointer;
    color: $colorTextSecondary;
    transition: transform 150ms ease-out;

    &:hover {
      transform: scale(1.02);
    }
  }

  &.compact {
    cursor: pointer;
    transition: 250ms background-color ease-out;

    .poll-basic-title {
      font-size: 16px;
    }

    .poll-basic-options-more {
      font-size: 12px;
      padding: 4px 12px;
      margin-bottom: 4px;
    }

    .poll-basic-votes {
      @include typo-small;
    }

    .poll-basic-voters {
      .poll-basic-voters-avatar {
        @include size(18px);
      }
    }

    .poll-basic-wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;

      .poll-basic-footer {
        margin-top: auto;
        padding-top: 16px;
      }
    }
  }

  &:not(.compact) {
    .poll-basic-voters-wrapper {
      margin-top: 16px;
    }
  }
}
