@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/helpers/cards';

.FeaturePagePartnership {
  padding: 80px 0;

  .feature-page-partnership-items {
    margin-top: 40px;

    display: grid;
    grid-template-columns: repeat(auto-fill, 330px);

    grid-gap: 24px;
    justify-content: center;

    @include above(xl) {
      grid-template-columns: repeat(3, 330px);
    }
  }

  .feature-page-partnership-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include size(330px, 250px);

    @include card-extra-far;
    padding: 24px;

    .feature-page-partnership-item-title {
      font-family: $fontFamilyBoon;
      font-size: 20px;
      font-weight: 800;
      margin-bottom: 16px;
    }

    .feature-page-partnership-item-description {
    }

    .feature-page-partnership-item-more {
      font-weight: 600;
      text-decoration: underline;
      text-underline-position: under;
      text-decoration-color: $colorBorderLight;

      margin-top: auto;
    }
  }
}
