@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.LoadingError {
  min-height: calc(100vh - #{$footerBodyHeightDesktop});
  @include below(sm) {
    min-height: calc(100vh - #{$footerBodyHeightMobile});
  }

  display: flex;
  justify-content: center;
  align-items: center;

  .loading-error-inner {
    padding: 60px;
    text-align: center;
  }

  .loading-error-title {
    @include typo-headline4;
    margin-bottom: 4px;
  }

  .loading-error-description {
    .button {
      margin-top: 15px;
      display: inline-block;
    }
  }

  .loading-error-link {
    text-decoration: underline;
    text-underline-position: under;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
}
