@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/mixins/shadows';
@import 'styles/helpers/cover';
@import 'styles/mixins/spinner';

.UploadImageField {
  &:focus {
    outline: none;
  }

  .upload-image-field-row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .upload-image-field-right {
    flex: 1 1;
    @include below(sm) {
      width: 100%;
      margin-bottom: 15px;
    }
  }

  .upload-image-field-outer {
    flex-shrink: 0;
    width: 210px;

    border-radius: 4px;
    overflow: hidden;

    margin-right: 30px;

    @include below(sm) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 15px;
    }
  }

  .upload-image-field-wrapper {
    display: block;
    @include eventCover;
    position: relative;
    cursor: pointer;
    background-color: $colorBorderLight;

    .upload-image-field-inner {
      @include position(absolute, 0 0 0 0);
    }
  }

  .upload-image-field-button {
    @include size(36px);
    @include position(absolute, 50% null null 50%);
    transform: translate(-50%, -50%);

    background-color: $colorMessageFrameLight;
    backdrop-filter: saturate(180%) blur(20px);

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 100%;

    svg {
      @include size(24px);
      margin-top: 1px;
      transition: all 250ms ease-out;
      opacity: 1;
      fill: $colorMuted;
    }

    &:before {
      content: '';
      opacity: 0;
      transition: opacity 250ms ease-out;
      @include spinner($color: $colorWhite);

      svg {
        fill: darken($colorWhite, 5%);
      }
    }

    &.loading {
      &:before {
        opacity: 1;
      }

      svg {
        opacity: 0;
      }
    }
  }
}
