@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.HeaderMenuContent {
  .header-menu-content-section {
    padding: 10px 10px;

    &:not(:first-of-type) {
      border-top: 1px solid $colorBorderLight;
    }

    &:last-child {
      padding-bottom: 10px;
    }
  }

  .CreatorMenu {
    .creator-menu-item {
      padding: 5px 15px 7px (36px + 10px + 10px);
    }
  }

  .header-menu-content-link {
    font-size: 14px;
    display: flex;
    align-items: center;
    padding: 5px 15px 7px 15px;
    cursor: pointer;
    white-space: nowrap;
    border-radius: 9999px;

    svg {
      fill: $colorWhite;
      @include size(24px);
      margin-right: 16px;
    }

    &:hover {
      color: inherit;
      text-decoration: none;
      background-color: $colorBorderLight;
    }
  }

  .header-menu-content-user-options {
    > * {
      padding-left: 36px + 10px + 10px;
    }
  }
}

.HeaderMenuContentSubscription {
  display: flex;
  padding: 10px 10px;
  border-radius: 16px;
  cursor: pointer;

  &:hover {
    color: inherit;
    text-decoration: none;
    background-color: $colorBorderLight;
  }

  &.active {
    background-color: $colorSurface;
  }

  .header-menu-content-subscription-avatar {
    .Avatar {
      @include size(36px);
    }
  }

  .header-menu-content-subscription-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
  }

  .header-menu-content-subscription-name {
    font-size: 14px;
    line-height: 1;
    font-weight: 800;
  }

  .header-menu-content-subscription-type {
    @include typo-small;
    color: $colorTextSecondary;
  }
}

.HeaderMenuContentCreator {
  margin: 0;
  border-radius: 16px;
  overflow: hidden;
  padding-bottom: 10px;

  &.active {
    background-color: $colorSurface;
  }

  .header-menu-content-creator-create {
    margin: 8px 0;
  }

  .header-menu-content-creator-profile {
    display: flex;
    padding: 10px 10px;

    &:hover {
      color: inherit;
      text-decoration: none;
      background-color: $colorBorderLight;
      cursor: pointer;
      border-radius: 9999px;
    }

    .header-menu-content-creator-profile-avatar {
      .Avatar {
        @include size(36px);
      }
    }

    .header-menu-content-creator-profile-details {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 10px;
    }

    .header-menu-content-creator-profile-name {
      line-height: 1;
      font-weight: 800;
    }

    .header-menu-content-creator-profile-type {
      @include typo-small;
      color: $colorTextSecondary;
    }
  }
}
