@import 'styles/global/colors';

.ForgotPasswordView {
  text-align: center;

  svg {
    padding: 16px;
    width: 48px+16px;
    height: 48px+16px;
    border-radius: 100%;
    background-color: $colorBorder;
    margin-bottom: 16px;
  }

  .button {
    margin-top: 32px;
  }
}
