@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.VisibilityPanel {
  .visibility-panel-header {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    .visibility-panel-header-title {
      @include typo-headline4;
    }

    .visibility-panel-header-status {
      padding: 0 8px;
      border-radius: 3px;
      margin-left: 8px;

      &.visibility-panel-header-status-pending {
        background-color: rgba($colorBlue, 0.4);
      }

      &.visibility-panel-header-status-published {
        background-color: rgba($colorGreen, 0.4);
      }
    }
  }

  .visibility-panel-description {
    color: $colorNine;
    margin-bottom: 24px;
  }

  .visibility-panel-no-payout {
    margin-top: 16px;
    color: $colorWarning;
  }
}

.VisibilityPanelItem {
  .visibility-panel-item-row {
    display: flex;
  }

  .visibility-panel-item-description {
    @include typo-small;
    color: $colorTextSecondary;
  }

  .visibility-panel-item-right {
    margin-left: auto;
  }

  .visibility-panel-item-below {
    margin-top: 8px;
    margin-left: 30px;

    .react-datepicker-wrapper {
      width: auto;
    }
  }

  .boon-text-field-wrapper {
    margin-top: 0;
    margin-bottom: 8px;
  }

  &.disabled {
    opacity: 0.7;
  }

  & + & {
    margin-top: 16px;
  }
}
