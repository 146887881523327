@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.UserPost {
  .PostCard {
    width: auto;
    @include below(sm) {
      margin-left: -$containerSpacing;
      margin-right: -$containerSpacing;
    }
  }

  .user-page-sub-container {
    justify-content: center;
  }
}
