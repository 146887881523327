@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.CreatorSetupProfile {
  color: $colorWhite;

  .ProfileCompletion {
    @include below(md) {
      margin-top: 30px;
    }
  }
}
