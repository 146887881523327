@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/mixins/spacing';
@import 'styles/mixins/shadows';

.FeaturePageHero {
  background-color: $colorAlternateBackground;

  .feature-page-hero-banner {
    @include page-behind-header;
    padding-top: $headerHeight;
    padding-bottom: 60px;

    @include above(lg) {
      padding-top: 120px;
    }
  }

  .feature-page-hero-banner-container {
    @include above(lg) {
      display: flex;
      flex-direction: row-reverse;
    }
  }

  .feature-page-hero-banner-media {
    @include above(lg) {
      flex: 1 1 auto;
    }

    @include below(lg) {
      margin: 0 (-$containerSpacing) 24px (-$containerSpacing);
      overflow: hidden;
    }

    display: flex;
    justify-content: center;

    @include above(xl) {
      justify-content: flex-end;
    }

    .feature-page-hero-banner-media-image {
      width: 100%;
      object-fit: contain;
      max-width: 800px;
      align-self: flex-start;

      @include below(lg) {
        width: 80%;
      }
    }
  }

  .feature-page-hero-banner-content {
    @include above(lg) {
      width: 500px;
      padding-right: 24px;
      flex-shrink: 0;
      margin-top: 75px;
    }
  }

  .feature-page-hero-name {
    font-family: $fontFamilyBoon;
    line-height: 2.25;
    font-weight: 800;
    text-transform: uppercase;
  }

  .feature-page-hero-title {
    @include typo-display5;
    margin-bottom: 24px;

    @include above(lg) {
      @include typo-display3;
    }
  }

  .feature-page-hero-subtitle {
    font-family: $fontFamilyBoon;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 20px;

    @include above(lg) {
      font-size: 24px;
      margin-bottom: 40px;
    }
  }

  .feature-page-hero-actions {
    display: flex;

    @include above(md) {
      > .button:not(:first-child) {
        margin-left: 8px;
      }
    }

    @include below(sm) {
      flex-direction: column;

      > .button {
        width: auto;
      }

      > .button:not(:first-child) {
        margin-top: 16px;
      }
    }
  }
}
