@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/helpers/cards';

.EventCheckoutPage {
  @include size(100vw, 100vh);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .event-checkout-page-box {
    @include card-extra-far;
    padding: 32px;
  }

  .event-checkout-page-title {
    @include typo-headline4;
    margin-bottom: 32px;
  }

  .event-checkout-page-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .event-checkout-page-loading-title {
      @include typo-headline3;
      margin-bottom: 48px;
    }

    .Spinner {
      position: static;
      transform: none;
    }
  }

  .event-checkout-page-error {
    .event-checkout-page-error-back {
      width: 100%;
    }
  }

  .event-checkout-page-free {
    .event-checkout-page-free-confirm {
      width: 100%;
    }
  }
}
