@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/mixins/spacing';
@import 'styles/helpers/cards';

.FilesGrid {
  .PostCompose {
    margin-top: -48px;
  }
}
