@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.PostCardReason {
  padding: 8px 24px;
  border-bottom: 1px solid $colorBorderLight;
  color: $colorTextSecondary;
  @include typo-small;
}
