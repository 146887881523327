@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/global/variables';
@import 'styles/mixins/responsiveness';

.Header {
  width: 100%;
  height: $headerHeight;
  position: relative;
  display: flex;
  align-items: center;
  color: $colorWhite;
  margin-bottom: $headerMarginBottom;

  @include below(lg) {
    z-index: $zHeader;
  }

  @include below(sm) {
    height: $headerHeightMobile;
  }

  &.scrolled {
    .header-wrapper {
      //background-color: rgba($colorBase, 0.8);
      //backdrop-filter: saturate(180%) blur(20px);
      background-color: $colorDark4;
      //box-shadow: inset 0 -1px 0 0 rgba(255, 255, 255, 0.1);
      //@include position(fixed, 0 0 null 0);
    }
  }

  .header-wrapper {
    @include position(fixed, 0 0 null 0);
    display: flex;
    align-items: center;
    flex: 1;
    height: $headerHeight;
    background-color: $colorPanelBackground;
    //box-shadow: inset 0 -1px 0 0 rgba(255, 255, 255, 0.1);
    box-shadow: 0 60px 80px 0 rgba(0, 0, 0, 0.1),
      0 18px 24px 0 rgba(0, 0, 0, 0.01), 0 8px 10px 0 rgba(0, 0, 0, 0.1),
      0 3px 4px 0 rgba(0, 0, 0, 0.1);
    z-index: $zHeader;

    @include below(sm) {
      height: $headerHeightMobile;
    }
  }

  .header-container {
    flex: 1;
    display: flex;
    padding: 0 $containerSpacing;
  }

  .header-right {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  .header-logo {
    display: flex;
    align-items: center;
    margin-top: -8px;

    svg {
      height: 24px;
      width: auto;
      fill: $colorWhite;
    }
  }

  &.white {
    color: $colorBlack;

    .header-wrapper {
      background-color: white;
      box-shadow: none;
    }

    &.scrolled .header-wrapper {
      background-color: white;
      box-shadow: 0 1px 0 0 rgb(0 0 0 / 10%);
    }

    .header-logo svg {
      fill: $colorBlack;
    }

    .HeaderControls {
      .header-controls-menu {
        .header-controls-menu-button-icon {
          fill: $colorTextPrimary;
        }
      }
    }
  }

  &.sudo {
    .header-logo svg {
      fill: $colorAlarm;
    }

    .header-controls-menu-user-image {
      box-shadow: 0 0 0 5px $colorAlarm;
    }
  }

  &.transparent {
    transition: 250ms background-color ease-out;
  }

  &.transparent:not(.scrolled) {
    .header-wrapper {
      background-color: transparent;
      box-shadow: none;
    }
  }
}
