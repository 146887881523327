@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/mixins/spinner';

.CategorySelector {
  .category-selector-loader {
    @include size(40px);

    &:after {
      @include spinner($size: 24px, $color: $colorWhite);
    }
  }

  .category-selector-add {
    @include typo-medium;
    font-weight: 600;
    display: flex;
    align-items: center;
    color: $colorTextPrimary;

    &:hover {
      cursor: pointer;
      opacity: 0.5;
    }

    svg {
      @include size(24px);
      fill: $colorTextPrimary;
      margin-right: 8px;
      margin-left: -2px;
    }
  }

  .category-selector-error {
    color: $colorWarning;
    span {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .category-selector-item {
    display: flex;

    .emoji {
      font-family: emoji;
      margin-right: 10px;
    }
  }

  .CategoryOptions {
    margin-left: auto;
  }
}

.CategorySelectorAddModal {
  .category-selector-add-modal-submit {
    width: 100%;
    margin-top: 30px;
  }
}
