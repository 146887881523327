@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.MembershipElement {
  cursor: pointer;
  $avatarSize: 80px;
  $borderSize: 2px;
  width: $avatarSize;
  padding-top: 2 * $borderSize;
  margin-right: $containerSpacing;

  @include below(md) {
    &:last-of-type {
      &::after {
        /* Hack to get membership box to scroll past last element */
        content: ' ';
        width: $avatarSize + 24px;
        height: 1px;
        display: block;
      }
    }
  }

  &.active,
  &:hover {
    .membership-element-image {
      &:before,
      .membership-element-clipped-box {
        display: inline-block;
      }
    }
  }

  @include below(md) {
    &:first-of-type {
      //margin-left: $avatarSize;
      margin-left: $containerSpacing;
    }
  }

  .membership-element-image {
    position: relative;
    cursor: pointer;

    &:before {
      content: ' ';
      display: none;
      background-color: var(--color-creator-primary);
      position: absolute;
      clip-path: url(#boonCircularSquareClipPath);
      height: $avatarSize + 4 * $borderSize;
      width: $avatarSize + 4 * $borderSize;
      top: -2 * $borderSize;
      left: -2 * $borderSize;
    }

    .membership-element-clipped-box {
      display: none;
      background-color: white;
      position: absolute;
      clip-path: url(#boonCircularSquareClipPath);
      height: $avatarSize + 2 * $borderSize;
      width: $avatarSize + 2 * $borderSize;
      top: -$borderSize;
      left: -$borderSize;
    }

    .Avatar {
      filter: brightness(95%);
      height: $avatarSize;
      width: $avatarSize;
      border-radius: 0;
      clip-path: url(#boonCircularSquareClipPath);
      padding: 2px;
    }

    .avatar-placeholder {
      background-color: $colorWhite;
      filter: brightness(95%);
      height: $avatarSize;
      width: $avatarSize;
      border-radius: 0;
      clip-path: url(#boonCircularSquareClipPath);
      padding: 2px;
    }

    &.locked {
      .Avatar {
        filter: brightness(90%);
      }

      .lock {
        fill: $colorWhite;
      }
    }

    .membership-element-lock {
      fill: $colorWhite;
      position: absolute;
      transform: translateX(-50%) translateY(-50%);
      width: 24px;
      height: 24px;
      top: calc(#{$avatarSize} / 2);
      left: calc(#{$avatarSize} / 2);
      z-index: 2;
    }

    .membership-element-all {
      fill: var(--color-creator-primary);
      position: absolute;
      transform: translateX(-50%) translateY(-50%);
      width: 24px;
      height: 24px;
      top: calc(#{$avatarSize} / 2);
      left: calc(#{$avatarSize} / 2);
      z-index: 2;
    }
  }

  .membership-element-title,
  .membership-element-subtitle {
    @include typo-small;
    font-size: 12px;
    text-align: center;
    //text-overflow: ellipsis;
    //overflow: hidden;
    //max-height: 34px;
  }

  .membership-element-title {
    color: $colorTextPrimary;
    font-weight: bold;
    margin-top: 8px;
  }

  .subtitle {
    color: $colorTextSecondary;
  }
}
