@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/spinner';

.Spinner {
  $size: 92px;

  @include position(absolute, 50% 0 0 50%);
  transform: translate(-50%, -50%);

  @include size($size);
  &:after {
    @include spinner($size: $size);
  }

  &.light {
    &:after {
      border-top-color: $colorWhite;
      border-left-color: $colorWhite;
    }
  }

  &.small {
    &,
    &:after {
      @include size(40px);
    }
  }

  &.tiny {
    &,
    &:after {
      @include size(18px);
      border-width: 2px;
    }
  }
}
