@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/helpers/cards';

.CreatorPageUspQuote {
  position: relative;
  @include card-extra-far;
  padding: $containerSpacing;

  .creator-page-usp-quote-svg {
    @include position(absolute, 0 null null $containerSpacing);
    transform: translateY(-50%);
    @include size(25px, auto);
    fill: $colorPrimary;
  }

  .creator-page-usp-quote-text {
    margin-bottom: 20px;
  }
}
