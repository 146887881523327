@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/helpers/cards';

.HeaderControls {
  display: flex;
  align-items: center;

  .header-controls-signin,
  .header-controls-help,
  .header-controls-profile {
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;

    transition: opacity 250ms ease-out;

    &:hover {
      opacity: 0.6;
    }
  }

  .header-controls-help {
    margin-right: 16px;

    @include below(md) {
      display: none;
    }
  }

  .header-controls-landing-page-cta {
    margin-right: 16px;

    @include below(sm) {
      display: none;
    }
  }

  .header-controls-landing-page-burger-menu {
    padding-left: 24px;
  }

  &.white {
    .header-controls-burger svg {
      fill: $colorBlack;
    }
  }

  .header-menu-controls-items {
    position: relative;
    display: flex;

    .header-controls-menu-iconbutton {
      @include size(30px);
      border-radius: 50%;
      background-color: $colorInputFields;
      position: relative;
      margin-left: 8px;

      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:hover {
        background-color: darken($colorInputFields, 5%);
      }

      svg {
        @include size(20px);
        fill: $colorTextPrimary;
      }
    }
  }

  .header-controls-menu {
    position: relative;
    display: flex;

    .header-controls-menu-user-image {
      margin-left: 10px;
      @include size(30px);
    }

    .header-controls-menu-button {
      display: flex;
      align-items: center;
      cursor: pointer;

      .header-controls-menu-button-icon {
        @include size(24px);
        fill: $colorWhite;
        @include below(md) {
          display: none;
        }
      }
    }
  }

  .header-controls-menu-create {
    button {
      @include size(30px);
      padding: 0;

      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        margin: 0;
        fill: #2e2f32;
      }
    }
  }

  .HeaderNotifications {
    margin-left: 8px;
  }
}
