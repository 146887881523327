@import 'styles/global/colors';
@import 'styles/helpers/box';
@import 'styles/mixins/typography';
@import 'styles/helpers/cover';
@import 'styles/modules/bourbon';
@import 'styles/helpers/play-button';
@import 'styles/helpers/cards';

.follower-modal-centered {
  display: flex;
  justify-content: center;

  .follower-modal-wrapper {
    width: 335px;
    height: 518px;
    margin-top: 47px;
    border-radius: 16px;
    overflow: hidden;
    position: relative;
    background-color: $colorWhite;
    color: $colorBlack;
    padding: 24px;

    .follower-modal-header {
      display: flex;
      justify-content: center;
      margin-top: 50px;

      .follower-header-avatar {
        height: 110px;
        width: 110px;
      }
    }

    .follower-modal-body {
      margin-top: 50px;
      ul {
        margin-top: 20px;
        padding-inline-start: 20px;
      }

      .follower-modal-button {
        margin-top: 30px;
        width: 100%;
      }
    }
  }
}
