@import 'styles/global/colors';
@import 'styles/modules/bourbon';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.ToggleButton {
  display: flex;
  @include size(40px, 20px);
  border-radius: 10px;
  background-color: $colorMuted;
  padding: 2px;
  transition: background-color 0.2s ease-out;
  cursor: pointer;

  .toggle-button-knob {
    cursor: pointer;
    @include size(16px);
    border-radius: 10px;
    background-color: $colorWhite;
    transition: transform 0.2s ease-out;
  }

  &.active {
    .toggle-button-knob {
      transform: translateX(20px);
    }

    background-color: $colorPrimary;
  }
}
