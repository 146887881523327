@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/spinner';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.Chat {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;

  color: $colorWhite;

  .chat-wrapper {
    width: 100%;
    height: 100%;
    //overflow: hidden;
  }

  .chat-conversation {
    position: relative;
    height: 100%;
    padding: 0 15px 0 15px;

    .ChatMessage:not(:first-child) {
      margin-top: 15px;
    }
  }

  .chat-bottom {
    @include position(absolute, null 0 0 0);
  }

  .chat-status {
    text-align: center;
    background-color: $colorMuted;
    padding: 10px 0;
    font-size: 14px;
    font-weight: 800;
  }
}

.chat-conversation-scroll {
  height: 100%;
  position: relative;
  padding-bottom: 80px;
  overflow-y: auto;
  padding-top: 15px;

  .scroll-to-bottom-follow-button {
    bottom: 100px;

    @media all and (orientation: landscape) {
      bottom: 130px;
    }

    &:before {
      @include position(absolute, 0);
      @include size(100%);
      background-color: rgba($colorWhite, 0.5);
      border-radius: 50%;

      color: $colorWhite;
      content: '\2193';
      line-height: 20px;
      text-align: center;
      display: block;
    }
  }
}
