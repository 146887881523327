@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.CreatorSettingsHeader {
  margin-bottom: 30px;

  .creator-settings-header-inner {
  }

  .creator-settings-header-title {
    color: $colorBlack;
    @include typo-headline2;
  }

  .creator-settings-header-description {
    color: $colorNine;
  }
}
