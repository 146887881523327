@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.PostCommentBodyAttachmentPhoto {
  position: relative;
  cursor: pointer;
  margin-top: 8px;
  width: 50%;

  .post-comment-body-attachment-photo-image {
    max-height: 600px;
    object-fit: cover;
    border-radius: 4px;
    width: 100%;
    filter: brightness(90%);
    transition: filter 250ms ease-in-out;
  }

  &:hover {
    .post-comment-body-attachment-photo-image {
      filter: none;
    }
  }

  .post-comment-body-attachment-photo-locked {
    @include position(absolute, 0 0 0 0);
    background-color: rgba(black, 0.2);

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    text-align: center;
    @include typo-small;
    color: $colorWhite;

    svg {
      fill: $colorWhite;
      @include size(48px);
      margin-bottom: 3px;
    }
  }
}
