@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/helpers/cover';

.LiveGuideModal {
  z-index: $zOverlay;
  color: $colorBlack;

  @include above(md) {
    padding: 32px 0;
  }

  .modal-content {
    background-color: white;
    position: relative;
    overflow: hidden;
    border-radius: 16px;

    @include above(md) {
      width: 450px;
    }

    @include below(md) {
      width: 90%;
    }
  }

  .live-guide-modal-top {
    padding: $containerSpacing;
  }

  .live-guide-modal-header {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .live-guide-modal-header-title {
      @include typo-headline5;
    }

    .live-guide-modal-header-close {
      margin-left: auto;
      fill: $colorMuted;
      @include size(24px);

      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }
  }

  .live-guide-modal-description {
    margin-bottom: 16px;
  }

  .live-guide-modal-toggle-advanced {
    display: flex;
    align-items: center;

    .live-guide-modal-toggle-advanced-label {
      margin-right: 10px;
      margin-bottom: 2px;
      color: $colorMuted;
      cursor: pointer;
    }
  }

  .live-guide-modal-apps {
    display: flex;
    margin-bottom: 30px;

    > a {
      &:not(:first-child) {
        margin-left: 10px;
      }

      img {
        height: 40px;
      }
    }
  }

  .live-guide-modal-textbutton {
    display: flex;
    align-items: center;
    font-weight: 800;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;

    &:hover {
      opacity: 0.8;
      color: initial;
      text-decoration: initial;
    }

    svg {
      height: 18px;
      width: auto;
      margin-right: 10px;
    }
  }

  .live-guide-modal-advanced {
    padding: $containerSpacing;
    background-color: #f0f1f2;

    .live-guide-modal-advanced-keys {
      margin-top: 16px;

      .live-guide-modal-advanced-keys-key {
        &:not(:last-child) {
          margin-bottom: 8px;
        }

        .live-guide-modal-advanced-keys-key-header {
          display: block;
          font-weight: bold;
        }
      }
    }
  }
}
