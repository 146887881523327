@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.AccountingPage {
  .extended-container {
    min-height: calc(100vh - #{$headerHeight});
  }

  .accounting-title {
    @include typo-headline2;
  }

  .accounting-payout-list {
    flex: 1;
    position: relative;
    padding: 40px 0;

    @include above(lg) {
      min-height: calc(100vh - 200px);
    }

    .PayoutListItem {
      margin-bottom: 15px;
    }
  }

  .accounting-mobile-warning {
    padding: 10px 15px;
    margin: 30px 0;
    background-color: rgba($colorAlarm, 0.5);
    border-radius: 4px;
    font-weight: 800;

    @include above(md) {
      display: none;
    }
  }
}
