@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.PostCompose {
  .post-compose-form-attachment-mobile-hide {
    @include below(sm) {
      display: none;
    }
  }
}
