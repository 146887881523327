@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.HybridMenu {
  &.MenuModal {
    .menu-modal-content {
      display: flex;
      flex-direction: column;
      padding: 0;
      height: 100%;
      background-color: $colorSurface;
      color: $colorTextPrimary;
    }
  }
}
