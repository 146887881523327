@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.ShowMore {
  cursor: pointer;
  text-align: center;
  font-weight: 800;

  border: 1px solid rgba(white, 0.1);
  border-radius: 16px;
  padding: 8px 0;

  &:hover {
    border-color: rgba(white, 0.3);
  }

  &.active {
    svg {
      transform: rotate(180deg);
    }
  }

  svg {
    @include size(24px);
    fill: $colorWhite;
  }
}
