@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/helpers/play-button';

.UserAbout {
  .UserSocial {
    margin-top: 30px;
  }

  .user-about-memberships {
    margin-top: 40px;

    .user-about-memberships-title {
      @include typo-headline2;
      margin-bottom: 24px;
      text-align: center;
    }

    .ProductCard {
      margin-bottom: 16px;
    }
  }
}
