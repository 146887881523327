@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.FeaturePageCta {
  .feature-page-cta-banner {
    background-color: $colorAlternateBackground;
    padding-top: 80px;
  }

  .feature-page-cta-actions {
    display: flex;
    justify-content: center;
    padding-bottom: 120px;

    @include above(md) {
      > .button:not(:first-child) {
        margin-left: 8px;
      }
    }

    @include below(sm) {
      flex-direction: column;

      > .button {
        width: auto;
      }

      > .button:not(:first-child) {
        margin-top: 16px;
      }
    }
  }
}
