@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.HeaderNotifications {
  .header-notifications-bell {
    @include size(30px);
    border-radius: 50%;
    background-color: $colorInputFields;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background-color: darken($colorInputFields, 5%);
    }

    .header-notifications-bell-icon {
      @include size(24px);
      fill: $colorTextPrimary;
    }

    .header-notifications-bell-count {
      @include position(absolute, -5px -6px null null);
      background-color: $colorPrimary;
      color: $colorWhite;
      font-size: 12px;
      line-height: 1;
      min-width: 19px;
      padding: 2px 6px;
      border-radius: 999px;
      text-align: center;
    }
  }
}
