@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/mixins/shadows';

.PurchasesPageHeader {
  .purchases-page-header-title {
    @include typo-headline1;
    margin-bottom: 12px;
  }

  .purchases-page-header-menu {
    @include box-shadow-near;
    display: flex;
    height: 60px;
    align-items: center;
    margin-bottom: 32px;

    a {
      position: relative;
      align-items: center;
      height: 100%;
      cursor: pointer;
      display: flex;

      &:not(:last-child) {
        margin-right: 20px;
      }

      &:hover {
        color: inherit;
        text-decoration: none;
      }

      &:not(.active):hover {
        opacity: 0.8;
      }

      &.active {
        &:before {
          content: '';
          @include position(absolute, null 0 -1px 0);
          background-color: $colorBorder;
          height: 3px;
          border-top-left-radius: 99px;
          border-top-right-radius: 99px;
        }
      }
    }
  }
}
