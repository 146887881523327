@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.NamedAnimatedModal {
  z-index: $zOverlay;
  color: $colorTextPrimary;

  .modal-content {
    background-color: $colorSurface;
    position: relative;
    min-width: 375px;
    padding: 24px;
    @include above(md) {
      border-radius: 16px;
    }
  }

  .named-animated-modal-header {
    display: flex;
    align-items: center;
    padding-bottom: 24px;

    .named-animated-modal-header-title {
      @include typo-headline5;
    }

    .named-animated-modal-header-close {
      @include size(24px);
      fill: $colorTextMuted;
      margin-left: auto;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .named-animated-modal-content {
  }
}
