@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.CreatorPageShowcase {
  padding-bottom: 80px;
  overflow: hidden;

  .creator-page-showcase-sections {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    margin-bottom: 60px;

    > .button {
      margin: 4px 4px;
    }
  }

  .creator-page-showcase-loading {
    position: relative;
    height: 346px;
  }

  .creator-page-showcase-search {
    display: flex;
    justify-content: center;
    margin: -16px 0 24px 0;

    @include below(sm) {
      margin-top: 0;
    }

    .HeaderControlsSearchBox {
      .sui-search-box {
        &__wrapper::before {
          top: 5px;
          left: 40px;

          @include below(md) {
            top: 46px;
            left: 22px;
          }
        }

        &__autocomplete-container {
          width: 400px;

          @include below(md) {
            width: 100%;
            margin-left: 0;
          }
        }
      }

      .sui-search-box__text-input {
        font-size: 20px;
        line-height: 1.5;

        height: 56px;
        padding: 6px 24px 6px 48px;

        @include above(md) {
          min-width: 400px;
        }
      }

      .sui-search-box-cancel {
        top: 16px;

        @include below(md) {
          top: 46px;
          margin-right: 15px;
        }
      }
    }
  }
}
