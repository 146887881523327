@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.SubscribePagePlanSelectorItem {
  border: 2px solid #eaeaea;
  position: relative;

  &:not(.active) {
    cursor: pointer;
  }

  &.active {
    border: 2px solid $colorPrimary;
  }

  border-radius: 8px;
  padding: 15px;
  margin-bottom: 10px;

  .subscribe-page-plan-selector-item-header {
    display: flex;

    .subscribe-page-plan-selector-item-name {
      font-weight: bold;
      font-size: 16px;
    }

    .subscribe-page-plan-selector-item-discount {
      margin-left: auto;
      color: $colorPrimary;
      font-size: 14px;
      font-weight: bold;
    }
  }

  .subscribe-page-plan-selector-item-invite-code {
    margin-top: 15px;
    display: flex;
    align-items: center;

    .subscribe-page-plan-selector-item-invite-code-avatar {
      @include size(20px);
      margin-right: 10px;
    }
  }
}
