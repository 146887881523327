@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/helpers/cards';

.CategoryCard {
  @include card-near;

  width: 100%;
  height: 112px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  transition: background 0.5s;

  &:hover {
    background: $colorBorderLight;
  }

  @include above(xs) {
    &:not(:first-of-type) {
      margin-left: 16px;
    }
  }

  .category-card-top-row {
    display: flex;
    justify-content: space-between;

    .category-card-top-row-emoji {
      font-family: emoji;
      display: inline-block;
    }

    .category-card-top-row-recent {
      @include typo-small;
      font-weight: 700;
      color: var(--color-creator-primary);
    }
  }

  .category-card-title {
    @include typo-medium;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 120px;

    @include above(xl) {
      max-width: 160px;
    }

    &.only-title {
      margin: auto;
      align-self: center;
      color: var(--color-creator-primary);
    }
  }

  .category-card-posts {
    @include typo-small;
    color: $colorTextSecondary;
  }
}
