@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/mixins/spinner';

.ProfileFeed {
  position: relative;
  min-height: 300px;
  max-width: 630px;
  margin: auto;

  .profile-feed-top {
    margin-bottom: 16px;
  }

  .profile-feed-title {
    @include typo-headline5;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 $containerSpacing;

    @include above(md) {
      padding: 0;
    }

    .profile-feed-post-button {
      svg {
        fill: $colorWhite;
        fill: var(--color-creator-text-primary);
        margin-right: 8px;
      }
    }
  }

  .profile-feed-post-sorting {
    span {
      color: $colorTextMuted;
      cursor: pointer;

      &.active {
        color: var(--color-creator-primary);
      }

      &:hover:not(.active) {
        color: $colorTextSecondary;
      }
    }

    span + span {
      margin-left: 24px;
    }
  }

  .profile-feed-list {
    .PostCard {
      margin-bottom: 20px;

      @include below(md) {
        margin-bottom: 8px;
      }

      @include below(sm) {
        box-shadow: none;
        border-radius: 0;
        border: none;
      }
    }

    .PostCard + .PostCard {
      @include below(sm) {
        border-top: 8px solid $colorBorderLight;
      }
    }

    .profile-feed-list-loader {
      display: flex;
      justify-content: center;
      padding: 40px 0 20px;

      .profile-feed-list-loader-inner {
        position: relative;
        font-weight: 800;

        &:before {
          @include position(absolute, calc(50% - 9px) null null -30px);
          @include spinner($size: 18px, $width: 2px, $color: $colorWhite);
        }
      }
    }

    .profile-feed-list-error {
      display: flex;
      flex-direction: column;
      align-items: center;

      .profile-feed-list-error-message {
        font-size: 20px;
        color: $colorError;
      }

      .profile-feed-list-error-retry {
        text-decoration: underline;
        cursor: pointer;

        &:hover {
          opacity: 0.6;
        }
      }
    }
  }

  .profile-feed-spinner {
    min-height: 300px;
    width: 100%;
    position: relative;
  }

  .post-feed-empty {
    min-height: 300px;

    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;

    .post-feed-empty-icon {
      font-size: 36px;
      margin-bottom: 8px;
    }
  }

  .CreatorEmptySection {
    margin-bottom: 40px;

    @include below(sm) {
      margin-left: $containerSpacing;
      margin-right: $containerSpacing;
    }
  }
}
