@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.PostComposeModal {
  z-index: $zOverlay;
  color: $colorDark8;

  @include above(md) {
    padding: 32px 0;
  }

  .modal-content {
    background-color: $colorWhite;
    position: relative;
    display: flex;
    flex-direction: column;

    @include above(md) {
      width: 660px;
      border-radius: 16px;
    }

    @include below(md) {
      width: 100%;
      min-height: 100%;
    }

    .input-error {
      margin-left: 20px;
    }
  }

  .post-compose-modal-header {
    display: flex;
    align-items: center;
    padding: 24px 24px 0 24px;
    margin-bottom: 24px;

    .post-compose-modal-header-avatar {
      @include size(40px);
      margin-right: 15px;
    }

    .post-compose-modal-header-name {
      @include typo-headline6;
    }

    .post-compose-modal-header-close {
      @include size(40px);

      display: flex;
      align-items: center;
      justify-content: center;

      margin-left: auto;

      border-radius: 50%;
      cursor: pointer;
      z-index: $zOverlay;

      background: $colorBasicGrey;
      box-shadow: 0 60px 80px 0 rgba(0, 0, 0, 0.02),
        0 18px 24px 0 rgba(0, 0, 0, 0.01), 0 8px 10px 0 rgba(0, 0, 0, 0.01),
        0 3px 4px 0 rgba(0, 0, 0, 0.01);

      .post-compose-modal-header-close-icon {
        @include size(24px);
        fill: $colorLightGrey;
      }

      &:hover {
        .post-compose-modal-header-close-icon {
          fill: $colorDark;
        }
      }
    }
  }

  .post-compose-modal-body {
    //padding: 0 20px;
    margin-bottom: 24px;
    flex: 1;

    .post-compose-modal-body-selector {
      padding: 0 20px;
    }
  }

  .post-compose-modal-notify-wrapper {
    display: flex;
    align-items: center;
    margin: -10px 20px 16px 20px;
    padding-bottom: 16px;
    border-bottom: 1px solid $colorBorderLight;

    .post-compose-modal-notify-toggle {
      margin-left: auto;
    }
  }

  .AccessSelector {
    margin-bottom: 24px;
  }

  .post-compose-modal-message {
    color: $colorDark;
    width: 100%;
    resize: none;
    background-color: transparent;
    font-size: 20px;
    line-height: 1.02;
    padding: 0px 0px 24px 24px;
    border: none;

    &::placeholder {
      color: $colorNine !important;
    }

    &:focus {
      outline: none;
    }
  }

  .post-compose-modal-attachments-bar {
    padding: 0 20px;
    margin: 60px 0 20px 0;
    display: flex;
    align-items: center;

    .PostComposeAttachmentButton ~ .PostComposeAttachmentButton {
      margin-left: 16px;
    }

    button {
      margin-left: auto;
    }
  }

  .post-compose-modal-attachment-error {
    margin-left: 24px;
    color: $colorTextSecondary;
  }
}
