@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/mixins/spinner';

.ReactionButton {
  position: relative;

  .reaction-button-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    appearance: none;
    outline: none;
    border: none;

    box-sizing: border-box;
    padding: 3px 8px;
    border-radius: 999px;

    background-color: $colorChatMessages;

    .reaction-button-button-icon {
      @include size(18px);
      fill: $colorNine;
    }

    &.loading {
      &:before {
        @include spinner($size: 18px, $width: 2px, $color: $colorWhite);
        position: relative;
        transition: none;
      }
    }

    &:hover {
      background-color: lighten($colorChatMessages, 10%);
    }
  }

  &:not(.overlay) {
    .emoji-mart {
      @include position(absolute, calc(100% + 15px) 0 null null);
      z-index: 1;
    }
  }

  &.big {
    .reaction-button-button {
      padding: 6px 12px;
    }
  }
}

.ReactionButton,
.ReactionButtonOverlay {
  .emoji-mart {
    button {
      &:focus {
        outline: none;
      }
    }
  }
}

.ReactionButtonOverlay {
  z-index: $zOverlay;
}
