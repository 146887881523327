@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.FeaturePageUsps {
  .feature-page-usps-item {
    margin-bottom: 40px;

    &:nth-child(odd) {
      flex-direction: row-reverse;
      justify-content: flex-end;
    }

    .feature-page-usps-item-media {
      .feature-page-usps-item-media-image {
        width: 100%;
      }
    }

    .feature-page-usps-item-content {
      //padding: 0 24px;
      margin-bottom: 24px;

      /*@include above(lg) {
        padding-top: 80px;
      }

      @include below(md) {
        margin-bottom: 40px;
      }*/
    }

    .feature-page-usps-item-title {
      @include typo-display6;
      margin-bottom: 24px;
    }
  }
}
