@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.SubscribePage {
  display: flex;
  justify-content: center;

  .subscribe-page-container {
    padding: 40px 24px;
    max-width: 450px;

    flex: 1 1 auto;
  }

  .SubscribePageHeader {
    margin-bottom: 32px;
  }

  .SubscribePageSummary {
    margin-top: 24px;
    margin-bottom: 16px;

    .checkout-payment-coupon-error {
      color: $colorRed;
      margin: 0 0 10px;
    }
  }
}
