@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.Giffer {
  @include position(absolute, 0 0 0 0);
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: 250ms opacity ease-out;

  &.playing {
    opacity: 1;
  }
}
