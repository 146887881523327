@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/helpers/cards';

.InsightsGenericWidget {
  @include card-near;
  color: $colorTextPrimary;
  background-color: $colorSurface;
  padding: 20px 24px;
  border-radius: 16px;

  .insights-generic-widget-header {
    display: flex;
    align-items: center;

    .insights-generic-widget-header-title {
      @include typo-headline6;
      font-weight: 800;
    }

    .insights-generic-widget-header-timescale {
      @include typo-small;
      margin-left: auto;
      color: $colorTextSecondary;
    }
  }
}
