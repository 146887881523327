@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';

.PostCardActions {
  padding: 0 24px;

  &.specific {
    .post-card-actions-list {
      border-bottom: 1px solid rgba(white, 0.1);
    }
    .post-card-actions-action:hover {
      color: $colorWhite;
      .post-card-actions-action-icon {
        fill: $colorWhite;
      }
    }
  }

  .post-card-actions-list {
    display: flex;
    align-items: center;
  }

  .post-card-actions-action {
    padding: 16px 0;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: $colorLightGrey;
    cursor: pointer;
    font-weight: 500;

    &.post-card-actions-action-right {
      margin-left: auto;
      font-weight: 500;
    }

    &:not(.post-card-actions-action-right) {
      margin-right: 24px;
    }

    .post-card-actions-action-icon {
      fill: $colorLightGrey;
      @include size(18px);
      margin-right: 6px;
    }

    &:hover {
      color: $colorBlack;
      .post-card-actions-action-icon {
        fill: $colorBlack;
      }
    }

    &.active {
      color: $colorPrimary;
      .post-card-actions-action-icon {
        fill: $colorPrimary;
      }
    }

    &.error {
      color: $colorError;
      .post-card-actions-action-icon {
        fill: $colorError;
      }
    }

    &.post-card-actions-action-disabled {
      color: $colorTextMuted;

      cursor: initial;
      &:hover {
        color: $colorTextMuted;
      }
    }
  }
}
