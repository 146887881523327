@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/helpers/cards';

.ManageSubscriptionsPage {
  .manage-subscriptions-title {
    @include typo-headline2;
    margin-bottom: 50px;
  }

  .manage-subscriptions-list {
    > * {
      margin-bottom: 15px;
    }
  }
}

.ManageSubscriptionCreator {
  .manage-subscription-creator-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .manage-subscription-creator-header-left {
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      &:hover {
        opacity: 0.8;
        color: inherit;
        text-decoration: none;
      }
    }

    .manage-subscription-creator-header-avatar {
      @include size(40px);
      margin-right: 16px;
    }

    .manage-subscription-creator-header-name {
      @include typo-headline6;
    }

    .manage-subscription-creator-header-right {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;

      @include below(sm) {
        width: 100%;
      }
    }

    .manage-subscription-creator-header-card {
      margin-right: 16px;
    }

    .manage-subscription-creator-header-update-payment {
      flex-shrink: 0;
    }
  }
}

.ManageSubscriptionItem {
  @include card-near;
  background-color: $colorSurface;
  padding: 16px 24px;
  border-radius: 16px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  .manage-subscription-item-details {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  .manage-subscription-item-details-left {
    @include below(sm) {
      width: 100%;
      margin-bottom: 16px;
    }
  }

  .manage-subscription-item-name {
    display: flex;
    align-items: center;
    font-weight: 800;
  }

  .manage-subscription-item-status {
    @include typo-small;
    background-color: rgba(white, 0.05);
    height: 20px;
    line-height: 20px;
    padding: 0 6px;
    border-radius: 2px;
    margin-left: 6px;

    &.error {
      background-color: rgba($colorError, 0.6);
    }
    &.success {
      background-color: rgba($colorSuccess, 0.6);
    }
  }

  .manage-subscription-item-date {
    @include typo-small;
    color: #999;
  }

  .manage-subscription-item-details-right {
    text-align: right;
  }

  .manage-subscription-item-price {
  }

  .lock-in-months {
    font-size: 12px;
  }

  .manage-subscription-item-action {
    @include typo-small;
    color: #999;
    text-decoration: underline;
    text-underline-position: under;
    cursor: pointer;

    &:hover {
      color: $colorTextPrimary;
    }
  }

  .manage-subscription-item-price,
  .manage-subscription-item-name {
    margin-bottom: 3px;
  }
}
