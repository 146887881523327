@import 'styles/modules/bourbon';
@import 'styles/global/colors';
@import 'styles/mixins/responsiveness';
@import 'styles/mixins/typography';
@import 'styles/helpers/cover';
@import 'styles/helpers/cards';

.EventInsightsHeader {
  background-color: $colorSurface;
  @include above(md) {
    margin: -16px -#{$containerSpacing} 32px -#{$containerSpacing};
  }

  .event-insights-header-event-content {
    width: 100%;
  }

  .event-insights-header-event {
    display: flex;
    padding: $containerSpacing $containerSpacing 12px $containerSpacing;

    @include below(md) {
      flex-direction: column;
    }

    .event-insights-header-event-cover {
      width: 120px;
      margin-right: $containerSpacing;

      @include below(md) {
        width: 100%;
        margin-bottom: 14px;
      }

      .event-insights-header-event-cover-image {
        @include eventCover();
        cursor: pointer;
        border-radius: 4px;
      }
    }

    .event-insights-header-event-title {
      @include typo-headline3;
      margin-top: 2px;
    }

    .event-insights-header-event-details {
      color: $colorTextSecondary;
    }
  }

  .event-insights-page-menu {
    display: flex;
    padding: 0 24px;
    height: 56px;
    align-items: center;

    .event-insights-page-menu-item {
      position: relative;
      align-items: center;
      height: 100%;
      cursor: pointer;
      display: flex;

      &:not(:last-child) {
        margin-right: 24px;
      }

      &:hover {
        color: inherit;
        text-decoration: none;
      }

      &:not(.active):hover {
        opacity: 0.8;
      }

      &.active {
        &:before {
          content: '';
          @include position(absolute, null 0 -1px 0);
          background-color: $colorPrimary;
          height: 3px;
          border-top-left-radius: 99px;
          border-top-right-radius: 99px;
        }
      }
    }
  }
}
