@import 'styles/global/colors';
@import 'styles/modules/bourbon';
@import 'styles/mixins/responsiveness';

.NotFoundPage {
  color: $colorTextPrimary;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;

  .video-overlay {
    position: relative;
    svg {
      @include position(absolute, -5px);
      width: calc(100% + 12px);
      fill: $colorWhite;
    }
  }

  .not-found-page-cta {
    text-align: center;

    .button {
      width: 200px;
      margin: 30px auto 0 auto;
    }
  }
}
